import {useState} from "react";

import { DotsStepper } from "components";

import { PhoneNumber , ApprovalCode, Congrats } from "./Steps";
import {
  ContentWrapper,
  VerifyNumberWrapper
} from "./styles";

const VerifyNumber = () => {
  const [activeStepId, setActiveStepId] = useState(0);
  const [userPhoneNumber, setUserPhoneNumber] = useState();
  const [redirectEndpoint, setRedirectEndpoint] = useState('');

  const editPhoneNumber = () => {
    setActiveStepId(0);
  }

  const handlePhoneApprove = (phoneNumber) => {
    setUserPhoneNumber(phoneNumber);
    setActiveStepId(1);
  }

  const handleCodeApprove = (link) => {
    setActiveStepId(2);
    setRedirectEndpoint(link)
  }

  return (
    <VerifyNumberWrapper>
      <ContentWrapper showConfetti={activeStepId === 2}>
        <DotsStepper activeStepId={activeStepId} stepsCount={3}/>
        {activeStepId === 0 && (
          <PhoneNumber
            handleSubmit={handlePhoneApprove}
          />
        )}
        {activeStepId === 1 && (
          <ApprovalCode
            handleSubmit={handleCodeApprove}
            editPhoneNumber={editPhoneNumber}
            phone={userPhoneNumber}
          />
        )}
        {activeStepId === 2 && (
          <Congrats redirectEndpoint={redirectEndpoint}/>
        )}
      </ContentWrapper>

    </VerifyNumberWrapper>
  );
}

export default VerifyNumber;
