import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, ButtonTypes, Checkbox, CHECKBOX_TYPES } from "components";
import { KEYS} from "constants";

import {
	BlockTitle,
	buttonSettings,
	CheckboxWrapper, RegistrationMessage
} from "../styles";

const SuccessfulRegistration = ({ redirectEndpoint }) => {
	const { t } = useTranslation();
	const [isAgree, setIsAgree] = useState(false);

	const handleChange = (e) => {
		e.preventDefault()
		setIsAgree(state => !state)
	}

	return (
		<>
			<BlockTitle>
				{t(`${KEYS.successfulRegistration}.title.text`)}
			</BlockTitle>
			<RegistrationMessage>
				{t(`${KEYS.successfulRegistration}.subTitle.text`)}
			</RegistrationMessage>

			<CheckboxWrapper onClick={handleChange}>
				<Checkbox
					id='policy_agreement'
					name="Policy agreement"
					label={t(`${KEYS.successfulRegistration}.policyAgreement`)}
					checked={isAgree}
					value={isAgree}
					type_={CHECKBOX_TYPES.dark}
				/>
			</CheckboxWrapper>
			{isAgree && (
				<Button type={ButtonTypes.red} style_={buttonSettings} to={redirectEndpoint}>
					{t(`${KEYS.common}.continue`)}
				</Button>
			)}
		</>
	);
}

export default SuccessfulRegistration;
