import AndroidIcon from 'assets/img/android_icon.png'
import IOS_Icon from 'assets/img/IOS_icon.png'

export const PHONES_LIST = [{
  icon: AndroidIcon,
  id: 0
}, {
  icon: IOS_Icon,
  id: 1
}]

export const STEPS = [{
  id: 1,
  linkTo: ''
},{
  id: 2,
  linkTo: '/choose-plan'
}]

export const buttonSettings = '' +
  'width: 158px !important;' +
  'height: 50px !important;' +
  'margin-top: 30px;' +
  'align-self: center;' +
  '@media (max-width: 1169px) {' +
  '  width: 154px !important;' +
  '  height: 47px !important;' +
  '  margin-top: 26px;' +
  '}'