import {useState} from "react";
import { useTranslation } from "react-i18next";

import { ONELINK, KEYS } from "constants";

import {
  Icons,
  RedLink,
  CloudIcon,
  TripleDot,
  RecordInfo,
  RecordTitle,
  RecordsList,
  SearchGlass,
  RecordLength,
  DownloadText,
  DownloadIcon,
  ContentMockup,
  RecordChatIcon,
  RecordsWrapper,
  SwipingHandgrip,
  GreyCrossButton,
  RecordPlayButton,
  DashboardWrapper,
  RecordItemWrapper,
  RecordIconWrapper,
  ContentMockupTitle,
  RecordsSearchField,
  RecordsSearchInput,
  DashboardContentWrapper,
} from "./styles";

import { RecordsMock } from './recordsMock.const'

const Dashboard = () => {
  const { t } = useTranslation();
  const [showAsPopup, setShowAsPopup] = useState(true)

  const format = (length) => {
    const seconds = length / 1000

    let h = Math.round(seconds / 3600);
    h = h < 10 ? `0${h}` : `${h}`;
    const h_ = seconds % 3600;
    let m = Math.round(h_ / 60);
    m = m < 10 ? `0${m}` : `${m}`;
    let s = h_% 60;
    s = s < 10 ? `0${s}` : `${s}`;

    return `${h}:${m}:${s}`;
  }

  const handleClick = () => {
    setShowAsPopup(false)
  }

  const webId = localStorage.getItem('webIdentifier')
  const oneLink = webId ? `${ONELINK}?source=web&identifier=${webId}` : ONELINK

  return (
    <DashboardWrapper>
      <DashboardContentWrapper>
        <RecordsWrapper>
          <SwipingHandgrip/>
          <ContentMockupTitle>
            {t(`${KEYS.dashboard}.mobileTitle`)}
          </ContentMockupTitle>
          <RecordsSearchField>
            <SearchGlass/>
            <RecordsSearchInput
              disabled
              placeholder={t(`${KEYS.common}.links.searchRecordings`)}
            />
          </RecordsSearchField>
          <RecordsList>
            {
              RecordsMock.map(record => (
                <RecordItemWrapper key={record.id}>
                  <RecordInfo>
                    <RecordTitle>
                      {t(`${KEYS.dashboard}.records.${record.id}.title`)}
                    </RecordTitle>
                    <RecordLength>
                      {format(record.length)}
                    </RecordLength>
                  </RecordInfo>

                  <Icons>
                    <RecordIconWrapper>
                      <RecordChatIcon/>
                    </RecordIconWrapper>
                    <RecordIconWrapper>
                      <RecordPlayButton/>
                    </RecordIconWrapper>
                    <RecordIconWrapper>
                      <TripleDot/>
                    </RecordIconWrapper>
                  </Icons>
                </RecordItemWrapper>
              ))
            }

          </RecordsList>
        </RecordsWrapper>
        <ContentMockup>
          {showAsPopup ? (
            <>
              <GreyCrossButton onClick={handleClick}/>
              <CloudIcon/>
              <DownloadText>
                {t(`${KEYS.dashboard}.downloadApp.prefix`)}
                <RedLink href={oneLink}>
                  {t(`${KEYS.dashboard}.downloadApp.body`)}
                </RedLink>
                {t(`${KEYS.dashboard}.downloadApp.suffix`)}
              </DownloadText>
              <RedLink href={oneLink}>
                <DownloadIcon/>
              </RedLink>
            </>
          ) : (
            <DownloadText>
              {t(`${KEYS.dashboard}.goToStore.prefix`)}
              <RedLink href={oneLink}>
                {t(`${KEYS.dashboard}.goToStore.body`)}
              </RedLink>
              {t(`${KEYS.dashboard}.goToStore.suffix`)}
            </DownloadText>
          )}
        </ContentMockup>
      </DashboardContentWrapper>
    </DashboardWrapper>
  );
}

export default Dashboard;
