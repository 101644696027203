import { EffectFade, Navigation, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from "react-i18next";

import { KEYS} from "constants";
import { Button, RedTitle, BlockTitle } from "components";

import { REASONS_LIST } from "./ui_constants.const";

import {
  NavWrapper,
  NextReason,
  ReasonsList,
  ReasonItem,
  PreviousReason,
  ReasonItemText,
  ReasonItemIcon,
  ReasonItemTitle,
  WhyLoveItWrapper,
  ReasonItemHeader,
  ReasonsListWrapper,
  MobileReasonsListWrapper,
  DecoratedReasonItemTitle
} from './styles'

const WhyLoveItBlock = () => {
  const { t } = useTranslation();

  return (
    <WhyLoveItWrapper id='about-us'>
      <BlockTitle>
        {t(`${KEYS.landing}.whyLoveItBlock.title.prefix`)}
        <RedTitle>
          {t(`${KEYS.landing}.whyLoveItBlock.title.body`)}
        </RedTitle>
        {t(`${KEYS.landing}.whyLoveItBlock.title.suffix`)}
      </BlockTitle>
      <ReasonsListWrapper>
        <ReasonsList>
          {REASONS_LIST.map((reason, index) => (
            <ReasonItem key={reason.title}>
              <ReasonItemHeader>
                {reason.decorate_title ? (
                  <ReasonItemTitle>
                    {t(`${KEYS.landing}.whyLoveItBlock.reasons.${index}.title.prefix`)}
                    <DecoratedReasonItemTitle>
                      {t(`${KEYS.landing}.whyLoveItBlock.reasons.${index}.title.body`)}
                    </DecoratedReasonItemTitle>
                    {t(`${KEYS.landing}.whyLoveItBlock.reasons.${index}.title.suffix`)}
                  </ReasonItemTitle>
                ) : (
                  <ReasonItemTitle>
                    {t(`${KEYS.landing}.whyLoveItBlock.reasons.${index}.title.text`)}
                  </ReasonItemTitle>
                )}
                <ReasonItemIcon src={reason.icon}/>
              </ReasonItemHeader>

              {reason.text && (
                <ReasonItemText>
                  {t(`${KEYS.landing}.whyLoveItBlock.reasons.${index}.subTitle`)}
                </ReasonItemText>
              )}

              {reason.button && (
                <Button type={reason.buttonType}>
                  {t(`${KEYS.common}.tryNow`)}
                </Button>
              )}
            </ReasonItem>
          ))}
        </ReasonsList>
      </ReasonsListWrapper>

      <MobileReasonsListWrapper>
        <Swiper
          slidesPerView={3}
          spaceBetween={16}
          effect="creative"
          loop={true}
          modules={[EffectFade, Navigation, Autoplay]}
          initialSlide={-1}
          navigation={{
            nextEl: '.next_slide',
            prevEl: '.prev_slide',
          }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true
          }}
        >
          {REASONS_LIST.map((reason, index) => (
            <SwiperSlide key={reason.title}>
              <ReasonItem>
                <ReasonItemHeader>
                  {reason.decorate_title ? (
                  <ReasonItemTitle>
                    {t(`${KEYS.landing}.whyLoveItBlock.reasons.${index}.title.prefix`)}&nbsp;
                    <DecoratedReasonItemTitle>
                      {t(`${KEYS.landing}.whyLoveItBlock.reasons.${index}.title.body`)}&nbsp;
                    </DecoratedReasonItemTitle>
                    {t(`${KEYS.landing}.whyLoveItBlock.reasons.${index}.title.suffix`)}
                  </ReasonItemTitle>
                  ) : (
                  <ReasonItemTitle>
                    {t(`${KEYS.landing}.whyLoveItBlock.reasons.${index}.title.text`)}
                  </ReasonItemTitle>
                  )}
                  <ReasonItemIcon src={reason.icon}/>
                </ReasonItemHeader>

                {reason.text && (
                  <ReasonItemText>
                    {t(`${KEYS.landing}.whyLoveItBlock.reasons.${index}.subTitle`)}
                  </ReasonItemText>
                )}

                {reason.button && (
                  <Button type={reason.buttonType}>
                    {t(`${KEYS.common}.tryNow`)}
                  </Button>
                )}
              </ReasonItem>
            </SwiperSlide>
          ))}
        </Swiper>
      </MobileReasonsListWrapper>
      <NavWrapper>
        <div className={'prev_slide'}>
          <PreviousReason/>
        </div>
        <div className={'next_slide'}>
          <NextReason/>
        </div>
      </NavWrapper>
    </WhyLoveItWrapper>
  );
}

export default WhyLoveItBlock;
