export const debounce = (func, delay=1000) => {
	let timer;
	return function (...args) {
		const context = this;
		if (timer) clearTimeout(timer);
		timer = setTimeout(() => {
			timer = null;
			func.apply(context, args);
		}, delay);
	};
};