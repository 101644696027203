import { createPortal } from "react-dom";

import {
  Wrapper
} from "./styles";

const Modal = ({ children }) => {

  return createPortal(
    <Wrapper>
      {children}
    </Wrapper>,
    document.getElementById('modal')
  )
}

export default Modal