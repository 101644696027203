import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import i18n from "i18next";

import { DEFAULT_LANG, LANGUAGES } from "constants";

import {
	LangIcon,
	LangInfo,
	Checkmark,
	DropdownItem,
	DropdownArrow,
	DropdownWrapper,
	DropdownContent,
	DropdownItemText,
	DropdownItemIcon,
} from "../styles";

const Component = () => {
	const location = useLocation();
	const lang = LANGUAGES.find(l => l.code === i18n.language) || DEFAULT_LANG;
	const navigate = useNavigate();
	const [ opened, setOpened ] = useState(false);
	const [ selected, setSelected ] = useState(lang);

	const toggleOpen = () => {
		setOpened(state => !state);
	};

	const handleSelect = (e, item) => {
		e.preventDefault();
		e.stopPropagation();

		setSelected(item);
		i18n.changeLanguage(item.code)
		localStorage.setItem('chosenLanguage', item.code)
		// const clearPath =  location.pathname.replace(`/${selected.code}`, '');
		// navigate(`/${item.code}${clearPath}`);
		toggleOpen();
	};

	return(
		<DropdownWrapper onClick={toggleOpen}>
			<LangIcon src={selected.icon}/>
			<DropdownArrow menuOpened={opened}/>
			{opened && (
				<DropdownContent>
					{LANGUAGES.map(item => (
						<DropdownItem key={item.id} onClick={(e) => handleSelect(e, item)}>
							<LangInfo>
								<DropdownItemIcon src={item.icon}/>
								<DropdownItemText>
									{item.title}
								</DropdownItemText>
							</LangInfo>

							{selected.id === item.id && (<Checkmark/>)}
						</DropdownItem>
					))}
				</DropdownContent>
			)}
		</DropdownWrapper>
	)
};

export default Component