import { Routes, Route } from 'react-router-dom';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import {
  Terms,
  Privacy,
  Landing,
  Dashboard,
  Onboarding,
  ChoosePlan,
  ChoosePhone,
  VerifyNumber,
  LayoutWrapper,
  VideoOnboarding
} from "./views";
import './App.css'

import {
  UI_CONFIGS,
  FRENCH_TRANSLATIONS,
  KOREAN_TRANSLATIONS,
  SPANISH_TRANSLATIONS,
  ENGLISH_TRANSLATIONS,
  PORTUGAL_TRANSLATIONS,
  TURKISH_TRANSLATIONS
} from "constants";

import { configureInterfaceLanguage } from 'helpers'

i18n.use(initReactI18next).init({
  lng: configureInterfaceLanguage(),
  fallbackLng: 'en',
  debug: true,
  resources: {
    en: {
      translation: ENGLISH_TRANSLATIONS
    },
    ko: {
      translation: KOREAN_TRANSLATIONS
    },
    fr: {
      translation: FRENCH_TRANSLATIONS
    },
    pt: {
      translation: PORTUGAL_TRANSLATIONS
    },
    es: {
      translation: SPANISH_TRANSLATIONS
    },
    tr: {
      translation: TURKISH_TRANSLATIONS
    }
  },
  interpolation: {
    escapeValue: false
  }
});

const App = () => {
  return (
    <>
      <Routes>
        <Route element={<LayoutWrapper uiConfig={UI_CONFIGS.default}/>}>
          <Route index element={<Landing />} />
        </Route>

        <Route element={<LayoutWrapper uiConfig={UI_CONFIGS.checkout}/>}>
          <Route path="verify-number" element={<VerifyNumber />} />
          <Route path="choose-phone" element={<ChoosePhone />} />
        </Route>

        <Route element={<LayoutWrapper uiConfig={UI_CONFIGS.minimal}/>}>
          <Route path="choose-plan" element={<ChoosePlan />} />
          <Route path="terms" element={<Terms />} />
          <Route path="privacy" element={<Privacy />} />
        </Route>

        <Route element={<LayoutWrapper uiConfig={UI_CONFIGS.onboarding}/>}>
          <Route path="onboarding" element={<Onboarding />} />
        </Route>

        <Route element={<LayoutWrapper uiConfig={UI_CONFIGS.dashboard}/>}>
          <Route path="dashboard" element={<Dashboard />} />
        </Route>

        <Route element={<LayoutWrapper uiConfig={UI_CONFIGS.videoOnboarding}/>}>
          <Route path="video-onboarding" element={<VideoOnboarding />} />
        </Route>

      </Routes>
    </>
  );
};

export default App;