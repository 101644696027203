import { Link } from "react-router-dom";

import {
	Step,
	StepsWrapper
} from "./styles";

const Stepper = ({ activeStepId, steps }) => {

	return(
		<StepsWrapper>
			{steps.map(step => (
				<Link to={step.linkTo} style={{ textDecoration: 'none' }}>
					<Step key={step.id} isActive={step.id === activeStepId}>
						{step.text}
					</Step>
				</Link>
			))}
		</StepsWrapper>
	)
}

export default Stepper