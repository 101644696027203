import { Link } from "react-router-dom";
import styled from 'styled-components';
import appLogo from 'assets/img/logo.png'
import appStoreLogo from 'assets/img/AppStoreLogo.png'
import dropdownArrow from "assets/img/icons/dropdownArrow.png"
import dropdownArrowRed from "assets/img/icons/dropdownArrowRed.png"
import greenCheckmark from "assets/img/icons/green-checkmark.png"

import { LINKS_TYPES } from 'constants';

const LINKS = {
  [LINKS_TYPES.anchor_]: `
    color: rgba(255, 255, 255, 0.8);
    margin: 12px 24px;
  `,
  [LINKS_TYPES.common]: `
    color: rgba(255, 255, 255, 0.8);
    margin: 12px 12px;
  `,
  [LINKS_TYPES.red]: `
    color: #EB5560;
    margin: 12px 12px;
  `,
  [LINKS_TYPES.white]: `
    color: #FFFFFF;
  `
}

export const HeaderBase = styled.header`
  display: flex;
  min-width: 1170px;
  height: 124px;
  background: #000;
  padding: 38px 0;
  box-sizing: border-box;
  z-index: 100;
  position: absolute;
  
  @media (max-width: 1169px) {
    min-width: 100vw;
    width: 100vw;
    height: 67px;
    padding: 14.5px 15.5px;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1316px;
  height: 57px;
  margin: 0 auto;
  
  @media(max-width: 1169px){
    max-width: 288px;
    height: 40px;
    display: none;
  }
`

export const AppLogo = styled.img.attrs(props => ({
  src: appLogo,
}))`
  width: 57px;
  height: 57px;
  
  @media(max-width: 1169px){
    width: 40px;
    height: 40px;
  }
`

export const TryItButton = styled(Link)`
  display: block;
  background: #1C1C1E;
  color: #EB5560;
  text-align: center;
  width: 170px;
  height: 57px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 163%;
  letter-spacing: 0;
  border: none;
  border-radius: 6px;
  margin-left: 16px;
  text-decoration: none;
  padding: 16px 0;
  box-sizing: border-box;

  @media (max-width: 1169px) {
    width: 130px;
    height: 38px;
    font-size: 14px;
    margin-right: 8px;
    padding: 8px 0;
  }
`

export const NavLinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0;

  @media (max-width: 1169px) {
    flex-direction: column;
    gap: 23px;
  }
`

export const NavLink = styled(Link)`
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  
  ${({ type }) => LINKS[type]};

  @media (max-width: 1169px) {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
  }
`

export const IdNavLink = styled.a`
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  ${({ type }) => LINKS[type]};

  @media (max-width: 1169px) {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
  }
`

export const MobileContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  z-index: 10;
  
  @media (min-width: 1170px){
    display: none;
  }
`

export const MenuLabel = styled.label`
  position: sticky;
  right: 0;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  cursor: pointer;
  z-index: 1000;
  text-align: center;
`;

export const Icon = styled.span`
  position: relative;
  background-color: transparent;
  width: 24px;
  height: 0;
  display: inline-block;
  transition: all 0.3s;
  &::before,
  &::after {
    content: "";
    background-color: #fff;
    width: 24px;
    height: 2px;
    display: inline-block;
    position: absolute;
    left: 0;
    transition: all 0.3s;
  }
  &::before {
    top: ${({ opened }) => opened ? "-0.2rem" : "-0.6em"};
    transform: ${({ opened }) => opened ? "rotate(135deg)" : "rotate(0)"};
  }
  &::after {
    top: ${({ opened }) => opened ? "-0.2rem" : "0.3rem"};
    transform: ${({ opened }) => opened ? "rotate(-135deg)" : "rotate(0)"};
  }
`;

export const MobileNavLinks = styled.div`
  display: ${({ opened }) => opened ? 'flex' : 'none'};
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  height: calc(100vh - 67px);
  width: 100vw;
  background: bisque;
  top: 67px;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  padding: 41px 0;
  box-sizing: border-box;
`

export const AppStoreLogo = styled.img.attrs(props => ({
  src: appStoreLogo,
}))`
  width: 150px;
`

export const MobileNavLinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 10;
`

export const LangIcon = styled.img`
	width: 31px;
	height: 23px;
  border-radius: 1px;
`

export const LangInfo = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
`

export const DropdownItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12.5px 15px;
  background: #1C1C1E;
  border-bottom: 0.5px solid rgba(84, 84, 88, 0.8);
  cursor: pointer;

  &:hover {
    background: rgb(43, 43, 47);
  }
`

export const DropdownArrow = styled.img.attrs(p => ({ ...p, src: p.menuOpened ? dropdownArrowRed : dropdownArrow }))`
	width: 14px;
	height: 7px;
`

export const Checkmark = styled.img.attrs(p => ({ ...p, src: greenCheckmark }))`
  display: unset;
  width: 23.5px;

  @media (max-width: 1169px) {
		display: none;
  }
`

export const DropdownWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
	cursor: pointer;
	gap: 10px;
  margin-right: 0;
  
  @media (max-width: 1169px) {
    margin-right: 10px;
  }
`

export const DropdownContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 283px;
  box-shadow: 0 24px 32px rgba(0, 0, 0, 0.1), 0 16px 24px rgba(0, 0, 0, 0.04), 0 4px 8px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  overflow: hidden;
	position: absolute;
  top: 75px;
  left: -228px;
  backdrop-filter: blur(7.5px);
  
  @media(max-width: 1169px) {
    top: 45px;
    left: -195px;
    border-radius: 0 0 10px 10px;
  }
`

export const DropdownItemIcon = styled.img`
	width: 27px;
`

export const DropdownItemText = styled.span`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #fff;
`

