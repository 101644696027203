import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Highlighter from "react-highlight-words";

import { useFocus } from "helpers";
import { KEYS } from "constants";

import {
	SearchIcon,
	SearchField,
	SearchResultItem,
	ModalCloseButton,
	SearchFormWrapper,
	SearchFieldWrapper,
	SearchResultWrapper,
	SearchResultItemFlag,
	SearchResultItemText,
	CountrySearchFormTitle,
	CountrySearchFormHeader,
	SearchResultItemDialCode,
} from "../styles";

import { COUNTRIES_DATA } from "./CountryCodes";

const CountrySearchForm = ({ closeModal, selectCountry }) => {
	const { t } = useTranslation();
	const [ref, setRef] = useFocus();
	const [inputValue, setInputValue] = useState('');
	const [countries, setCountries] = useState(COUNTRIES_DATA);

	const handleChange = (e) => {
	  setInputValue(e.target.value);
	}

	const handleCountrySelect = (country) => {
		selectCountry(country);
		closeModal();
	}

	useEffect(() => {
		if(inputValue){
			const filteredCountries = COUNTRIES_DATA
				.filter(c => c.name.toLowerCase().includes(inputValue.toLowerCase()) || c.dial_code.includes(inputValue))
				.map(c => {
					if(c.name.includes(inputValue) ){
						c.highlighted_name.show = true;
						const splittedText = c.name.split(inputValue)
						c.highlighted_name.prefix = splittedText[0]
						c.highlighted_name.highLightedText = inputValue
						c.highlighted_name.suffix = splittedText[1]
					}

					if(c.dial_code.includes(inputValue) ){
						c.highlighted_dial_code.show = true;
						const splittedText = c.dial_code.split(inputValue)
						c.highlighted_dial_code.prefix = splittedText[0]
						c.highlighted_dial_code.highLightedText = inputValue
						c.highlighted_dial_code.suffix = splittedText[1]
					}
					return c
				});
			setCountries(filteredCountries);
		}
	}, [inputValue])

	useEffect(() => {
		setRef();
	}, [])

	return (
		<SearchFormWrapper>
			<CountrySearchFormHeader>
				<div></div>
				<CountrySearchFormTitle>
					{t(`${KEYS.common}.chooseCountry`)}
				</CountrySearchFormTitle>
				<ModalCloseButton onClick={closeModal}/>
			</CountrySearchFormHeader>
			<SearchFieldWrapper>
				<SearchIcon/>
				<SearchField onChange={handleChange} placeholder="Search" value={inputValue} ref={ref}/>
			</SearchFieldWrapper>
			<SearchResultWrapper>
				{countries.map(country => (
					<SearchResultItem key={country.code} onClick={() => handleCountrySelect(country)}>
						<SearchResultItemFlag src={country.flagIcon}/>
						<SearchResultItemText>
							<Highlighter
								searchWords={[inputValue]}
								autoEscape={true}
								textToHighlight={country.name}
							/>
						</SearchResultItemText>
						<SearchResultItemDialCode>
							<Highlighter
								searchWords={[inputValue]}
								autoEscape={true}
								textToHighlight={country.dial_code}
							/>
						</SearchResultItemDialCode>
					</SearchResultItem>
				))}
			</SearchResultWrapper>
		</SearchFormWrapper>
	)
}

export default CountrySearchForm