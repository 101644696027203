import {
	Step,
	StepWrapper,
	StepsWrapper
} from "./styles";

const DotsStepper = ({ activeStepId, stepsCount }) => {

	const steps = Array(stepsCount).fill({}).map((el, i) => ({ id: i }))

	return(
		<StepsWrapper>
			{steps.map(step => (
				<StepWrapper key={step.id}>
					<Step isActive={step.id === activeStepId}/>
				</StepWrapper>
			))}
		</StepsWrapper>
	)
}

export default DotsStepper