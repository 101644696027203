import { useEffect } from "react";
import jwt_decode from "jwt-decode";
import { Outlet, useNavigate } from "react-router-dom";

import { UI_CONFIG_TYPES } from 'constants'
import { refreshToken } from "helpers";
import { Header, Footer, LayoutWrapperBase, ContentWrapper } from "components";

const protectedRoutes = [
  '/dashboard',
  '/onboarding',
  '/video-onboarding'
]

const LayoutWrapper = ({ uiConfig }) => {
  const navigate = useNavigate();

  const checkToken = (key) => {
    const token = localStorage.getItem(key)
    let exp;
    if(token) {
      exp = token && token !== 'undefined' ? jwt_decode(token).exp : null;
    }
    return token ? new Date(exp*1000).getTime() > new Date().getTime() : false;
  }

  useEffect(() => {
    if(protectedRoutes.includes(window.location.pathname)) {
      const isTokenValid = checkToken('jwtToken');

      if (!isTokenValid) {
        const isRefreshTokenValid = checkToken('refreshJwtToken');

        if (!isRefreshTokenValid) {
          localStorage.removeItem('jwtToken');
          localStorage.removeItem('refreshJwtToken');
          navigate('/verify-number');
        } else {
          refreshToken();
        }
      }
    }
  }, )

  return (
    <LayoutWrapperBase>
      <Header config={UI_CONFIG_TYPES[uiConfig.header]}/>
      <ContentWrapper>
        <Outlet/>
        <Footer config={UI_CONFIG_TYPES[uiConfig.footer]}/>
      </ContentWrapper>
    </LayoutWrapperBase>
  );
};

export default LayoutWrapper