import { KEYS } from "./keys.const";

export const PORTUGAL_TRANSLATIONS = {
	[KEYS.landing]: {
		bestAppBlock: {
			title: {
				text: 'Gravador de chamadas definitivo\n Aplicativo para Dispositivos Móveis',
				decorate: true,
				prefix: ' ',
				body: 'Gravador de chamadas',
				suffix: 'definitivo Aplicativo para Dispositivos Móveis'
			},
			subTitle: {
				text: 'Grave, salve e compartilhe conversas em seu dispositivo iOS.',
				highlightedWord1: 'Grave',
				highlightedWord2: 'salve',
				highlightedWord3: 'e compartilhe'
			},
		},
		whyLoveItBlock: {
			title: {
				text: 'Por que você vai adorar o gravador de chamadas',
				decorate: true,
				prefix: 'Por que ',
				body: 'você vai adorar',
				suffix: ' o gravador de chamadas'
			},
			reasons: [
				{
					title: {
						text: 'Grave as chamadas',
					},
					subTitle: 'O gravador de chamadas oferece poder ilimitado de gravação de chamadas. Grave todas as chamadas importantes em movimento e revise-as mais tarde - como áudio ou texto.'
				},{
					title: {
						text: 'Ótima qualidade de gravação',
					},
					subTitle: 'Grave chamadas recebidas e efetuadas com a melhor qualidade possível. Escolha entre várias opções de gravação aquela que funciona melhor para você.'
				},{
					title: {
						text: 'Transcreva suas chamadas',
						decorate: true,
						prefix: '',
						body: 'Transcreva ',
						suffix: 'suas chamadas'
					},
					subTitle: 'Transforme as gravações de áudio em notas escritas. Call Recorder transcreve tudo para você automaticamente.',
				},{
					title: {
						text: 'Armazene gravações sem limites',
					},
					subTitle: 'Salve sua gravação no Google Drive ou envie para seu e-mail.'
				},{
					title: {
						text: 'Privacidade total',
					},
					subTitle: 'Levamos sua privacidade a sério, então todos os dados são criptografados e nunca os compartilhamos com ninguém.'
				},{
					title: {
						text: 'começar a usar',
					},
				}
			]
		},
		getStartedBlock: {
			title: {
				text: 'Pronto para começar?',
				decorate: true,
				prefix: 'Pronto ',
				body: 'para começar',
				suffix: ' ?'
			},
			subTitle: 'Ouça, grave e transcreva qualquer chamada.'
		},
		easyToRecordBlock: {
			title: {
				text: 'É tão fácil gravar chamadas',
				decorate: true,
				prefix: 'É tão',
				body: ' fácil gravar ',
				suffix: 'chamadas'
			},
			steps: [
				'Adicione seu número de telefone',
				'Disque o número do serviço do gravador de chamadas',
				'Digite o número que deseja gravar',
				'Mesclar as chamadas',
				'Grave, transcreva e compartilhe as gravações de chamadas'
			]
		},
		bestChoiceBlock: {
			title: {
				text: 'Melhor escolha para um gravador de chamadas',
				decorate: true,
				prefix: '',
				body: 'Melhor escolha',
				suffix: ' para um gravador de chamadas'
			},
			reasons: [{
				title: {
					prefix: '2.5\n',
					body: 'milhões',
					suffix: '+'
				},
				subtitle: 'de chamadas gravadas',
			},{
				title: {
					prefix: '',
					body: '250,000\n',
					suffix: '+ horas '
				},
				subtitle: 'de gravação concluídas',
			},{
				title: {
					prefix: 'Mais de 20,000+\n',
					body: 'transcrições ',
					suffix: ''
				},
				subtitle: 'processadas',
			}]
		},
		whyCRBlock: {
			title: {
				text: 'Por que gravador de chamadas\n do RecCall?',
				decorate: true,
				prefix: 'Por que gravador de chamadas\n',
				body: ' do RecCall ',
				suffix: '?'
			},
			reasons: [[{
				text: 'boa qualidade de voz',
			},{
				text: 'Salvar e compartilhar gravações',
			},{
				text: 'Transcrição automática de áudio',
			}],[{
				text: 'gravações ilimitadas'
			},{
				text: 'Totalmente privado',
			},{
				text: 'Cobertura mundial'
			}
			]]
		},
		reviewsBlock: {
			title: {
				text: 'Comentários de nossos clientes',
				decorate: true,
				prefix: 'Comentários de nossos ',
				body: 'clientes',
				suffix: ''
			},
			generalRating: '4.8',
			over30_1: 'mais de 30.000',
			over30_2: 'comentários',
			reviews: [{
				text: 'O gravador de chamadas me salvou em várias ocasiões. Como paralegal, estou constantemente fazendo malabarismos com telefonemas e e-mails. O Call Recorder realmente me ajuda a entender tudo e me permite manter as coisas à mão para referência futura.',
				name: 'Karen J.'
			},{
				text: 'Eu não posso agradecer o suficiente. Eu estava sendo assediado por telefone pela administradora do meu cartão de crédito por causa de uma dívida pendente que eu não devia. Consegui capturar o assédio por meio de áudio e criar uma transcrição. A prova me ajudou em minha reclamação contra a empresa de cartão de crédito e, por fim, levou a uma resolução pacífica.',
				name: 'Marvin Z.'
			},{
				text: 'Fácil de instalar. Fácil de usar. E o áudio é muito, muito claro. Adoro a transcrição automática. 10 de 10. Eu daria 11 se pudesse.',
				name: 'Stephanie A.'
			}]
		}
	},
	[KEYS.common]: {
		tryNow: 'Tente agora',
		videoGuide: 'Video guide',
		confirm: 'Confirme',
		continue: 'Continuar',
		continueToPayment: 'Continuar para pagamento',
		android: 'Android',
		ios: 'IOS',
		chooseCountry: 'Escolha o país',
		next: 'Próximo',
		previous: 'Anterior',
		navigation: 'Navegação',
		policies: 'Políticas',
		contactUs: 'Contate-nos',
		links : {
			faq: 'Perguntas frequentes',
			signIn: 'Entrar',
			signUp: 'Sign Up',
			reviews: 'Avaliações',
			aboutUs: 'Sobre nós',
			terms: 'Termos de uso',
			bestChoice: 'Melhor escolha',
			privacy: 'Política de Privacidade',
			unsubscribe: 'Cancelar subscrição',
			howItWorks: 'Como funciona',
			keyBenefits: 'Principais benefícios',
			howToRecord: 'Como gravar chamadas',
			searchRecordings: 'Pesquisar gravações',
			support: 'support@reccall.app',
		}
	},
	[KEYS.choosePhone] : {
		title: {
			text: 'Qual dispositivo você deseja gravar?',
			prefix: 'Qual dispositivo você ',
			body: ' deseja gravar',
			suffix: ' ?'
		},
		steps: [{
			text: 'Passo 1',
		},{
			text: 'Passo 2',
		}],
		phones: [{
			text: 'Android',
		}, {
			text: 'IOS',
		}]
	},
	[KEYS.choosePlan] : {
		title: {
			text: 'Escolha seu plano',
			prefix: 'Escolha  ',
			body: ' seu ',
			suffix: 'plano'
		},
		mostPopular: 'MOST POPULAR',
		planItems: [{
			title: '1 mês'
		},{
			title: 'TESTE DE 1 DIA'
		},{
			title: '12 meses'
		}
		],
		pricePrefix: 'US$ ',
		priceSuffix: '',
		perMonth: '/mês*',
		priceHint: '*Preço mensal de acordo com a assinatura de 1 ou 12 meses. O preço está com desconto.',
		subscriptionsHint: '** The subscription will be automatically renewed after the end of the trial period ',
		platformHint: '***Currently works only on IOS',
	},
	[KEYS.verifyPhone] : {
		title: {
			text: 'Digite seu\n número de telefoner',
			prefix: 'Digite seur\n',
			body: ' número de telefoner',
			suffix: ''
		},
		inputLabel: 'número de telefone',
		invalidFormat: '* Número errado de caracteres',
		chooseCountryTitle: 'Escolha o país'
	},
	[KEYS.verifyCode] : {
		title: {
			text: 'Insira um código de 6 dígitos',
			prefix: 'Insira um código de ',
			body: '6 dígitos',
			suffix: ''
		},
		getRequestPlaceholder: 'Sua solicitação está sendo processada, por favor aguarde...',
		checkRequestPlaceholder: 'Checking your code...',
		resendSMS: 'Reenviar SMS após:',
		wrongCode: '*Código errado',
		sendAgain: 'ENVIE O CÓDIGO NOVAMENTE',
		underlineDecoratedText: {
			text: 'Or change phone number!',
			prefix: 'Or change ',
			body: 'phone number',
			suffix: '!'
		}
	},
	[KEYS.successfulRegistration] : {
		title: {
			text: 'Registrado com sucesso',
			prefix: '',
			body: '',
			suffix: ''
		},
		subTitle: {
			text: 'Registramos sua conta\n com sucesso',
			prefix: '',
			body: '',
			suffix: ''
		},
		policyAgreement: 'Eu concordo com a Política de Privacidade'
	},
	[KEYS.onboarding] : {
		steps: [{
			title: 'Passo 1',
			subTitle: 'Adicione seu número de telefone'
		},{
			title: 'Passo 2',
			subTitle: 'Disque o número do serviço do gravador de chamadas'
		},{
			title: 'Passo 3',
			subTitle: 'Digite o número que deseja gravar'
		},{
			title: 'Passo 4',
			subTitle: 'Mesclar as chamadas'
		},{
			title: 'Passo 5',
			subTitle: 'Grave, transcreva e compartilhe as gravações de chamadas'
		},{
			title: 'Passo 6',
			subTitle: 'Assista ao tutorial'
		}],
		downloadApp: {
			text: 'Baixe um aplicativo\n da App Store através do link',
			prefix: 'Baixe um aplicativo\n da App Store através ',
			body: 'do link',
			suffix: ''
		}

	},
	[KEYS.videoOnboarding] : {
		tabs: [
			'Extrovertido', 'Entrada'
		],
		breadcrumbs: [
			'Painel', 'Como gravar chamadas'
		],
		records: [[{
			title: 'Passo 1',
			subTitle: {
				prefix: 'Pressione o ',
				body: 'grande botão vermelho',
				suffix: ' na tela "Gravar chamadas"'
			}
		},{
			title: 'Passo 2',
			subTitle: {
				prefix: 'Você verá um pop-up com um número de telefone.',
				body: 'Clique neste número',
				suffix: ' para discar (é uma linha automática, você não será conectado a ninguém).'
			}
		},{
			title: 'Passo 3',
			subTitle: {
				prefix: 'Aguarde o número atender e "',
				body: 'Mesclar chamada',
				suffix: '".'
			}
		},{
			title: 'Passo 4',
			subTitle: {
				prefix: 'Wait for the number you dialed to answer and click the "',
				body: 'Mesclar as chamadas',
				suffix: '" button. Call recording started'
			}
		},{
			title: 'Verifique o aplicativo para sua gravação',
			subTitle: {
				prefix: 'Use o comando de voz.',
				body: 'Diga "GO" para começar',
				suffix: 'a gravar chamadas'
			}
		}],[{
			title: 'Passo 1',
			subTitle: {
				prefix: 'Atender a chamada',
				body: '',
				suffix: ''
			}
		},{
			title: 'Passo 2',
			subTitle: {
				prefix: 'Pressione o grande botão vermelho na tela "Gravar chamadas"',
				body: '',
				suffix: ''
			}
		},{
			title: 'Passo 3',
			subTitle: {
				prefix: 'Você verá um pop-up com um número de telefone. ',
				body: 'Clique neste número',
				suffix: ' para discar (é uma linha automática, você não será conectado a ninguém).'
			}
		},{
			title: 'Passo 4',
			subTitle: {
				prefix: 'Disque o número com o qual deseja gravar sua chamada',
				body: '',
				suffix: ''
			}
		},{
			title: 'Passo 5',
			subTitle: {
				prefix: 'Aguarde o número atender e "',
				body: 'Mesclar chamada',
				suffix: '".  A gravação da chamada será iniciada automaticamente.'
			}
		},{
			title: 'Verifique o aplicativo para sua gravação',
			subTitle: {
				prefix: 'Verifique o aplicativo para sua gravação',
				body: '',
				suffix: ''
			}
		}]],
		mobileGuideButton: 'Assista ao tutorial',
		downloadSubTitle: 'Baixe um aplicativo da App Store',
		hint: 'You can make call recordings only\n through the mobile application'
	},
	[KEYS.dashboard] : {
		records: [{
			title: 'Gravação de chamadas 1'
		},{
			title: 'Gravação de chamadas 2'
		},{
			title: 'Gravação de chamadas 3'
		},{
			title: 'Gravação de chamadas 4'
		},{
			title: 'Gravação de chamadas 5'
		},{
			title: 'Gravação de chamadas 6'
		},{
			title: 'Gravação de chamadas 7'
		},{
			title: 'Gravação de chamadas 8'
		},{
			title: 'Gravação de chamadas 9'
		},{
			title: 'Gravação de chamadas 10'
		},{
			title: 'Gravação de chamadas 11'
		},{
			title: 'Gravação de chamadas 12'
		}],
		downloadApp: {
			text: 'Baixe um aplicativo\n da App Store através do link',
			prefix: 'Baixe um aplicativo\n da App Store através ',
			body: 'do link',
			suffix: ''
		},
		goToStore: {
			text: 'Go to the application to start\n recording calls',
			prefix: 'Go to the ',
			body: 'application',
			suffix: ' to start\n recording calls'
		},
		mobileTitle: 'Voice Recordings'
	},
	[KEYS.footer] : {
		CRForAll: 'Call Recorder for everyone',
		disclaimerTitle: 'Isenção de responsabilidade',
		disclaimerText: 'O software Call Recorder é destinado APENAS PARA USO LEGAL. É uma violação da lei aplicável e das leis de sua jurisdição local instalar o Software licenciado em um dispositivo que não seja seu. A lei geralmente exige que você notifique os proprietários dos dispositivos nos quais pretende instalar o Software Licenciado. A violação deste requisito pode resultar em severas penalidades monetárias e criminais impostas ao infrator. Você deve consultar seu próprio consultor jurídico com relação à legalidade do uso do Software licenciado em sua jurisdição antes de instalá-lo e usá-lo. Você é o único responsável por instalar o Software Licenciado em tal dispositivo e está ciente de que o Call Recorder não pode ser responsabilizado.'
	}
}