import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Modal } from "components";
import { KEYS, ONELINK } from "constants";

import {
  Tab,
  Tabs,
  Video,
  Arrow,
  RedDot,
  TabIcon,
  RedText,
  Buttons,
  Wrapper,
  TabTitle,
  PlayIcon,
  WebImage,
  TextBlock,
  StoreIcon,
  StepImage,
  StepTitle,
  CloseIcon,
  Breadcrumb,
  PrevButton,
  NextButton,
  StoreBlock,
  PageWrapper,
  CloseButton,
  Breadcrumbs,
  WarningIcon,
  StepSubtitle,
  DownloadText,
  ExternalLink,
  SlidesWrapper,
  WebRecordHint,
  ContentWrapper,
  WebVideoWrapper,
  VideoPlayButton,
  MobileRecordHint,
  PlayButtonWrapper,
  PageContentWrapper,
  ControlsAndContent,
  MobileVideoWrapper,
  BreadcrumbsSeparator,
  MobileContentWrapper,
  MobileVideoContentWrapper
} from "./styles";

import { TABS, BREADCRUMBS, VIDEO_GUIDES} from './tabsAndSteps.const'

const VideoOnboarding = () => {
  const { t } = useTranslation();
  const videoRef = useRef(null);
  const [ activeTabId, setActiveTabId ] = useState(0);
  const [ isPlaying, setIsPlaying ] = useState(false);
  const [ showButton, setShowButton ] = useState(false);
  const [ activeSlideId, setActiveSlideId ] = useState(0);
  const [ showVideoModal, setShowVideoModal ] = useState(false);

  const handleTabClick = (id) => {
    setActiveSlideId(0);
    setActiveTabId(id);
  }

  const handleVideo = () => {
    setShowButton(true)
    setIsPlaying(!isPlaying);
    if (isPlaying === true) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
  };

  const handleSlideChange = (number) => {
    setActiveSlideId(state => state + number)
  }

  const handleVideoEnd = () => {
    setIsPlaying(false)
  }

  const handleModalVisibility = (bool) => {
    setShowVideoModal(bool)
  }

  const webId = localStorage.getItem('webIdentifier')
  const oneLink = webId ? `${ONELINK}?source=web&identifier=${webId}` : ONELINK

  return (
    <Wrapper>
      <PageWrapper>
        <Breadcrumbs>
          {BREADCRUMBS.map(breadcrumb => (
            <Link to={breadcrumb.url}>
              <Breadcrumb lastOne={breadcrumb.id === TABS.length - 1}>
                {t(`${KEYS.videoOnboarding}.breadcrumbs.${breadcrumb.id}`)}
                {breadcrumb.id < (TABS.length - 1) && (<BreadcrumbsSeparator/>)}
              </Breadcrumb>
            </Link>
          ))}
        </Breadcrumbs>
        <Tabs>
          {TABS.map(tab => (
            <Tab isActive={tab.id === activeTabId} onClick={() => handleTabClick(tab.id)}>
              {tab.id === activeTabId && (
                <TabIcon src={tab.icon}/>
              )}
              <TabTitle>
                {t(`${KEYS.videoOnboarding}.tabs.${tab.id}`)}
              </TabTitle>
              {tab.id === activeTabId && (
                <RedDot/>
              )}
            </Tab>
          ))}
        </Tabs>
        <PageContentWrapper>
          <WebVideoWrapper>
            <Video onClick={handleVideo} ref={videoRef} onEnded={handleVideoEnd}/>
            {showButton && !isPlaying && (
              <VideoPlayButton onClick={handleVideo}/>
            )}
          </WebVideoWrapper>
          <ContentWrapper>
            <SlidesWrapper>
              <ControlsAndContent>
                <TextBlock>
                  <StepTitle>
                    {t(`${KEYS.videoOnboarding}.records.${activeTabId}.${activeSlideId}.title`)}
                  </StepTitle>
                  <StepSubtitle>
                    {t(`${KEYS.videoOnboarding}.records.${activeTabId}.${activeSlideId}.subTitle.prefix`)}
                    <RedText>
                      {t(`${KEYS.videoOnboarding}.records.${activeTabId}.${activeSlideId}.subTitle.body`)}
                    </RedText>
                    {t(`${KEYS.videoOnboarding}.records.${activeTabId}.${activeSlideId}.subTitle.suffix`)}
                  </StepSubtitle>
                </TextBlock>
                <MobileContentWrapper>
                  <PlayButtonWrapper onClick={() => handleModalVisibility(true)}>
                    <PlayIcon/>
                    {t(`${KEYS.videoOnboarding}.mobileGuideButton`)}
                  </PlayButtonWrapper>
                  <StepImage src={VIDEO_GUIDES[activeTabId][activeSlideId].image}/>
                </MobileContentWrapper>
                <Buttons>
                  {activeSlideId > 0 && (
                    <PrevButton onClick={() => handleSlideChange(-1)}>
                      <Arrow rotated/>
                    </PrevButton>
                  )}

                  {activeSlideId < VIDEO_GUIDES[activeTabId].length - 1 && (
                    <NextButton onClick={() => handleSlideChange(1)}>
                      <Arrow/>
                    </NextButton>
                  )}
                </Buttons>
                <StoreBlock>
                  <DownloadText>
                    {t(`${KEYS.videoOnboarding}.downloadSubTitle`)}
                  </DownloadText>
                  <ExternalLink href={oneLink}>
                    <StoreIcon />
                  </ExternalLink>
                </StoreBlock>
              </ControlsAndContent>
              <WebImage src={VIDEO_GUIDES[activeTabId][activeSlideId].image}/>
            </SlidesWrapper>
            <WebRecordHint>
              <WarningIcon/>
              {t(`${KEYS.videoOnboarding}.hint`)}
            </WebRecordHint>
          </ContentWrapper>
        </PageContentWrapper>

        <MobileRecordHint>
          <WarningIcon/>
          {t(`${KEYS.videoOnboarding}.hint`)}
        </MobileRecordHint>
      </PageWrapper>
      {showVideoModal && (
        <Modal>
          <MobileVideoWrapper>
            <MobileVideoContentWrapper>
              <CloseButton onClick={() => handleModalVisibility(false)}>
                <CloseIcon/>
              </CloseButton>

              <Video onClick={handleVideo} ref={videoRef} onEnded={handleVideoEnd}/>
              {showButton && !isPlaying && (
                <VideoPlayButton onClick={handleVideo}/>
              )}
            </MobileVideoContentWrapper>
          </MobileVideoWrapper>
        </Modal>
      )}
    </Wrapper>

  );
}

export default VideoOnboarding;
