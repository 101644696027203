import outGoingCall from 'assets/img/icons/outgoingCallIcon.png'
import inComingCall from 'assets/img/icons/incomingCallIcon.png'
import guide_1 from 'assets/img/video_guide_1.png'
import guide_2 from 'assets/img/video_guide_2.png'
import guide_3 from 'assets/img/video_guide_3.png'
import guide_4 from 'assets/img/video_guide_4.png'
import guide_5 from 'assets/img/video_guide_5.png'
import guide_6 from 'assets/img/video_guide_6.png'
import guide_7 from 'assets/img/video_guide_7.png'

export const BREADCRUMBS = [{
	id: 0,
	url: '/dashboard'
},{
	id: 1,
	url: '/video-onboarding'
}]

export const TABS = [{
	id: 0,
	icon: outGoingCall
},{
	id: 1,
	icon: inComingCall
}]

export const VIDEO_GUIDES = [
	[{
		id: 0,
		image: guide_1
	},{
		id: 1,
		image: guide_2
	},{
		id: 2,
		image: guide_3
	},{
		id: 3,
		image: guide_4
	},{
		id: 4,
		image: guide_5
	}],
	[{
		id: 0,
		image: guide_6
	},{
		id: 1,
		image: guide_7
	},{
		id: 2,
		image: guide_1
	},{
		id: 3,
		image: guide_2
	},{
		id: 4,
		image: guide_4
	},{
		id: 5,
		image: guide_5
	}]
]