import CryptoJS from "crypto-js";

import { API_ROUTES } from 'constants'

import { jwt } from 'helpers'

export const getCode = async (phone) => {
	const iv = "9wchh7wtfwu5t2d2";
	const key = "d41d8cd98f00b204e9800998ecf8427e";
	const fastSpringOrderId = localStorage.getItem('orderId');
	const hash = CryptoJS.AES.encrypt(Math.floor(Date.now() / 1000 - 1).toString(), CryptoJS.enc.Utf8.parse(key), {
		iv: CryptoJS.enc.Utf8.parse(iv),
		padding: CryptoJS.pad.Pkcs7,
		mode: CryptoJS.mode.CBC
	}).toString();

	const { headers } = await fetch(API_ROUTES.requestCode.url, {
		method: "HEAD",
		cache: "no-cache",
		headers: {
			"X-Request-Identifier": hash
		}
	});

	let resp;

	if (headers.has('X-Temporary-Code')) {
		const jwtToken = jwt(
			{
				phone,
				fastSpringOrderId,
				timestamp: Math.floor(Date.now() / 1000 - 1).toString()
			},
			"47c0ae8c0e1b986b722b23ed4d4d421e3c9214ace1acec9da754472fad5ff488" + headers.get("X-Temporary-Code")
		)
		resp = await fetch(API_ROUTES.requestCode.url, {
			method: "POST",
			cache: "no-cache",
			headers: {
				"Content-Type": "application/json",
				"X-Request-Signature": jwtToken
			},
			body: JSON.stringify({phone})
		}).then(response => response.json()).catch(e => console.log('ERROR THROWN',e))
	}
	if(resp.webIdentifier) {
		localStorage.setItem('webIdentifier', resp.webIdentifier);
	}

	return { isOk: resp.ok};
}

export const checkCode = async (phone, code) => {
	const response = await fetch(API_ROUTES.login.url, {
		method: 'POST',
		mode: 'cors',
		body: JSON.stringify({
			phone,
			code
		})
	}).then(r => {
		if (r.ok) {
			return r.json()
		} else {
			return {
				notOk: true
			}
		}
	}).then(data => data).catch(e => console.log(e))

	const { access, refresh, notOk } = response;

  if (!notOk) {
		localStorage.setItem('jwtToken', access);
		localStorage.setItem('refreshJwtToken', refresh);
	}

	const userExist = false;

	return {
		redirectPoint: userExist ? '/dashboard' : '/onboarding',
		isResponseOk: !notOk
	};
}

export const refreshToken = async () => {
	const response = await fetch(API_ROUTES.tokenRefresh.url, {
		method: 'POST',
		mode: 'cors',
		body: JSON.stringify({
			token: localStorage.getItem('refreshJwtToken')
		})
	}).then(response => response.json())

	if(response.ok) {
		localStorage.setItem('jwtToken', response.access)
		localStorage.setItem('refreshJwtToken', response.refresh)
	}

	return response.ok
}

