import AF_icon from "assets/img/countriesFlags/Property 1=AF@2x.png"
import AX_icon from "assets/img/countriesFlags/Property 1=AX@2x.png"
import AL_icon from "assets/img/countriesFlags/Property 1=AL@2x.png"
import DZ_icon from "assets/img/countriesFlags/Property 1=DZ@2x.png"
import AS_icon from "assets/img/countriesFlags/Property 1=AS@2x.png"
import AD_icon from "assets/img/countriesFlags/Property 1=AD@2x.png"
import AO_icon from "assets/img/countriesFlags/Property 1=AO@2x.png"
import AI_icon from "assets/img/countriesFlags/Property 1=AI@2x.png"
import AQ_icon from "assets/img/countriesFlags/Property 1=AQ@2x.png"
import AG_icon from "assets/img/countriesFlags/Property 1=AG@2x.png"
import AR_icon from "assets/img/countriesFlags/Property 1=AR@2x.png"
import AM_icon from "assets/img/countriesFlags/Property 1=AM@2x.png"
import AW_icon from "assets/img/countriesFlags/Property 1=AW@2x.png"
import AU_icon from "assets/img/countriesFlags/Property 1=AU@2x.png"
import AT_icon from "assets/img/countriesFlags/Property 1=AT@2x.png"
import AZ_icon from "assets/img/countriesFlags/Property 1=AZ@2x.png"
import BS_icon from "assets/img/countriesFlags/Property 1=BS@2x.png"
import BH_icon from "assets/img/countriesFlags/Property 1=BH@2x.png"
import BD_icon from "assets/img/countriesFlags/Property 1=BD@2x.png"
import BB_icon from "assets/img/countriesFlags/Property 1=BB@2x.png"
import BY_icon from "assets/img/countriesFlags/Property 1=BY@2x.png"
import BE_icon from "assets/img/countriesFlags/Property 1=BE@2x.png"
import BZ_icon from "assets/img/countriesFlags/Property 1=BZ@2x.png"
import BJ_icon from "assets/img/countriesFlags/Property 1=BJ@2x.png"
import BM_icon from "assets/img/countriesFlags/Property 1=BM@2x.png"
import BT_icon from "assets/img/countriesFlags/Property 1=BT@2x.png"
import BO_icon from "assets/img/countriesFlags/Property 1=BO@2x.png"
import BA_icon from "assets/img/countriesFlags/Property 1=BA@2x.png"
import BW_icon from "assets/img/countriesFlags/Property 1=BW@2x.png"
import BR_icon from "assets/img/countriesFlags/Property 1=BR@2x.png"
import IO_icon from "assets/img/countriesFlags/Property 1=IO@2x.png"
import BN_icon from "assets/img/countriesFlags/Property 1=BN@2x.png"
import BG_icon from "assets/img/countriesFlags/Property 1=BG@2x.png"
import BF_icon from "assets/img/countriesFlags/Property 1=BF@2x.png"
import BI_icon from "assets/img/countriesFlags/Property 1=BI@2x.png"
import KH_icon from "assets/img/countriesFlags/Property 1=KH@2x.png"
import CM_icon from "assets/img/countriesFlags/Property 1=CM@2x.png"
import CA_icon from "assets/img/countriesFlags/Property 1=CA@2x.png"
import CV_icon from "assets/img/countriesFlags/Property 1=CV@2x.png"
import KY_icon from "assets/img/countriesFlags/Property 1=KY@2x.png"
import CF_icon from "assets/img/countriesFlags/Property 1=CF@2x.png"
import TD_icon from "assets/img/countriesFlags/Property 1=TD@2x.png"
import CL_icon from "assets/img/countriesFlags/Property 1=CL@2x.png"
import CN_icon from "assets/img/countriesFlags/Property 1=CN@2x.png"
import CX_icon from "assets/img/countriesFlags/Property 1=CX@2x.png"
import CC_icon from "assets/img/countriesFlags/Property 1=CC@2x.png"
import CO_icon from "assets/img/countriesFlags/Property 1=CO@2x.png"
import KM_icon from "assets/img/countriesFlags/Property 1=KM@2x.png"
import CG_icon from "assets/img/countriesFlags/Property 1=CG@2x.png"
import CD_icon from "assets/img/countriesFlags/Property 1=CD@2x.png"
import CK_icon from "assets/img/countriesFlags/Property 1=CK@2x.png"
import CR_icon from "assets/img/countriesFlags/Property 1=CR@2x.png"
import CI_icon from "assets/img/countriesFlags/Property 1=CI@2x.png"
import HR_icon from "assets/img/countriesFlags/Property 1=HR@2x.png"
import CU_icon from "assets/img/countriesFlags/Property 1=CU@2x.png"
import CY_icon from "assets/img/countriesFlags/Property 1=CY@2x.png"
import CZ_icon from "assets/img/countriesFlags/Property 1=CZ@2x.png"
import DK_icon from "assets/img/countriesFlags/Property 1=DK@2x.png"
import DJ_icon from "assets/img/countriesFlags/Property 1=DJ@2x.png"
import DM_icon from "assets/img/countriesFlags/Property 1=DM@2x.png"
import DO_icon from "assets/img/countriesFlags/Property 1=DO@2x.png"
import EC_icon from "assets/img/countriesFlags/Property 1=EC@2x.png"
import EG_icon from "assets/img/countriesFlags/Property 1=EG@2x.png"
import SV_icon from "assets/img/countriesFlags/Property 1=SV@2x.png"
import GQ_icon from "assets/img/countriesFlags/Property 1=GQ@2x.png"
import ER_icon from "assets/img/countriesFlags/Property 1=ER@2x.png"
import EE_icon from "assets/img/countriesFlags/Property 1=EE@2x.png"
import ET_icon from "assets/img/countriesFlags/Property 1=ET@2x.png"
import FK_icon from "assets/img/countriesFlags/Property 1=FK@2x.png"
import FO_icon from "assets/img/countriesFlags/Property 1=FO@2x.png"
import FJ_icon from "assets/img/countriesFlags/Property 1=FJ@2x.png"
import FI_icon from "assets/img/countriesFlags/Property 1=FI@2x.png"
import FR_icon from "assets/img/countriesFlags/Property 1=FR@2x.png"
import GF_icon from "assets/img/countriesFlags/Property 1=GF@2x.png"
import PF_icon from "assets/img/countriesFlags/Property 1=PF@2x.png"
import GA_icon from "assets/img/countriesFlags/Property 1=GA@2x.png"
import GM_icon from "assets/img/countriesFlags/Property 1=GM@2x.png"
import GE_icon from "assets/img/countriesFlags/Property 1=GE@2x.png"
import DE_icon from "assets/img/countriesFlags/Property 1=DE@2x.png"
import GH_icon from "assets/img/countriesFlags/Property 1=GH@2x.png"
import GI_icon from "assets/img/countriesFlags/Property 1=GI@2x.png"
import GR_icon from "assets/img/countriesFlags/Property 1=GR@2x.png"
import GL_icon from "assets/img/countriesFlags/Property 1=GL@2x.png"
import GD_icon from "assets/img/countriesFlags/Property 1=GD@2x.png"
import GP_icon from "assets/img/countriesFlags/Property 1=GP@2x.png"
import GU_icon from "assets/img/countriesFlags/Property 1=GU@2x.png"
import GT_icon from "assets/img/countriesFlags/Property 1=GT@2x.png"
import GG_icon from "assets/img/countriesFlags/Property 1=GG@2x.png"
import GN_icon from "assets/img/countriesFlags/Property 1=GN@2x.png"
import GW_icon from "assets/img/countriesFlags/Property 1=GW@2x.png"
import GY_icon from "assets/img/countriesFlags/Property 1=GY@2x.png"
import HT_icon from "assets/img/countriesFlags/Property 1=HT@2x.png"
import VA_icon from "assets/img/countriesFlags/Property 1=VA@2x.png"
import HN_icon from "assets/img/countriesFlags/Property 1=HN@2x.png"
import HK_icon from "assets/img/countriesFlags/Property 1=HK@2x.png"
import HU_icon from "assets/img/countriesFlags/Property 1=HU@2x.png"
import IS_icon from "assets/img/countriesFlags/Property 1=IS@2x.png"
import IN_icon from "assets/img/countriesFlags/Property 1=IN@2x.png"
import ID_icon from "assets/img/countriesFlags/Property 1=ID@2x.png"
import IR_icon from "assets/img/countriesFlags/Property 1=IR@2x.png"
import IQ_icon from "assets/img/countriesFlags/Property 1=IQ@2x.png"
import IE_icon from "assets/img/countriesFlags/Property 1=IE@2x.png"
import IM_icon from "assets/img/countriesFlags/Property 1=IM@2x.png"
import IL_icon from "assets/img/countriesFlags/Property 1=IL@2x.png"
import IT_icon from "assets/img/countriesFlags/Property 1=IT@2x.png"
import JM_icon from "assets/img/countriesFlags/Property 1=JM@2x.png"
import JP_icon from "assets/img/countriesFlags/Property 1=JP@2x.png"
import JE_icon from "assets/img/countriesFlags/Property 1=JE@2x.png"
import JO_icon from "assets/img/countriesFlags/Property 1=JO@2x.png"
import KZ_icon from "assets/img/countriesFlags/Property 1=KZ@2x.png"
import KE_icon from "assets/img/countriesFlags/Property 1=KN@2x.png"
import KI_icon from "assets/img/countriesFlags/Property 1=KI@2x.png"
import KP_icon from "assets/img/countriesFlags/Property 1=KP@2x.png"
import KR_icon from "assets/img/countriesFlags/Property 1=KR@2x.png"
import KW_icon from "assets/img/countriesFlags/Property 1=KW@2x.png"
import KG_icon from "assets/img/countriesFlags/Property 1=KG@2x.png"
import LA_icon from "assets/img/countriesFlags/Property 1=LA@2x.png"
import LV_icon from "assets/img/countriesFlags/Property 1=LV@2x.png"
import LB_icon from "assets/img/countriesFlags/Property 1=LB@2x.png"
import LS_icon from "assets/img/countriesFlags/Property 1=LS@2x.png"
import LR_icon from "assets/img/countriesFlags/Property 1=LR@2x.png"
import LY_icon from "assets/img/countriesFlags/Property 1=LY@2x.png"
import LI_icon from "assets/img/countriesFlags/Property 1=LI@2x.png"
import LT_icon from "assets/img/countriesFlags/Property 1=LT@2x.png"
import LU_icon from "assets/img/countriesFlags/Property 1=LU@2x.png"
import MO_icon from "assets/img/countriesFlags/Property 1=MO@2x.png"
import MK_icon from "assets/img/countriesFlags/Property 1=MK@2x.png"
import MG_icon from "assets/img/countriesFlags/Property 1=MG@2x.png"
import MW_icon from "assets/img/countriesFlags/Property 1=MW@2x.png"
import MY_icon from "assets/img/countriesFlags/Property 1=MY@2x.png"
import MV_icon from "assets/img/countriesFlags/Property 1=MV@2x.png"
import ML_icon from "assets/img/countriesFlags/Property 1=ML@2x.png"
import MT_icon from "assets/img/countriesFlags/Property 1=MT@2x.png"
import MH_icon from "assets/img/countriesFlags/Property 1=MH@2x.png"
import MQ_icon from "assets/img/countriesFlags/Property 1=MQ@2x.png"
import MR_icon from "assets/img/countriesFlags/Property 1=MR@2x.png"
import MU_icon from "assets/img/countriesFlags/Property 1=MU@2x.png"
import MX_icon from "assets/img/countriesFlags/Property 1=MX@2x.png"
import FM_icon from "assets/img/countriesFlags/Property 1=FM@2x.png"
import MD_icon from "assets/img/countriesFlags/Property 1=MD@2x.png"
import MC_icon from "assets/img/countriesFlags/Property 1=MC@2x.png"
import MN_icon from "assets/img/countriesFlags/Property 1=MN@2x.png"
import ME_icon from "assets/img/countriesFlags/Property 1=ME@2x.png"
import MS_icon from "assets/img/countriesFlags/Property 1=MS@2x.png"
import MA_icon from "assets/img/countriesFlags/Property 1=MA@2x.png"
import MZ_icon from "assets/img/countriesFlags/Property 1=MZ@2x.png"
import MM_icon from "assets/img/countriesFlags/Property 1=MM@2x.png"
import NA_icon from "assets/img/countriesFlags/Property 1=NA@2x.png"
import NR_icon from "assets/img/countriesFlags/Property 1=NR@2x.png"
import NP_icon from "assets/img/countriesFlags/Property 1=NP@2x.png"
import NL_icon from "assets/img/countriesFlags/Property 1=NL@2x.png"
import NC_icon from "assets/img/countriesFlags/Property 1=NC@2x.png"
import NZ_icon from "assets/img/countriesFlags/Property 1=NZ@2x.png"
import NI_icon from "assets/img/countriesFlags/Property 1=NI@2x.png"
import NE_icon from "assets/img/countriesFlags/Property 1=NE@2x.png"
import NG_icon from "assets/img/countriesFlags/Property 1=NG@2x.png"
import NU_icon from "assets/img/countriesFlags/Property 1=NU@2x.png"
import NF_icon from "assets/img/countriesFlags/Property 1=NF@2x.png"
import MP_icon from "assets/img/countriesFlags/Property 1=MP@2x.png"
import NO_icon from "assets/img/countriesFlags/Property 1=NO@2x.png"
import OM_icon from "assets/img/countriesFlags/Property 1=OM@2x.png"
import PK_icon from "assets/img/countriesFlags/Property 1=PK@2x.png"
import PW_icon from "assets/img/countriesFlags/Property 1=PW@2x.png"
import PS_icon from "assets/img/countriesFlags/Property 1=PS@2x.png"
import PA_icon from "assets/img/countriesFlags/Property 1=PA@2x.png"
import PG_icon from "assets/img/countriesFlags/Property 1=PG@2x.png"
import PY_icon from "assets/img/countriesFlags/Property 1=PY@2x.png"
import PE_icon from "assets/img/countriesFlags/Property 1=PE@2x.png"
import PH_icon from "assets/img/countriesFlags/Property 1=PH@2x.png"
import PN_icon from "assets/img/countriesFlags/Property 1=PN@2x.png"
import PL_icon from "assets/img/countriesFlags/Property 1=PL@2x.png"
import PT_icon from "assets/img/countriesFlags/Property 1=PT@2x.png"
import PR_icon from "assets/img/countriesFlags/Property 1=PR@2x.png"
import QA_icon from "assets/img/countriesFlags/Property 1=QA@2x.png"
import RO_icon from "assets/img/countriesFlags/Property 1=RO@2x.png"
import RU_icon from "assets/img/countriesFlags/Property 1=RU@2x.png"
import RW_icon from "assets/img/countriesFlags/Property 1=RW@2x.png"
import RE_icon from "assets/img/countriesFlags/Property 1=RE@2x.png"
import BL_icon from "assets/img/countriesFlags/Property 1=BL@2x.png"
import SH_icon from "assets/img/countriesFlags/Property 1=SH@2x.png"
import KN_icon from "assets/img/countriesFlags/Property 1=KN@2x.png"
import LC_icon from "assets/img/countriesFlags/Property 1=LC@2x.png"
import MF_icon from "assets/img/countriesFlags/Property 1=MF@2x.png"
import PM_icon from "assets/img/countriesFlags/Property 1=PM@2x.png"
import VC_icon from "assets/img/countriesFlags/Property 1=VC@2x.png"
import WS_icon from "assets/img/countriesFlags/Property 1=WS@2x.png"
import SM_icon from "assets/img/countriesFlags/Property 1=SM@2x.png"
import ST_icon from "assets/img/countriesFlags/Property 1=ST@2x.png"
import SA_icon from "assets/img/countriesFlags/Property 1=SA@2x.png"
import SN_icon from "assets/img/countriesFlags/Property 1=SN@2x.png"
import RS_icon from "assets/img/countriesFlags/Property 1=RS@2x.png"
import SC_icon from "assets/img/countriesFlags/Property 1=SC@2x.png"
import SL_icon from "assets/img/countriesFlags/Property 1=SL@2x.png"
import SG_icon from "assets/img/countriesFlags/Property 1=SG@2x.png"
import SK_icon from "assets/img/countriesFlags/Property 1=SK@2x.png"
import SI_icon from "assets/img/countriesFlags/Property 1=SI@2x.png"
import SB_icon from "assets/img/countriesFlags/Property 1=SB@2x.png"
import SO_icon from "assets/img/countriesFlags/Property 1=SO@2x.png"
import ZA_icon from "assets/img/countriesFlags/Property 1=ZA@2x.png"
import SS_icon from "assets/img/countriesFlags/Property 1=SS@2x.png"
import GS_icon from "assets/img/countriesFlags/Property 1=GS@2x.png"
import ES_icon from "assets/img/countriesFlags/Property 1=ES@2x.png"
import LK_icon from "assets/img/countriesFlags/Property 1=LK@2x.png"
import SD_icon from "assets/img/countriesFlags/Property 1=SD@2x.png"
import SR_icon from "assets/img/countriesFlags/Property 1=SR@2x.png"
import SJ_icon from "assets/img/countriesFlags/Property 1=SJ@2x.png"
import SZ_icon from "assets/img/countriesFlags/Property 1=SZ@2x.png"
import SE_icon from "assets/img/countriesFlags/Property 1=SE@2x.png"
import CH_icon from "assets/img/countriesFlags/Property 1=CH@2x.png"
import SY_icon from "assets/img/countriesFlags/Property 1=SY@2x.png"
import TW_icon from "assets/img/countriesFlags/Property 1=TW@2x.png"
import TJ_icon from "assets/img/countriesFlags/Property 1=TJ@2x.png"
import TZ_icon from "assets/img/countriesFlags/Property 1=TZ@2x.png"
import TH_icon from "assets/img/countriesFlags/Property 1=TH@2x.png"
import TL_icon from "assets/img/countriesFlags/Property 1=TL@2x.png"
import TG_icon from "assets/img/countriesFlags/Property 1=TG@2x.png"
import TK_icon from "assets/img/countriesFlags/Property 1=TK@2x.png"
import TO_icon from "assets/img/countriesFlags/Property 1=TO@2x.png"
import TT_icon from "assets/img/countriesFlags/Property 1=TT@2x.png"
import TN_icon from "assets/img/countriesFlags/Property 1=TN@2x.png"
import TR_icon from "assets/img/countriesFlags/Property 1=TR@2x.png"
import TM_icon from "assets/img/countriesFlags/Property 1=TM@2x.png"
import TC_icon from "assets/img/countriesFlags/Property 1=TC@2x.png"
import TV_icon from "assets/img/countriesFlags/Property 1=TV@2x.png"
import UG_icon from "assets/img/countriesFlags/Property 1=UG@2x.png"
import UA_icon from "assets/img/countriesFlags/Property 1=UA@2x.png"
import AE_icon from "assets/img/countriesFlags/Property 1=AE@2x.png"
import US_icon from "assets/img/countriesFlags/Property 1=US@2x.png"
import UY_icon from "assets/img/countriesFlags/Property 1=UY@2x.png"
import UZ_icon from "assets/img/countriesFlags/Property 1=UZ@2x.png"
import VU_icon from "assets/img/countriesFlags/Property 1=VU@2x.png"
import VE_icon from "assets/img/countriesFlags/Property 1=VE@2x.png"
import VN_icon from "assets/img/countriesFlags/Property 1=VN@2x.png"
import VG_icon from "assets/img/countriesFlags/Property 1=VG@2x.png"
import VI_icon from "assets/img/countriesFlags/Property 1=VI@2x.png"
import WF_icon from "assets/img/countriesFlags/Property 1=WF@2x.png"
import YE_icon from "assets/img/countriesFlags/Property 1=YE@2x.png"
import ZM_icon from "assets/img/countriesFlags/Property 1=ZM@2x.png"
import ZW_icon from "assets/img/countriesFlags/Property 1=ZW@2x.png"
import YT_icon from "assets/img/countriesFlags/Property 1=YT-UNF@2x.png"
import GB_ENG_icon from "assets/img/countriesFlags/Property 1=GB-ENG@2x.png"
import GB_NIR_icon from "assets/img/countriesFlags/Property 1=GB-NIR@2x.png"
import GB_SCT_icon from "assets/img/countriesFlags/Property 1=GB-SCT@2x.png"
import GB_WLS_icon from "assets/img/countriesFlags/Property 1=GB-WLS@2x.png"

export const DefaultSelect = {
    "name": "United States",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+1",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "US",
    "flagIcon": US_icon
  }


export const COUNTRIES_DATA = [
  {
    "name": "Afghanistan",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+93",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "AF",
    "flagIcon": AF_icon
  },
  {
    "name": "Aland Islands",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+358",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "AX",
    "flagIcon": AX_icon
  },
  {
    "name": "Albania",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+355",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "AL",
    "flagIcon": AL_icon
  },
  {
    "name": "Algeria",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+213",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "DZ",
    "flagIcon": DZ_icon
  },
  {
    "name": "AmericanSamoa",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+1684",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "AS",
    "flagIcon": AS_icon
  },
  {
    "name": "Andorra",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+376",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "AD",
    "flagIcon": AD_icon
  },
  {
    "name": "Angola",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+244",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "AO",
    "flagIcon": AO_icon
  },
  {
    "name": "Anguilla",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+1264",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "AI",
    "flagIcon": AI_icon
  },
  {
    "name": "Antarctica",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+672",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "AQ",
    "flagIcon": AQ_icon
  },
  {
    "name": "Antigua and Barbuda",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+1268",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "AG",
    "flagIcon": AG_icon
  },
  {
    "name": "Argentina",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+54",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "AR",
    "flagIcon": AR_icon
  },
  {
    "name": "Armenia",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+374",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "AM",
    "flagIcon": AM_icon
  },
  {
    "name": "Aruba",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+297",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "AW",
    "flagIcon": AW_icon
  },
  {
    "name": "Australia",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+61",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "AU",
    "flagIcon": AU_icon
  },
  {
    "name": "Austria",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+43",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "AT",
    "flagIcon": AT_icon
  },
  {
    "name": "Azerbaijan",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+994",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "AZ",
    "flagIcon": AZ_icon
  },
  {
    "name": "Bahamas",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+1242",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "BS",
    "flagIcon": BS_icon
  },
  {
    "name": "Bahrain",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+973",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "BH",
    "flagIcon": BH_icon
  },
  {
    "name": "Bangladesh",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+880",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "BD",
    "flagIcon": BD_icon
  },
  {
    "name": "Barbados",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+1246",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "BB",
    "flagIcon": BB_icon
  },
  {
    "name": "Belarus",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+375",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "BY",
    "flagIcon": BY_icon
  },
  {
    "name": "Belgium",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+32",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "BE",
    "flagIcon": BE_icon
  },
  {
    "name": "Belize",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+501",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "BZ",
    "flagIcon": BZ_icon
  },
  {
    "name": "Benin",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+229",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "BJ",
    "flagIcon": BJ_icon
  },
  {
    "name": "Bermuda",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+1441",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "BM",
    "flagIcon": BM_icon
  },
  {
    "name": "Bhutan",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+975",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "BT",
    "flagIcon": BT_icon
  },
  {
    "name": "Bolivia, Plurinational State of",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+591",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "BO",
    "flagIcon": BO_icon
  },
  {
    "name": "Bosnia and Herzegovina",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+387",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "BA",
    "flagIcon": BA_icon
  },
  {
    "name": "Botswana",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+267",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "BW",
    "flagIcon": BW_icon
  },
  {
    "name": "Brazil",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+55",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "BR",
    "flagIcon": BR_icon
  },
  {
    "name": "British Indian Ocean Territory",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+246",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "IO",
    "flagIcon": IO_icon
  },
  {
    "name": "Brunei Darussalam",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+673",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "BN",
    "flagIcon": BN_icon
  },
  {
    "name": "Bulgaria",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+359",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "BG",
    "flagIcon": BG_icon
  },
  {
    "name": "Burkina Faso",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+226",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "BF",
    "flagIcon": BF_icon
  },
  {
    "name": "Burundi",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+257",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "BI",
    "flagIcon": BI_icon
  },
  {
    "name": "Cambodia",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+855",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "KH",
    "flagIcon": KH_icon
  },
  {
    "name": "Cameroon",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+237",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "CM",
    "flagIcon": CM_icon
  },
  {
    "name": "Canada",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+1",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "CA",
    "flagIcon": CA_icon
  },
  {
    "name": "Cape Verde",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+238",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "CV",
    "flagIcon": CV_icon
  },
  {
    "name": "Cayman Islands",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+ 345",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "KY",
    "flagIcon": KY_icon
  },
  {
    "name": "Central African Republic",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+236",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "CF",
    "flagIcon": CF_icon
  },
  {
    "name": "Chad",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+235",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "TD",
    "flagIcon": TD_icon
  },
  {
    "name": "Chile",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+56",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "CL",
    "flagIcon": CL_icon
  },
  {
    "name": "China",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+86",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "CN",
    "flagIcon": CN_icon
  },
  {
    "name": "Christmas Island",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+61",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "CX",
    "flagIcon": CX_icon
  },
  {
    "name": "Cocos (Keeling) Islands",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+61",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "CC",
    "flagIcon": CC_icon
  },
  {
    "name": "Colombia",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+57",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "CO",
    "flagIcon": CO_icon
  },
  {
    "name": "Comoros",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+269",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "KM",
    "flagIcon": KM_icon
  },
  {
    "name": "Congo",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+242",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "CG",
    "flagIcon": CG_icon
  },
  {
    "name": "Congo, The Democratic Republic of the Congo",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+243",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "CD",
    "flagIcon": CD_icon
  },
  {
    "name": "Cook Islands",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+682",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "CK",
    "flagIcon": CK_icon
  },
  {
    "name": "Costa Rica",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+506",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "CR",
    "flagIcon": CR_icon
  },
  {
    "name": "Cote d'Ivoire",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+225",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "CI",
    "flagIcon": CI_icon
  },
  {
    "name": "Croatia",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+385",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "HR",
    "flagIcon": HR_icon
  },
  {
    "name": "Cuba",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+53",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "CU",
    "flagIcon": CU_icon
  },
  {
    "name": "Cyprus",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+357",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "CY",
    "flagIcon": CY_icon
  },
  {
    "name": "Czech Republic",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+420",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "CZ",
    "flagIcon": CZ_icon
  },
  {
    "name": "Denmark",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+45",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "DK",
    "flagIcon": DK_icon
  },
  {
    "name": "Djibouti",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+253",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "DJ",
    "flagIcon": DJ_icon
  },
  {
    "name": "Dominica",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+1767",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "DM",
    "flagIcon": DM_icon
  },
  {
    "name": "Dominican Republic",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+1849",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "DO",
    "flagIcon": DO_icon
  },
  {
    "name": "Ecuador",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+593",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "EC",
    "flagIcon": EC_icon
  },
  {
    "name": "Egypt",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+20",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "EG",
    "flagIcon": EG_icon
  },
  {
    "name": "El Salvador",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+503",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "SV",
    "flagIcon": SV_icon
  },
  {
    "name": "Equatorial Guinea",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+240",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "GQ",
    "flagIcon": GQ_icon
  },
  {
    "name": "Eritrea",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+291",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "ER",
    "flagIcon": ER_icon
  },
  {
    "name": "Estonia",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+372",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "EE",
    "flagIcon": EE_icon
  },
  {
    "name": "Ethiopia",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+251",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "ET",
    "flagIcon": ET_icon
  },
  {
    "name": "Falkland Islands (Malvinas)",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+500",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "FK",
    "flagIcon": FK_icon
  },
  {
    "name": "Faroe Islands",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+298",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "FO",
    "flagIcon": FO_icon
  },
  {
    "name": "Fiji",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+679",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "FJ",
    "flagIcon": FJ_icon
  },
  {
    "name": "Finland",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+358",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "FI",
    "flagIcon": FI_icon
  },
  {
    "name": "France",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+33",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "FR",
    "flagIcon": FR_icon
  },
  {
    "name": "French Guiana",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+594",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "GF",
    "flagIcon": GF_icon
  },
  {
    "name": "French Polynesia",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+689",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "PF",
    "flagIcon": PF_icon
  },
  {
    "name": "Gabon",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+241",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "GA",
    "flagIcon": GA_icon
  },
  {
    "name": "Gambia",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+220",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "GM",
    "flagIcon": GM_icon
  },
  {
    "name": "Georgia",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+995",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "GE",
    "flagIcon": GE_icon
  },
  {
    "name": "Germany",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+49",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "DE",
    "flagIcon": DE_icon
  },
  {
    "name": "Ghana",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+233",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "GH",
    "flagIcon": GH_icon
  },
  {
    "name": "Gibraltar",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+350",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "GI",
    "flagIcon": GI_icon
  },
  {
    "name": "Greece",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+30",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "GR",
    "flagIcon": GR_icon
  },
  {
    "name": "Greenland",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+299",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "GL",
    "flagIcon": GL_icon
  },
  {
    "name": "Grenada",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+1473",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "GD",
    "flagIcon": GD_icon
  },
  {
    "name": "Guadeloupe",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+590",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "GP",
    "flagIcon": GP_icon
  },
  {
    "name": "Guam",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+1671",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "GU",
    "flagIcon": GU_icon
  },
  {
    "name": "Guatemala",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+502",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "GT",
    "flagIcon": GT_icon
  },
  {
    "name": "Guernsey",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+44",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "GG",
    "flagIcon": GG_icon
  },
  {
    "name": "Guinea",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+224",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "GN",
    "flagIcon": GN_icon
  },
  {
    "name": "Guinea-Bissau",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+245",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "GW",
    "flagIcon": GW_icon
  },
  {
    "name": "Guyana",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+595",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "GY",
    "flagIcon": GY_icon
  },
  {
    "name": "Haiti",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+509",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "HT",
    "flagIcon": HT_icon
  },
  {
    "name": "Holy See (Vatican City State)",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+379",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "VA",
    "flagIcon": VA_icon
  },
  {
    "name": "Honduras",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+504",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "HN",
    "flagIcon": HN_icon
  },
  {
    "name": "Hong Kong",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+852",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "HK",
    "flagIcon": HK_icon
  },
  {
    "name": "Hungary",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+36",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "HU",
    "flagIcon": HU_icon
  },
  {
    "name": "Iceland",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+354",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "IS",
    "flagIcon": IS_icon
  },
  {
    "name": "India",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+91",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "IN",
    "flagIcon": IN_icon
  },
  {
    "name": "Indonesia",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+62",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "ID",
    "flagIcon": ID_icon
  },
  {
    "name": "Iran, Islamic Republic of Persian Gulf",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+98",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "IR",
    "flagIcon": IR_icon
  },
  {
    "name": "Iraq",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+964",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "IQ",
    "flagIcon": IQ_icon
  },
  {
    "name": "Ireland",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+353",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "IE",
    "flagIcon": IE_icon
  },
  {
    "name": "Isle of Man",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+44",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "IM",
    "flagIcon": IM_icon
  },
  {
    "name": "Israel",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+972",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "IL",
    "flagIcon": IL_icon
  },
  {
    "name": "Italy",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+39",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "IT",
    "flagIcon": IT_icon
  },
  {
    "name": "Jamaica",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+1876",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "JM",
    "flagIcon": JM_icon
  },
  {
    "name": "Japan",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+81",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "JP",
    "flagIcon": JP_icon
  },
  {
    "name": "Jersey",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+44",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "JE",
    "flagIcon": JE_icon
  },
  {
    "name": "Jordan",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+962",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "JO",
    "flagIcon": JO_icon
  },
  {
    "name": "Kazakhstan",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+77",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "KZ",
    "flagIcon": KZ_icon
  },
  {
    "name": "Kenya",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+254",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "KE",
    "flagIcon": KE_icon
  },
  {
    "name": "Kiribati",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+686",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "KI",
    "flagIcon": KI_icon
  },
  {
    "name": "Korea, Democratic People's Republic of Korea",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+850",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "KP",
    "flagIcon": KP_icon
  },
  {
    "name": "Korea, Republic of South Korea",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+82",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "KR",
    "flagIcon": KR_icon
  },
  {
    "name": "Kuwait",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+965",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "KW",
    "flagIcon": KW_icon
  },
  {
    "name": "Kyrgyzstan",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+996",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "KG",
    "flagIcon": KG_icon
  },
  {
    "name": "Laos",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+856",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "LA",
    "flagIcon": LA_icon
  },
  {
    "name": "Latvia",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+371",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "LV",
    "flagIcon": LV_icon
  },
  {
    "name": "Lebanon",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+961",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "LB",
    "flagIcon": LB_icon
  },
  {
    "name": "Lesotho",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+266",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "LS",
    "flagIcon": LS_icon
  },
  {
    "name": "Liberia",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+231",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "LR",
    "flagIcon": LR_icon
  },
  {
    "name": "Libyan Arab Jamahiriya",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+218",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "LY",
    "flagIcon": LY_icon
  },
  {
    "name": "Liechtenstein",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+423",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "LI",
    "flagIcon": LI_icon
  },
  {
    "name": "Lithuania",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+370",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "LT",
    "flagIcon": LT_icon
  },
  {
    "name": "Luxembourg",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+352",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "LU",
    "flagIcon": LU_icon
  },
  {
    "name": "Macao",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+853",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "MO",
    "flagIcon": MO_icon
  },
  {
    "name": "Macedonia",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+389",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "MK",
    "flagIcon": MK_icon
  },
  {
    "name": "Madagascar",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+261",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "MG",
    "flagIcon": MG_icon
  },
  {
    "name": "Malawi",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+265",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "MW",
    "flagIcon": MW_icon
  },
  {
    "name": "Malaysia",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+60",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "MY",
    "flagIcon": MY_icon
  },
  {
    "name": "Maldives",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+960",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "MV",
    "flagIcon": MV_icon
  },
  {
    "name": "Mali",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+223",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "ML",
    "flagIcon": ML_icon
  },
  {
    "name": "Malta",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+356",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "MT",
    "flagIcon": MT_icon
  },
  {
    "name": "Marshall Islands",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+692",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "MH",
    "flagIcon": MH_icon
  },
  {
    "name": "Martinique",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+596",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "MQ",
    "flagIcon": MQ_icon
  },
  {
    "name": "Mauritania",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+222",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "MR",
    "flagIcon": MR_icon
  },
  {
    "name": "Mauritius",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+230",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "MU",
    "flagIcon": MU_icon
  },
  {
    "name": "Mayotte",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+262",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "YT",
    "flagIcon": YT_icon
  },
  {
    "name": "Mexico",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+52",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "MX",
    "flagIcon": MX_icon
  },
  {
    "name": "Micronesia, Federated States of Micronesia",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+691",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "FM",
    "flagIcon": FM_icon
  },
  {
    "name": "Moldova",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+373",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "MD",
    "flagIcon": MD_icon
  },
  {
    "name": "Monaco",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+377",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "MC",
    "flagIcon": MC_icon
  },
  {
    "name": "Mongolia",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+976",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "MN",
    "flagIcon": MN_icon
  },
  {
    "name": "Montenegro",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+382",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "ME",
    "flagIcon": ME_icon
  },
  {
    "name": "Montserrat",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+1664",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "MS",
    "flagIcon": MS_icon
  },
  {
    "name": "Morocco",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+212",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "MA",
    "flagIcon": MA_icon
  },
  {
    "name": "Mozambique",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+258",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "MZ",
    "flagIcon": MZ_icon
  },
  {
    "name": "Myanmar",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+95",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "MM",
    "flagIcon": MM_icon
  },
  {
    "name": "Namibia",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+264",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "NA",
    "flagIcon": NA_icon
  },
  {
    "name": "Nauru",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+674",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "NR",
    "flagIcon": NR_icon
  },
  {
    "name": "Nepal",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+977",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "NP",
    "flagIcon": NP_icon
  },
  {
    "name": "Netherlands",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+31",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "NL",
    "flagIcon": NL_icon
  },
  {
    "name": "New Caledonia",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+687",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "NC",
    "flagIcon": NC_icon
  },
  {
    "name": "New Zealand",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+64",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "NZ",
    "flagIcon": NZ_icon
  },
  {
    "name": "Nicaragua",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+505",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "NI",
    "flagIcon": NI_icon
  },
  {
    "name": "Niger",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+227",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "NE",
    "flagIcon": NE_icon
  },
  {
    "name": "Nigeria",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+234",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "NG",
    "flagIcon": NG_icon
  },
  {
    "name": "Niue",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+683",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "NU",
    "flagIcon": NU_icon
  },
  {
    "name": "Norfolk Island",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+672",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "NF",
    "flagIcon": NF_icon
  },
  {
    "name": "Northern Mariana Islands",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+1670",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "MP",
    "flagIcon": MP_icon
  },
  {
    "name": "Norway",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+47",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "NO",
    "flagIcon": NO_icon
  },
  {
    "name": "Oman",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+968",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "OM",
    "flagIcon": OM_icon
  },
  {
    "name": "Pakistan",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+92",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "PK",
    "flagIcon": PK_icon
  },
  {
    "name": "Palau",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+680",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "PW",
    "flagIcon": PW_icon
  },
  {
    "name": "Palestinian Territory, Occupied",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+970",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "PS",
    "flagIcon": PS_icon
  },
  {
    "name": "Panama",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+507",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "PA",
    "flagIcon": PA_icon
  },
  {
    "name": "Papua New Guinea",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+675",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "PG",
    "flagIcon": PG_icon
  },
  {
    "name": "Paraguay",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+595",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "PY",
    "flagIcon": PY_icon
  },
  {
    "name": "Peru",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+51",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "PE",
    "flagIcon": PE_icon
  },
  {
    "name": "Philippines",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+63",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "PH",
    "flagIcon": PH_icon
  },
  {
    "name": "Pitcairn",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+872",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "PN",
    "flagIcon": PN_icon
  },
  {
    "name": "Poland",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+48",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "PL",
    "flagIcon": PL_icon
  },
  {
    "name": "Portugal",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+351",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "PT",
    "flagIcon": PT_icon
  },
  {
    "name": "Puerto Rico",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+1939",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "PR",
    "flagIcon": PR_icon
  },
  {
    "name": "Qatar",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+974",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "QA",
    "flagIcon": QA_icon
  },
  {
    "name": "Romania",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+40",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "RO",
    "flagIcon": RO_icon
  },
  {
    "name": "Russia",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+7",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "RU",
    "flagIcon": RU_icon
  },
  {
    "name": "Rwanda",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+250",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "RW",
    "flagIcon": RW_icon
  },
  {
    "name": "Reunion",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+262",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "RE",
    "flagIcon": RE_icon
  },
  {
    "name": "Saint Barthelemy",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+590",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "BL",
    "flagIcon": BL_icon
  },
  {
    "name": "Saint Helena, Ascension and Tristan Da Cunha",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+290",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "SH",
    "flagIcon": SH_icon
  },
  {
    "name": "Saint Kitts and Nevis",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+1869",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "KN",
    "flagIcon": KN_icon
  },
  {
    "name": "Saint Lucia",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+1758",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "LC",
    "flagIcon": LC_icon
  },
  {
    "name": "Saint Martin",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+590",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "MF",
    "flagIcon": MF_icon
  },
  {
    "name": "Saint Pierre and Miquelon",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+508",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "PM",
    "flagIcon": PM_icon
  },
  {
    "name": "Saint Vincent and the Grenadines",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+1784",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "VC",
    "flagIcon": VC_icon
  },
  {
    "name": "Samoa",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+685",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "WS",
    "flagIcon": WS_icon
  },
  {
    "name": "San Marino",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+378",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "SM",
    "flagIcon": SM_icon
  },
  {
    "name": "Sao Tome and Principe",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+239",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "ST",
    "flagIcon": ST_icon
  },
  {
    "name": "Saudi Arabia",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+966",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "SA",
    "flagIcon": SA_icon
  },
  {
    "name": "Senegal",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+221",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "SN",
    "flagIcon": SN_icon
  },
  {
    "name": "Serbia",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+381",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "RS",
    "flagIcon": RS_icon
  },
  {
    "name": "Seychelles",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+248",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "SC",
    "flagIcon": SC_icon
  },
  {
    "name": "Sierra Leone",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+232",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "SL",
    "flagIcon": SL_icon
  },
  {
    "name": "Singapore",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+65",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "SG",
    "flagIcon": SG_icon
  },
  {
    "name": "Slovakia",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+421",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "SK",
    "flagIcon": SK_icon
  },
  {
    "name": "Slovenia",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+386",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "SI",
    "flagIcon": SI_icon
  },
  {
    "name": "Solomon Islands",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+677",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "SB",
    "flagIcon": SB_icon
  },
  {
    "name": "Somalia",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+252",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "SO",
    "flagIcon": SO_icon
  },
  {
    "name": "South Africa",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+27",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "ZA",
    "flagIcon": ZA_icon
  },
  {
    "name": "South Sudan",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+211",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "SS",
    "flagIcon": SS_icon
  },
  {
    "name": "South Georgia and the South Sandwich Islands",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+500",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "GS",
    "flagIcon": GS_icon
  },
  {
    "name": "Spain",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+34",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "ES",
    "flagIcon": ES_icon
  },
  {
    "name": "Sri Lanka",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+94",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "LK",
    "flagIcon": LK_icon
  },
  {
    "name": "Sudan",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+249",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "SD",
    "flagIcon": SD_icon
  },
  {
    "name": "Suriname",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+597",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "SR",
    "flagIcon": SR_icon
  },
  {
    "name": "Svalbard and Jan Mayen",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+47",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "SJ",
    "flagIcon": SJ_icon
  },
  {
    "name": "Swaziland",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+268",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "SZ",
    "flagIcon": SZ_icon
  },
  {
    "name": "Sweden",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+46",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "SE",
    "flagIcon": SE_icon
  },
  {
    "name": "Switzerland",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+41",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "CH",
    "flagIcon": CH_icon
  },
  {
    "name": "Syrian Arab Republic",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+963",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "SY",
    "flagIcon": SY_icon
  },
  {
    "name": "Taiwan",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+886",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "TW",
    "flagIcon": TW_icon
  },
  {
    "name": "Tajikistan",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+992",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "TJ",
    "flagIcon": TJ_icon
  },
  {
    "name": "Tanzania, United Republic of Tanzania",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+255",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "TZ",
    "flagIcon": TZ_icon
  },
  {
    "name": "Thailand",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+66",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "TH",
    "flagIcon": TH_icon
  },
  {
    "name": "Timor-Leste",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+670",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "TL",
    "flagIcon": TL_icon
  },
  {
    "name": "Togo",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+228",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "TG",
    "flagIcon": TG_icon
  },
  {
    "name": "Tokelau",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+690",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "TK",
    "flagIcon": TK_icon
  },
  {
    "name": "Tonga",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+676",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "TO",
    "flagIcon": TO_icon
  },
  {
    "name": "Trinidad and Tobago",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+1868",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "TT",
    "flagIcon": TT_icon
  },
  {
    "name": "Tunisia",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+216",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "TN",
    "flagIcon": TN_icon
  },
  {
    "name": "Turkey",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+90",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "TR",
    "flagIcon": TR_icon
  },
  {
    "name": "Turkmenistan",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+993",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "TM",
    "flagIcon": TM_icon
  },
  {
    "name": "Turks and Caicos Islands",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+1649",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "TC",
    "flagIcon": TC_icon
  },
  {
    "name": "Tuvalu",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+688",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "TV",
    "flagIcon": TV_icon
  },
  {
    "name": "Uganda",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+256",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "UG",
    "flagIcon": UG_icon
  },
  {
    "name": "Ukraine",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+380",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "UA",
    "flagIcon": UA_icon
  },
  {
    "name": "United Arab Emirates",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+971",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "AE",
    "flagIcon": AE_icon
  },
  {
    "name": "England",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+44",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "GB_ENG",
    "flagIcon": GB_ENG_icon
  },
  {
    "name": "Northern Ireland",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+44",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "GB_NIR",
    "flagIcon": GB_NIR_icon
  },
  {
    "name": "Scotland",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+44",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "GB_SCT",
    "flagIcon": GB_SCT_icon
  },
  {
    "name": "Wales",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+44",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "GB_WLS",
    "flagIcon": GB_WLS_icon
  },
  {
    "name": "United States",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+1",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "US",
    "flagIcon": US_icon
  },
  {
    "name": "Uruguay",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+598",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "UY",
    "flagIcon": UY_icon
  },
  {
    "name": "Uzbekistan",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+998",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "UZ",
    "flagIcon": UZ_icon
  },
  {
    "name": "Vanuatu",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+678",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "VU",
    "flagIcon": VU_icon
  },
  {
    "name": "Venezuela, Bolivarian Republic of Venezuela",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+58",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "VE",
    "flagIcon": VE_icon
  },
  {
    "name": "Vietnam",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+84",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "VN",
    "flagIcon": VN_icon
  },
  {
    "name": "Virgin Islands, British",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+1284",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "VG",
    "flagIcon": VG_icon
  },
  {
    "name": "Virgin Islands, U.S.",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+1340",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "VI",
    "flagIcon": VI_icon
  },
  {
    "name": "Wallis and Futuna",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+681",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "WF",
    "flagIcon": WF_icon
  },
  {
    "name": "Yemen",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+967",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "YE",
    "flagIcon": YE_icon
  },
  {
    "name": "Zambia",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+260",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "ZM",
    "flagIcon": ZM_icon
  },
  {
    "name": "Zimbabwe",
    "highlighted_name": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "dial_code": "+263",
    "highlighted_dial_code": {
      show: false,
      prefix: '',
      highLightedText: '',
      suffix: ''
    },
    "code": "ZW",
    "flagIcon": ZW_icon
  }
]