
const Component = () => {
	return (
		<section className="privacy">
			<div className="wr">
				<h1>PRIVACY POLICY</h1>
				<h3>This Privacy Policy was published on 25.03.2023</h3>
				<p>
					This Privacy Policy describes the privacy practices for the Call Recorder - Transcribe Your Calls App
					(hereinafter the “App”, “Call Recorder”) operated by Fortunex Limited (hereinafter “we”, “us”, “our”, “Company”)
					and how the App, operated by Company, collect and use the personal data you provide to the Company, with the
					purpose to use Call Recorder (hereinafter referred to as the “Services”). It also describes the choices
					available to you regarding our use of your personal information and how you can access, update and delete
					this information.
				</p>
				<p>If a user wants to use the Services, collecting and processing of personal data could become necessary. If the processing of personal data is necessary, we generally obtain consent from the user as a data subject, except cases when personal data processing is available under other grounds (legitimate interests, contract executing, etc.).</p>

				<p>The processing of personal data of a data subject shall always be in line with the General Data Protection Regulation (GDPR), and in accordance with the country-specific data protection regulations applicable to Company. By means of this Privacy Policy, we would like to inform the general public of the nature, scope, and purpose of the personal data we collect, use and process. Furthermore, data subjects (users) are informed, by means of this Privacy Policy, of the rights to which they are entitled.
					This Privacy Policy is an integral part of the <a className="greyUnderlined" href='/terms-of-use'>Terms of Use.</a></p>
				<p><strong>Call Recorder acts as a data controller and a data processor in relation to the personal data you provide depending on the factual circumstances of the processing. </strong></p>
				<p>As the controller and/or processor, Company has implemented numerous technical and organizational measures to ensure the most complete protection of personal data processed through the App.</p>
				<p>By visiting our Website or by using our Services you are accepting and consenting to the practices described in this Privacy Policy. </p>
				<p>In this Privacy Policy Fortunex Limited (registered address: Georgiou A, 83, SHOP 17, Germasogeia, 4047, Limassol, Cyprus) acts as the controller and/or processor of personal data depending on the factual circumstances of the processing.</p>

				<h3>CONTENT</h3>
				<ol className="blueUpperCaseUnderlined">
					<li>1. <a href="#definitions">DEFINITIONS</a></li>
					<li>2. <a href="#data_collection">PERSONAL DATA COLLECTION AND USE</a></li>
					<li>3. <a href="#scope">SCOPE AND CATEGORIES OF PERSONAL DATA, LAWFUL BASIS FOR PERSONAL DATA PROCESSING AND DATA SUBJECT CATEGORIES</a></li>
					<li>4. <a href="#encrypted">ENCRYPTED DATA</a></li>
					<li>5. <a href="#legal_matters">LEGAL MATTERS</a></li>
					<li>6. <a href="#storing">WHERE DATA SUBJECT’S PERSONAL DATA ARE STORED</a></li>
					<li>7. <a href="#your_rights">YOUR RIGHTS AS A DATA SUBJECT</a></li>
					<li>8. <a href="#childrens_privacy">CHILDREN’S PRIVACY</a></li>
					<li>9. <a href="#soting_and_removal">DATA STORAGE AND DATA REMOVAL</a></li>
					<li>10. <a href="#miscellaneous">MISCELLANEOUS</a></li>
				</ol>
				<ol>
					<li>
						<h2 id="definitions">1. DEFINITIONS</h2>
							<h4>Personal data</h4>
							<div className="paragraph">Means any information relating to an identified or identifiable natural person (“data subject/user”). An identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person</div>

							<h4>Controller</h4>
							<div className="paragraph">For the purposes of the General Data Protection Regulation (GDPR), other data protection laws applicable in Member states of the European Union and other provisions related to data protection is the Company.</div>

							<h4>Processor</h4>
							<div className="paragraph">Means the natural or legal person who processes personal data on behalf of the data controller.</div>

							<h4>Processing</h4>
							<div className="paragraph">Is any operation or set of operations which is performed on personal data or on sets of personal data, whether or not by automated means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.</div>

							<h4>Data subject/user</h4>
							<div className="paragraph">Is any identified or identifiable natural person, whose personal data is processed by the controller responsible for the processing; a user of the Services.</div>

							<h4>Pseudonymization</h4>
							<div className="paragraph">Is the processing of personal data in such a manner that the personal data can no longer be attributed to a specific data subject/user without the use of additional information, provided that such additional information is kept separately and is subject to technical and organizational measures to ensure that the personal data are not attributed to an identified or identifiable natural person.</div>

							<h4>Encryption</h4>
							<div className="paragraph">Is a security protection measure for personal data; as a form of cryptography, it is a process whereby personal data gets turned into an encoded and unintelligible version, using encryption algorithms and an encryption key, and whereby a decryption key or code enables users to decode it again.</div>

							<h4>Consent of the data subject/user </h4>
							<div className="paragraph"> is any freely given, specific, informed and unambiguous indication of the data subject/user`s wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to the processing of personal data relating to him or her.</div>
					</li>
					<li>
						<h2 id="data_collection">2. PERSONAL DATA COLLECTION AND USE</h2>
						<p>As a data controller we collect the respective personal data: call recording metadata (call duration, date, etc.), user phone number, user contact list. As a data processor we process the respective personal data: call recording, recording from a voice recorder, call recording transcript (transcription), the phone number of the user's interlocutor.</p>
						<p>You agree that the user acts as a controller in relation to such personal data: call recording, recording from a voice recorder, call transcript (transcription), the phone number of the user's interlocutor. You agree that you shall be the sole owner of the end products of your use of App (including call recordings, recordings from a voice recorder, call transcripts (transcriptions), etc.).</p>
						<p>Call Recorder shall in no way be responsible (whether in violation of applicable law or not) for your use (including sharing) of call recordings, recordings from a voice recorder, call transcripts (transcriptions), and specifically disclaims any responsibility thereto.</p>
						<p>You agree that the user is solely responsible for the lawful collection of personal data, obtaining all consents to such collection and compliance with all applicable laws and regulations (including data privacy laws) with respect to such personal data when acting as a controller in accordance with this Privacy Policy.</p>
						<p>By installing and using the App, you agree (i) to comply with all applicable laws and regulations; (ii) that Call Recorder has no liability for your failure to comply with all applicable laws and regulations (including data privacy laws); and (iii) you agree to fully, finally and forever release, discharge, hold harmless and fully indemnify Call Recorder from and against any damages or liabilities of any kind related to such personal data when you act as a controller in accordance with this Privacy Policy while using the App and/or Services.</p>
					</li>
					<li>
						<h2 id="scope">3. SCOPE AND CATEGORIES OF PERSONAL DATA, LAWFUL BASIS FOR PERSONAL DATA PROCESSING AND DATA SUBJECT CATEGORIES</h2>
						<p>We strive to be compliant with GDPR and implemented data minimization principles.</p>
						<div className="legals_table_wrapper">
							<table className="legals_table">
							<tbody>
							<tr>
								<th>No.</th>
								<th>Scope and categories of personal data</th>
								<th>Data subject categories</th>
								<th>Processing purpose</th>
								<th>Lawful basis for personal data processing</th>
								<th>Company role in relation to the personal data</th>
							</tr>
							<tr>
								<td>1</td>
								<td>
									User phone number
								</td>
								<td>
									Services users
								</td>
								<td>
									to provide Services
								</td>
								<td>
									Performance of a contract; Consent
								</td>
								<td>
									Controller
								</td>
							</tr>
							<tr>
								<td>2</td>
								<td>
									Phone number of the user's interlocutor
								</td>
								<td>
									User's interlocutor
								</td>
								<td>
									to provide Services
								</td>
								<td>
									Consent
								</td>
								<td>
									Processor
								</td>
							</tr>
							<tr>
								<td>3</td>
								<td>
									IP address
								</td>
								<td>
									Services users
								</td>
								<td>
									to provide the features of the Website
								</td>
								<td>
									Performance of a contract; Consent
								</td>
								<td>
									Controller
								</td>
							</tr>
							<tr>
								<td>4</td>
								<td>
									Location data – for statistics of user’s areas involvement
								</td>
								<td>
									Services users
								</td>
								<td>
									to provide the features of the Website
								</td>
								<td>
									Performance of a contract; Consent
								</td>
								<td>
									Controller
								</td>
							</tr>
							<tr>
								<td>5</td>
								<td>
									Automatically collected data (cookies and similar technologies, device and connection data)
								</td>
								<td>
									Website users
								</td>
								<td>
									to provide the features of the Website
								</td>
								<td>
									Performance of a contract; Consent
								</td>
								<td>
									Controller
								</td>
							</tr>
							<tr>
								<td>6</td>
								<td>
									Call recording metadata (call duration, date, etc.)
								</td>
								<td>
									Services users
								</td>
								<td>
									To provide Services
								</td>
								<td>
									Performance of a contract; Consent
								</td>
								<td>
									Controller
								</td>
							</tr>
							<tr>
								<td>7</td>
								<td>
									User contact list
								</td>
								<td>
									Services users
								</td>
								<td>
									To provide Services
								</td>
								<td>
									Performance of a contract; Consent
								</td>
								<td>
									Controller
								</td>
							</tr>
							<tr>
								<td>8</td>
								<td>
									Call recording
								</td>
								<td>
									Services users
								</td>
								<td>
									To provide Services
								</td>
								<td>
									Performance of a contract; Consent
								</td>
								<td>
									Processor
								</td>
							</tr>
							<tr>
								<td>9</td>
								<td>
									Recording from a voice recorder
								</td>
								<td>
									Services users
								</td>
								<td>
									To provide Services
								</td>
								<td>
									Performance of a contract; Consent
								</td>
								<td>
									Processor
								</td>
							</tr>
							<tr>
								<td>10</td>
								<td>
									Call recording transcript (transcription)
								</td>
								<td>
									Services users
								</td>
								<td>
									To provide Services
								</td>
								<td>
									Performance of a contract; Consent
								</td>
								<td>
									Processor
								</td>
							</tr>
							</tbody>
						</table>
						</div>
						<p><strong>
							We may collect information (such as call recordings) from non-users who interact with Call Recorder (e.g. user's interlocutor), when they communicate with those users. Where required in the relevant jurisdiction (e.g, California, China etc.), the non-user will be presented with a beep notification, prompt or otherwise notified that they are being recorded in accordance with applicable laws.
						</strong></p>
						<p>The storage of this data takes place against the background that this is the only way to prevent the misuse of Services, and, if necessary, to make it possible to investigate committed offenses.</p>






						<p>The personal data are not transferred to third parties, except events, when it’s necessary with an aim to provide Services and/or there is a lawful obligation to transfer these data under request of government authorities and/or the transferring is executed in respect of criminal proceedings.</p>
						<p>We do not authorize the use of your personal data by any third party (only under exceptional conditions as described under "Legal Matters" below). We operate and maintain a variety of online security measures to safeguard and keep your personal information private and secured.</p>
						<p>We will also communicate with you in response to your inquiries, regarding any information or services you request.</p>
						<p>All Personal Data is stored in encrypted way on Services servers with using of the last encryption and protection technologies and standards.</p>
						<p>
							<strong>Data that we collect automatically.</strong><br/>
							We also collect and store information that is generated automatically as you navigate through our Website to enhance your experience on our Website by using tracking technologies such as Cookies, Log Files and Pixel tags.
						</p>
						<p>As you navigate our Website(s) information that we automatically collect is information in "log files" about your device’s connection to the Internet, length of time spent on the Website, and the pages accessed during each visit to the Website. We use this information to analyze trends, administer the Website, track user movement on the Website, and gather broad statistical information for aggregate use.</p>
						<p>
							<strong>
								Our Website uses cookies.
							</strong>
							Cookies are text files that are stored in a computer system via an Internet browser. Cookies are small files that your web browser places on your hard drive for record-keeping purposes. By showing how and when visitors use the Website, Cookies help us track user trends and patterns. They also prevent you from having to re-enter your preferences on certain areas of the Website where you have entered preference information before. Cookies contain a so-called cookie ID. A cookie ID is a unique identifier of the cookie. It consists of a character string through which Internet pages and servers can be assigned to the specific Internet browser in which the cookie was stored. This allows visited Internet sites and servers to differentiate the individual browser of the data subject from other Internet browsers that contain other cookies. A specific Internet browser can be recognized and identified using the unique cookie ID.
						</p>
						<p>Through the use of cookies, we can provide users of the Website with more user-friendly services that would not be possible without the cookie setting. By means of a cookie, the information and offers on our website can be optimized with the user in mind.</p>



						<p>The data subject may, at any time, prevent the setting of cookies through our Website by means of a corresponding setting of the Internet browser used, and may thus permanently deny the setting of Cookies. Furthermore, already set cookies may be deleted at any time via an Internet browser or other software programs. This is possible in all popular Internet browsers. If the data subject deactivates the setting of Cookies in the Internet browser used, not all functions of our website may be entirely usable.</p>
						<p>If you do not wish to receive Cookies you may be able to refuse them by adjusting your browser settings to reject Cookies. If you do so, we may be unable to offer you some of our functionalities, services or support. If you have previously visited our Website, you may also have to delete any existing cookies from your browser.</p>
						<p>Also, we may use Pixel tags (single-pixel image files also known as transparent GIFs, clear GIFs or web beacons) to access Cookies and to count users who visit the Website or open our HTML-formatted e-mail messages.</p>
						<p>Please, kindly read our <a class="greyUnderlined" href="/privacy">Privacy Policy</a>for more details about our rules of personal data processing and its secure storing.</p>
					</li>
					<li>
						<h2 id="encrypted">4. ENCRYPTED DATA</h2>
						<p>We have put in place security hardware, software and software update and network scanning procedures designed to safeguard and secure the information (including personal data) under our control and follow generally accepted industry standards. We work with third party service providers and vendors that use encryption and authentication to maintain the confidentiality of your personal data. If stored, we house personal information on systems behind firewalls that are only accessible to limited personnel, who are under DPA.</p>
						<p>We store personal data of all our users in an encrypted way. We use asymmetric public-private key cryptosystem RSA with key size is 4096 bit (further RSA) and symmetric-key algorithm AES with key size is 256 bit (further AES).</p>
						<p>Asymmetric public-private key cryptosystem RSA (key size is 4096 bit) use public encryption key and private decryption key. The public encryption key is storing in database (DB) in open way. The private decryption key is storing in DB encrypted using AES 256 using key which consist of the user’s password and secret key. It cannot be decrypted without user’s password and secret key.</p>
						<p>User’s credentials are user’s login is stored in DB. User’s password is not stored. We store only hash of the password. We generate public-private key pair for cryptosystem RSA during user’s sign up. This public-private key pair is unique for each user. The key pair is stored in DB encrypted format using AES 256.</p>
						<p>Login process: We use user’s password from login form for decrypting user’s original private key.</p>
						<p>Encryption data: We get opened data from devices using encrypted https protocol. We immediately start encryption process of the data on the server in the RAM without storing on server`s disks. We encrypted data using RSA using user’s public encryption key.</p>
						<p>The encrypted data is saved to our servers.</p>
						<p>Decryption: We get user’s decrypted private key. We decrypt user’s text data using user`s original private decryption key. We show this information to user.</p>
					</li>
					<li>
						<h2 id="legal_matters">5. LEGAL MATTERS</h2>
						<p>We consider your use of Services to be private. However, we may disclose your personal information stored in your account and/or on our servers and databases, in order to::</p>
						<ul>
							<li>comply with the law or legal process served on us;</li>
							<li>enforce and investigate potential violations of this Privacy Policy, Terms of use; including use of this Service to participate in, or facilitate activities that violate the law;</li>
							<li>investigate potential fraudulent activities; or</li>
							<li>protect the rights, property, or safety of our Company, its employees, its customers or the public.</li>
						</ul>
						<p>In the event of a change of control of Services (such as asset transfers through a merger, sale, assignment or liquidation of the business entity, or any of its properties, assets or equity) or, in the event of a direct or indirect sale of any of its publishing properties and/or its Website(s), App(s) in our possession will be transferred to the new owner/successor. You will be notified of any such transaction and have an ability to exercise your legal rights under GDPR. You may always change or delete your personal data or opt out by contacting us as provided below, or if the acquirer posts a new Privacy Policy with new contact information, you may change or delete your personal data or opt out by following any new instructions that are posted.</p>
						<p>As a controller, we may also transfer to one or more processors (e.g. call recording provider under DPA; call recording transcript (transcription) provider under DPA) personal data of a data subject for (a) Service provision (certain App features) purpose (e.g. call recording feature; call recording transcript (transcription) feature).</p>
						<p>(if applicable) In case we use third party technological services for the provision of Services, we may transfer your personal data internationally. Providers of such technological services may process personal data collected in the course of providing us their services as sub-processors only under DPA in accordance with GDPR.</p>
						<p>In case there might be a risk of unauthorized disclosure of personal data the controller communicates the personal data breach to the data subject without undue delay. However, as the controller has implemented appropriate technical and organizational protection measures, as encryption, and it was applied to the personal data affected by the personal data breach, the controller is not required to communicate the data subject.</p>
						<p>However, the encryption is useless if the access password or other credentials are weakly protected and stored by data subject. In this case the controller is not responsible for personal data breach.</p>
						<p>In the case of a personal data breach, we, as a controller, shall without undue delay and, where feasible, not later than 72 hours after having become aware of it, notify the personal data breach to the competent supervisory authority.</p>
					</li>
					<li>
						<h2 id="storing">6. WHERE DATA SUBJECT’S PERSONAL DATA ARE STORED</h2>
						<p>Usually, Personal data, which are provided by users are stored on the servers in Germany. We adopted all necessary security measures for protection of your Personal Data according to the best practices of security, protection and confidentiality. If we transfer your Personal data to third party service providers, we will compel each third-party service provider to adopt necessary security measures for protection of your Personal Data according to our data protection agreement.</p>
					</li>
					<li>
						<h2 id="your_rights">7. YOUR RIGHTS AS A DATA SUBJECT</h2>
						<h4>Access</h4>
						<div className="paragraph">
							You have right to request an explanation of the personal data we process about you. Also, you can request a copy of your personal data undergoing processing.
						</div>
						<h4>Data portability.</h4>
						<div className="paragraph">
							You have a right to receive the personal data concerning you, which you provided to us. You can make a request to transmit this data directly to another data controller in a structured, commonly used and machine-readable format. We will transmit your data directly to another controller in cases where it is technically feasible.
						</div>
						<h4>Restrict processing.</h4>
						<div className="paragraph">
							You have right to request us to temporarily or permanently stop processing all or some of your personal data.
						</div>
						<h4>Rectification.</h4>
						<div className="paragraph">
							You have right to request to rectify/correct any inaccurate data about you.
						</div>
						<h4>Erasure.</h4>
						<div className="paragraph">
							You have a right to be forgotten which means that we will delete all personal data that you have provided to us. Note, we may retain certain information as required by law and for legitimate business purposes permitted by law.
						</div>
						<h4>Object processing.</h4>
						<div className="paragraph">
							You can, at any time, object to the processing of your personal data on grounds relating to your particular situation. You have the right to object your personal data being processed for direct marketing purposes.
						</div>
						<h4>Right to lodge complaints.</h4>
						<div className="paragraph">
							You have the right to lodge complaints in relation to the data processing activities carried out by us to the competent data protection authorities.
						</div>
						<h4>Not to be subject to automated decision-making.</h4>
						<div className="paragraph">
							You have a right to not be subject to a decision based solely on automated decision making, including profiling, where the decision would have a legal effect on you or produce a similarly significant effect.
						</div>
						<h4>Right of confirmation.</h4>
						<div className="paragraph">
							Each data subject shall have the right granted by the European legislator to obtain from the controller the confirmation as to whether or not personal data concerning him or her are being processed.
						</div>
						<h4>Right to withdraw data protection consent.</h4>
						<div className="paragraph">
							Each data subject shall have the right granted by the European legislator to withdraw his or her consent to processing of his or her personal data at any time.
						</div>
						<p>If one of the aforementioned reasons applies, data subject may at any time contact us, as instructed at the end of this Privacy Policy. We shall promptly ensure that the request is complied with immediately. We may need up to 30 (thirty) calendar days to assure compliance with your request.</p>
					</li>
					<li>
						<h2 id="childrens_privacy">8. CHILDREN’S PRIVACY</h2>
						<p>Provision of Services generally not aimed at children. This Website is not intended for use by children under the age of 18.</p>
						<p>The Company is acting in compliance with COPPA. We do not knowingly collect information from children and minors. We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce our Privacy Policy by instructing their children to never provide information on our Website and App without their permission.</p>
						<p>Our App and Service is not directed to, nor do we knowingly collect personal data from children under the age of 13. If we obtain actual knowledge that we have collected personal data from a child, we will comply with industry guidelines and applicable laws and will promptly delete it, unless we are legally obligated to retain such data.</p>
					</li>
					<li>
						<h2 id="soting_and_removal">9. DATA STORAGE AND DATA REMOVAL</h2>
						<p>The criteria used to determine the period of storage of personal data is the respective statutory retention period. After expiration of that period and when we no longer need personal data, we routinely and securely delete or destroy it, as long as it is no longer necessary for the Purpose. As the data controller and/or processor, we shall process and store the personal data of the data subject only for the period necessary to achieve the purpose of Services provision, or as far as this is granted by the European legislator or other legislators in laws or regulations to which the controller and/or processor is subject to.</p>
						<p>However, in case of conflict situations in progress we may store personal data for 180 days period or more if the processing is necessary for the establishment, exercise or defense of legal claims and for compliance with a legal obligation which requires processing by Union or Member State law to which the controller is subject in case of an expired account or in case data subject requested to delete his personal data. Upon the expiry of this period or earlier, if a conflict situation is settled, all personal data and information gathered from a target device gets deleted.</p>
					</li>
					<li>
						<h2 id="miscellaneous">10. MISCELLANEOUS</h2>
						<p>We may modify this Privacy Policy at any time and post any changes to the Privacy Policy on the Website, so please review it frequently. We indicate the date of the current version of this Privacy Policy above, so you know when it was last updated.</p>
						<p>Changes to this Privacy Policy may not affect your personal data we have previously collected from you or after such changes.</p>
						<p>If you object to the changes, please contact us as provided below.</p>
						<p>This Privacy Policy is governed by and construed with the laws of Cyprus.</p>
						<p>The Cyprus courts shall have exclusive jurisdiction to settle any claim or dispute which might arise out of or in connection with this Privacy Policy.</p>
						<p><strong>If you have any questions, propositions, or you are willing to exercise you rights please in relation to this Privacy Policy, get in touch with us by the e-mail:<a class="greyUnderlined" href="mailto:support@reccall.app">support@reccall.app</a>.</strong></p>
					</li>
				</ol>
			</div>
		</section>
	);
};

export default Component;

