const BASE_URL = process.env.NODE_ENV === 'production' ? 'mobile-callrecorder.thd.cc' : 'qa1-mobile-callrecorder.dmr308.co'

export const API_ROUTES = {
	requestCode: {
		url: `https://${BASE_URL}/api/v1/auth/request-code`,
		requestSchema: {
			phone: ""
		}
	},
	login: {
		url: `https://${BASE_URL}/api/v1/auth/login`,
		requestSchema: {
			phone: "",
			code: "",
			deviceToken: ""
		}
	},
	tokenRefresh: {
		url: `https://${BASE_URL}/api/v1/auth/token-refresh`,
		requestSchema: {
			token: ""
		}
	}
}
