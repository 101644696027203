import styled from 'styled-components';
import common_bg from "assets/img/common_bg.png";
import search_glass from "assets/img/icons/magnifyingglass.png";
import chatIcon from "assets/img/icons/chat_icon.png";
import play_button from "assets/img/icons/play_button.png";
import triple_dot from "assets/img/icons/triple_dot.png";
import cloudIcon from "assets/img/icons/white_cloud_icon.png";
import downloadIcon from "assets/img/AppStoreWhiteLogo.png";
import greyCrossIcon from "assets/img/icons/grey_cross.png";


export const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 60px 0;
	width: 100%;
	background: #000000 url(${ common_bg }) no-repeat;
	background-size: 100% 100%;
	
	@media (max-width: 1169px) {
    padding: 0;
		margin: 20px 0;
	}
`

export const DashboardContentWrapper = styled.div`
	display: flex;
	flex-direction: row;
	padding: 12px 0;
	width: 970px;
 	height: 590px;
  background: rgba(28, 28, 30, 0.6);
  border-radius: 12px;
	gap: 0;
	

   @media (max-width: 1169px) {
     flex-direction: column-reverse;
		 gap: 20px;
		 width: 288px;
		 height: fit-content;
		 background: transparent;
		 padding: 0;
   }
`

export const RecordsWrapper = styled.div`
	display: flex;
  flex-direction: column;
	align-items: center;
	width: 270px;
	height: 100%;
	cursor: not-allowed;
	background: transparent;

  @media (max-width: 1169px) {
		width: 100%;
		background: #000;
  }
`

export const RecordsSearchField = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
  width: 247px;
  height: 45px;
  background: #1C1C1E;
  border-radius: 8px;
	padding: 10.5px 16px;
	box-sizing: border-box;
	position: absolute;
  margin-left: -5px;
	
  @media (max-width: 1169px) {
    width: 288px;
		position: relative;
    margin-left: 0;
  }
`

export const RecordsSearchInput = styled.input`
	width: 100%;
	height: 40px;
	background: transparent;
  font-family: Poppins, sans-serif;
  letter-spacing: 0;
  text-align: left;
	border: none;

  &::placeholder {
    font-size: 14px;
    font-weight: 600;
		line-height: 21px;
    color: rgba(255, 255, 255, 0.25);
  }

  &:focus {
    outline: none;
  }
	
  @media (max-width: 1169px) {
		
  }
`

export const SearchGlass = styled.img.attrs(p => ({ ...p, src: search_glass}))`
	width: 24px;
	height: 24px;
`

export const RecordsList = styled.div`
	display: flex;
	flex-direction: column;
  gap: 4px;
  align-items: center;
  width: 270px;
  height: 100%;
  overflow-y: scroll;
  cursor: not-allowed;
  padding-top: 57px;
	
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: #373738;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-track {
    background: #242426;
    width: 4px;
    border-radius: 2px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  @media (max-width: 1169px) {
    width: 100%;
		height: fit-content;
		overflow-y: auto;
		padding: 0;
    position: relative;
  }
`

export const RecordItemWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
  background: #000000;
  border-radius: 8px;
  width: 247px;
  height: 55px;
  min-height: 55px !important;
	padding: 0 12px;
	box-sizing: border-box;
	
  @media (max-width: 1169px) {
  	width: 288px;
  	height: 55px;
  }
`

export const RecordInfo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	
  @media (max-width: 1169px) {

  }
`

export const RecordLength = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.01em;
  text-align: left;
  color: rgba(255, 255, 255, 0.35);
`

export const RecordTitle = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.01em;
  text-align: left;
  color: rgba(255, 255, 255, 0.6);
`

export const RecordIconWrapper = styled.div`
  display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 32px;
  height: 32px;
	
`

export const RecordChatIcon = styled.img.attrs(p => ({ ...p, src: chatIcon}))`
	width: 14px;
	height: 14px;
`

export const RecordPlayButton = styled.img.attrs(p => ({ ...p, src: play_button}))`
  width: 32px;
  height: 32px;
`

export const TripleDot = styled.img.attrs(p => ({ ...p, src: triple_dot}))`
  width: 14px;
  height: 14px;
`

export const Icons = styled.div`
	display: flex;
	flex-direction: row;
`

export const DownloadText = styled.div`
  font-family: SF Pro Text, sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
	white-space: break-spaces;

  @media (max-width: 1169px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }
`

export const RedLink = styled.a`
	text-decoration: underline;
	color: #EB5560;
`

export const CloudIcon = styled.img.attrs(p => ({ ...p, src: cloudIcon }))`
	width: 36px;
	height: 34.5px;
`

export const DownloadIcon = styled.img.attrs(p => ({ ...p, src: downloadIcon }))`
	width: 111px;
	height: 37px;
`

export const ContentMockup = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 14px;
	width: 100%;
	background: transparent;
	border-radius: 0;

  @media (max-width: 1169px) {
		width: 288px;
		height: 288px;
    background: rgba(31, 31, 31, 0.6);
    backdrop-filter: blur(67.955px);
		border-radius: 12px;
  }
`

export const GreyCrossButton = styled.img.attrs(p => ({ ...p, src: greyCrossIcon }))`
	display: none;

  @media (max-width: 1169px) {
		display: block;
    width: 12px;
    height: 12px;
		margin-right: 21px;
		align-self: flex-end;
		cursor: pointer;
  }
`

export const SwipingHandgrip = styled.div`
	display: none;
	
  @media (max-width: 1169px) {
    display: flex;
    height: 5px;
    width: 35px;
    background: rgba(235, 235, 245, 0.3);
    border-radius: 2.5px;
    margin-top: 13px;
  }
`

export const ContentMockupTitle = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0;
  text-align: center;
	color: #fff;
	display: none;
	margin: 10px 0;

  @media (max-width: 1169px) {
    display: flex;
  }
`