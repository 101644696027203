
const Component = () => {
	return (
		<section className="privacy">
			<div className="wr">
				<h1>Terms of Use</h1>
				<h3>These Terms of Use were published on 25.03.2023</h3>
				<p><strong>PLEASE READ THESE TERMS OF USE CAREFULLY. <br/>THESE TERMS OF USE ARE BINDING.</strong></p>
				<p>Welcome to the Call Recorder - Transcribe Your Calls App (hereinafter the “App”, “Call Recorder”) and the Call Recorder website <a href="reccall.app">reccall.app</a> (hereinafter the “Website”). This App and Website is operated by Fortunex Limited, registered address: Georgiou A, 83, SHOP 17, Germasogeia, 4047, Limassol, Cyprus (hereinafter referred to as “We,” “Us,” “Our”, “Company”).</p>
				<p>These Terms of Use (hereinafter - the “ToU”) describe the terms and conditions applicable to your access and use of the App, Website and services, that are provided by Company (hereinafter collectively “Services”) made available through or in connection with the App.</p>
				<p>By using the App and/or Services, you agree to be bound by the following Terms of Use.</p>
				<p>The integral parts of these ToU are Privacy Policy and Cookie Policy.</p>
				<p>YOUR USE OF THE APP AND/OR SERVICES SIGNIFIES THAT YOU AGREE TO THESE TOU AND CONSTITUTES YOUR BINDING ACCEPTANCE OF THESE TOU, INCLUDING ANY MODIFICATIONS THAT WE MAKE FROM TIME TO TIME. WE WILL INFORM YOU ABOUT ANY MODIFICATIONS THAT OCCUR IN THE ESSENTIAL PARTS OF THE TOU AND WILL ASK IMPLIED CONSENT. THIS MEANS THAT IF YOU CONTINUE TO USE THE SERVICES, YOU AGREE WITH THESE MODIFICATIONS.</p>

				<h3>CONTENT</h3>
				<ol className="blueUpperCaseUnderlined">
					<li>1. <a href="#definitions">DEFINITIONS</a></li>
					<li>2. <a href="#provisions">GENERAL PROVISIONS</a></li>
					<li>3. <a href="#aspects">LEGAL ASPECTS OF APP OPERATION</a></li>
					<li>4. <a href="#app_stores">APP STORES</a></li>
					<li>5. <a href="#refunds">PAYMENTS AND REFUNDS</a></li>
					<li>6. <a href="#security">SECURITY</a></li>
					<li>7. <a href="#restrictions">RESTRICTIONS ON USE</a></li>
					<li>8. <a href="#conditions">END USER LICENSE CONDITIONS</a></li>
					<li>9. <a href="#i_p">INTELLECTUAL PROPERTY</a></li>
					<li>10. <a href="#liability">LIABILITY AND WARRANTIES</a></li>
					<li>11. <a href="#miscellaneous">MISCELLANEOUS</a></li>
				</ol>
				<ol>
					<li>
						<h2 id="definitions">1. DEFINITIONS</h2>
						<h4>Call Recorder</h4>
						<div className="paragraph">- a call recorder App allowing any individual acting in a private capacity to record their calls, manage recordings, and certain other features available in the App.</div>

						<h4>Subscription</h4>
						<div className="paragraph">– recurring payments for Call Recorder usage on a periodic basis.</div>

						<h4>Subscription period</h4>
						<div className="paragraph">– paid subscription period that consists of one calendar week or one calendar month.</div>
					</li>
					<li>
						<h2 id="provisions">2. GENERAL PROVISIONS</h2>
						<p>You are required to create an account on the App for purchasing and usage of Services. For the account creating you have to provide your phone number and 6-digit code that we sent yon on the respective phone number.</p>
						<p>When placing the order for the Services you have to provide Payment information, and you acknowledge and agree that you are 18 years of age or older.</p>
						<p>You are aware that We are not responsible for any third-party data leak in databases and/or DSN servers.</p>
						<p>You will not use the App or Services in violation of any laws you are subject to. Notwithstanding the foregoing, We are not responsible for the further use of the App and/or Services and it’s your sole responsibility.  </p>
						<p>You will properly follow all instructions and documentation provided by us to you at the App.</p>
						<p>You will not allow any other third party to access your account or to utilize the Services. In case access to your account has been granted to a third party deliberately, or by mistake, or in any other way without your consent, you acknowledge that we don’t bear the responsibility.</p>
						<p>You may not share or transfer any account. You may not disclose your password to anyone else.</p>
						<p>You must notify us immediately of any breach of security or unauthorized use of your account or if you believe that an account password may have been compromised.</p>
						<p>We will not be liable for any losses caused by any unauthorized use of your account.</p>
						<p>
							We reserve the right to modify, terminate, or refuse the Services to anyone for any reason and without notice at any time.<br/>
							You understand that we, in our sole discretion, may at any time for any reason suspend or terminate any license thereunder and disable the functionality of the Services. In this case, the information you may have already accessed will become unavailable without prior notice.
						</p>
						<p>In case you have any technical question, please contact customer service at <a className="greyUnderlined" href="mailto:support@reccall.app">support@reccall.app</a></p>
						<p>We try to help all of our users. You may get in touch with us about any matters, which belong to Services via Live Chat or e-mail. Our assistance via Live Chat and e-mail are absolutely free for you. </p>
					</li>
					<li>
						<h2 id="aspects">3. LEGAL ASPECTS OF APP OPERATION</h2>
						<p>Depending on the state or country in which you are located, the call recording feature of the App may violate laws requiring a person to obtain the consent of other parties on the call prior to recording the telephone conversation. You acknowledge and agree that as the individual directing the recording, you are solely responsible for determining the law in the state or country in which you are receiving calls prior to using the call recording feature in that state or country. You agree not to enable the call recording feature unless you have first determined that your use is legal.</p>
						<p>Certain states or countries may have laws prohibiting the recording of calls without consent from the parties to the call. You may violate a state’s or country's criminal law or create civil liability for yourself by listening in on or recording a telephone conversation without first informing the other parties that you are doing so. By installing and using the App, you agree (i) to comply with all applicable laws and regulations; (ii) that Call Recorder has no liability for your failure to comply with all applicable laws and regulations; and (iii) you agree to fully, finally and forever release, discharge, hold harmless and fully indemnify Call Recorder from and against any damages or liabilities of any kind related to your recording of conversations using the App and/or Services.</p>
						<p>Call recording feature is only allowed from your own phone. </p>
						<p>If you enable call recording feature, you shall be required to obtain consent from all participants of the call to record a conversation.</p>
						<p>You agree that you shall be the sole owner of the end products of your use of App (including call recordings, recordings from a voice recorder, call transcripts (transcriptions), etc.).</p>
						<p>Call Recorder shall in no way be responsible (whether in violation of applicable law or not) for your use (including sharing) of call recordings, recordings from a voice recorder, call transcripts (transcriptions), and specifically disclaims any responsibility thereto.</p>
						<p>Call Recorder reserves the right to immediately, and without notice, terminate or discontinue your account in the event it determines in its sole discretion that you have committed fraud or violated any applicable law or regulation or any term or condition of these ToU.</p>
						<p>You agree that the user is solely responsible for the lawful collection of personal data, obtaining all consents to such collection and compliance with all applicable laws and regulations (including data privacy laws) with respect to such personal data when acting as a controller in accordance with the Privacy Policy.</p>
						<p>By installing and using the App, you agree (i) to comply with all applicable laws and regulations; (ii) that Call Recorder has no liability for your failure to comply with all applicable laws and regulations (including data privacy laws); and (iii) you agree to fully, finally and forever release, discharge, hold harmless and fully indemnify Call Recorder from and against any damages or liabilities of any kind related to such personal data when you act as a controller in accordance with the Privacy Policy while using the App and/or Services.</p>
					</li>
					<li>
						<h2 id="app_stores">4. APP STORES</h2>
						<p>You acknowledge and agree that the availability of Call Recorder is dependent on the third party from which you received the App, e.g., the Apple App Store, and/or other app stores (collectively, "App Stores" and each, an "App Store"). </p>
						<p>You agree to pay all fees charged by the App Stores and us in connection with the Call Recorder. </p>
						<p>You agree to comply with, and your license to use the App is conditioned upon your compliance with, all applicable agreements, terms of use/service, and other policies of the App Stores. </p>
						<p>You acknowledge that the App Stores (and their subsidiaries) are a third party beneficiary of these Terms and will have the right to enforce these Terms.</p>
					</li>
					<li>
						<h2 id="refunds">5. PAYMENTS AND REFUNDS</h2>
						<p>Certain features of the Services may be offered for a fee. You can make a purchase through App Stores (hereinafter the “Purchase”).</p>
						<p>To the maximum extent permitted by applicable laws, we may change Purchase fees at any time. We will give you reasonable notice of any such pricing changes by posting the new prices on the Services or by sending you an email notification.</p>
						<p>You authorize us and the App Stores to charge the applicable fees to the payment method that you submit.</p>
						<p>Our Services may offer subscriptions that automatically renew. Unless you cancel your subscription before the end of the subscription period, you authorize us to charge you for the renewal term. The terms of autorenewal and cancellation procedure will be disclosed to you on the Services and/or App Stores.</p>
						<p>The subscription period consists of one calendar week or one calendar month for which payment is charged.</p>
						<p>Our Services may offer trial subscriptions (if applicable) that provide access to the Services for a limited period of time and auto renew unless you cancel before the end of the trial period. The terms of the autorenewal and cancellation procedure will be disclosed to you on the Services and/or App Stores.</p>
						<p>(if applicable) We reserve the right, in our absolute discretion, to modify or terminate any trial offer, your access to the Services during the trial, or any of these terms without notice and with no liability. We reserve the right to limit your ability to take advantage of multiple trials.</p>
						<p>Purchases made via an App Store are subject to such App Store's policies (including refund policies). This means when a Purchase is made through an App Store, we cannot grant refunds. You will have to contact an App Store support.</p>
					</li>
					<li>
						<h2 id="security">6. SECURITY</h2>
						<p>We care about the integrity and security of your personal information.</p>
						<p>You acknowledge that you willingly provide your personal information to Company while ordering the Services.<br/>
							Please, kindly read our <a className="greyUnderlined" href="/privacy">Privacy Policy</a> for more details about our rules of personal data processing and its secure storing.</p>
						<p>We do not control, monitor and/or are not aware of how the user exploits the Services. We may not be held responsible for the inappropriate use of thereof. It is at a user’s risk and responsibility. </p>
						<p>You acknowledge that we don’t encourage our users to exploit the Services illegally.</p>
					</li>
					<li>
						<h2 id="restrictions">7. RESTRICTIONS ON USE</h2>
						<p>You understand and agree that:</p>
						<ul>
							<li>you shall not use the Services for any unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, sexually explicit, profane, hateful, racially, ethnically, or otherwise objectionable purpose of any kind, including but not limited to, any purpose that encourages conduct that would constitute a criminal offense, gives rise to civil liability, or otherwise violates any applicable local, state, national, or international law;</li>
							<li>you shall not decompile, reverse engineer, disassemble, attempt to derive the source code of, or decrypt any portion of the Services, for any purpose</li>
							<li>you shall not copy, modify, sublicense, transmit, distribute, adapt, improve, or create any derivative works from the Services, including the source code of the Services;</li>
							<li>you shall not probe, scan or test the vulnerability of our Services on any system or network;</li>
							<li>you shall not upload viruses or other malicious code or otherwise compromise the security of our Services;</li>
							<li>you shall not interfere with or disrupt the Services offered by Call Recorder;</li>
							<li>you shall not impersonate or misrepresent your affiliation with any person or entity;</li>
							<li>you shall not create a false identity or otherwise attempt to mislead others as to the origin of the communication using the Services;</li>
							<li>you shall not use the App in a manner for which it is not designed or intended; </li>
							<li>you shall not transmit any content that violates the intellectual property or proprietary rights of another; poses or creates a privacy or security risk to any person; constitutes unauthorized advertising or spam; or in the sole opinion of Call Recorder is objectionable, offensive or may expose Call Recorder or its users to harm or liability of any type; </li>
							<li>you shall not use the App for creating a product, service or software that is, directly or indirectly, competitive with or in any way a substitute for any services, products or software offered by Call Recorder;</li>
							<li>you shall not interfere with or disrupt the Services oyou shall abide by the rules and policies established by Call Recorder. Such rules and policies may include, for example, required or automated updates, modifications, and/or reinstallations of the App. These obligations survive termination of this Agreement; andffered by Call Recorder;</li>
							<li>Call Recorder shall not be responsible to you or any third-party for your failure to comply with local, state, federal or international laws. </li>
							<li>If you are unable for any reason to comply with the foregoing, you must immediately stop using the Services.</li>
						</ul>
					</li>
					<li>
						<h2 id="conditions">8. END USER LICENSE CONDITIONS</h2>
						<p>Scope of License: The Company grants you a limited, non-exclusive, non-transferable revocable license (hereinafter the “License”) to use the Services.</p>
						<p>Call Recorder is Designed for Legal Use only. The Call Recorder is designed for legal use. The Company cannot provide legal advice to you regarding your use of the Services. You should consult your own legal advisor with respect to legality of using the Services in the manner you intend to use it prior to using the Services. </p>
						<p>License. It is understood and agreed that the Company is the owner of all right, title, and interest in and to the original, and any copies of the Services and related information, improvements, enhancements or derivatives thereto and ownership of all intellectual property rights pertaining thereto, in whole or in part, shall be, vest with, and remain the exclusive property of the Company. You, as a licensee, through your use of the Services do not acquire any ownership rights to the Services. The Services is protected by copyright laws and international copyright treaties. The rights granted in this license are limited to the right to use the Services and do not include any other intellectual property rights.</p>
						<p>You agree that you may not and will not: (i) sell, lease, rent, license, sublicense, redistribute, assign or grant the Services; (ii) decompile, disassemble, or reverse engineer the Services, in whole or in part; (iii) write or develop any derivative software, make attempt to derive the source code of, modify, or create derivative works of the Services, any updates, or any part or any other software program based upon the Services; (iv) provide, disclose, divulge or make available to, or permit use of the Services by any third party without the Company prior written consent; or (v) copy the Services.  </p>
						<p>Any attempt to do so is considered as a violation of the ToU and the rights of the Company and/or other copyright owners. If you breach this restriction, you may be subject to legal actions and damages.</p>
						<p>If you violate requirements mentioned above, you will be responsibility according to applicable law and international treaties on intellectual property rights and copyrights protection, and derivative software that was developed will be belonged to the Company from the moment of its creation.</p>
						<p>The Company may provide upgrades, modifications, updates, or additions (hereinafter the “Modifications”) to the Services during the term of the License. The terms of this ToU shall be applied to any such Modification unless such Modification is accompanied by a separate license, in which case the terms of that license will be applied. </p>
						<p>Term and Termination: The term of this License runs concurrently with the term of your subscription plan, which is the period during which you are authorized to use the Services. Your rights under this License will terminate automatically without notice from the Company if you fail to comply with any term(s) of this ToU or your subscription period expires. </p>
					</li>
					<li>
						<h2 id="i_p">9. INTELLECTUAL PROPERTY</h2>
						<p>Unauthorized use of Call Recorder trademark (if applicable), service mark or logo is a violation of the applicable trademark laws and prevalent international intellectual property laws</p>
						<p>Copyright. All intellectual property rights in the Call Recorder belong to and is vested in Company or its licensors, and are protected by the applicable law and international copyright laws.</p>
						<p>You may not use or reproduce any part of our App or the materials contained within it in any manner without first obtaining the prior written permission of Fortunex Limited.</p>
						<p>Any images that appear in our App with an acknowledgement to the Licensed Material include restrictions on downloading such material for other than personal use, and prohibit republication, retransmission, reproduction, or other use of the Licensed Material.</p>
					</li>
					<li>
						<h2 id="liability">10. LIABILITY AND WARRANTIES</h2>
						<p>TO THE MAXIMUM EXTENT PERMITTED BY LAW, COMPANY, ITS AFFILIATES, LICENSORS, AND BUSINESS PARTNERS (COLLECTIVELY, THE “RELATED PARTIES”) DISCLAIM ALL LIABILITY, WHETHER BASED IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, AND FURTHER DISCLAIMS ALL LOSSES, INCLUDING WITHOUT LIMITATION DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR SPECIAL DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH ACCESS TO OR USE OF THE APP AND\OR THE SERVICES, EVEN IF WE AND/ OR RELATED PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
						<p>WITHOUT LIMITING THE FOREGOING, IN NO CASE SHALL THE LIABILITY OF THE COMPANY OR ANY OF THE RELATED PARTIES EXCEED THE GREATER OF $50 OR THE AMOUNT THAT YOU PAID TO US OR OUR DESIGNEES DURING THE THREE (3) MONTHS PRIOR TO THE TIME THE CAUSE OF ACTION GIVING RISE TO LIABILITY AROSE.</p>
						<p>Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, the liability of the Company and its affiliates shall be limited to the fullest extent permitted by law.</p>
						<p>WE ARE NOT RESPONSIBLE FOR USING THE SERVICES AND/OR APP BY USERS UNLAWFULLY AND WITH UNLAWFUL AIMS. THE SERVICES ARE PROVIDED AND DEVELOPED WITH SECURITY AIMS ONLY AND CANNOT BE USED WITH VIOLATION OF RIGHTS, FREEDOMS AND INTERESTS OF THIRD PARTIES. WE DON’T KNOW AND CANNOT KNOW HOW USERS USE THE SERVICES. IF YOU BECOME AWARE THAT THE SERVICES ARE USED OR WERE USED UNLAWFULLY, YOU CAN NOTIFY US AND WE WILL ADOPT THE BEST EFFORTS TO STOP THE UNLAWFULLY SERVICES USING.</p>
						<p>THE APP AND THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS FOR YOUR USE, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING WITHOUT LIMITATION THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, AND THOSE ARISING FROM COURSE OF DEALING OR USAGE OF TRADE. WE MAKE NO WARRANTY AS TO THE ACCURACY, COMPLETENESS OR RELIABILITY OF ANY CONTENT AVAILABLE THROUGH, OR THE PERFORMANCE OF, THE APP OR THE SERVICES. YOU ARE RESPONSIBLE FOR VERIFYING ANY INFORMATION BEFORE RELYING ON IT. USE OF THE APP AND/OR THE SERVICES IS AT YOUR OWN RISK. WE DO NOT WARRANT THAT YOU WILL BE ABLE TO ACCESS OR USE THE APP AND/OR SERVICES AT THE TIMES OR LOCATIONS OF YOUR CHOOSING; THAT THE APP OR THE SERVICEs WILL BE UNINTERRUPTED OR ERROR-FREE; THAT DEFECTS WILL BE CORRECTED; OR THAT THE APP OR THE SERVICEs ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</p>
						<p>Because some states or jurisdictions do not allow the disclaimer of implied warranties, the foregoing disclaimer may not apply to you.</p>
					</li>
					<li>
						<h2 id="miscellaneous">11. MISCELLANEOUS</h2>
						<p>You agree to indemnify, defend, and hold the Company and the related parties harmless from any and all claims, demands, damages, or other losses, including reasonable attorneys’ fees, resulting or arising from your use of the App and Services or any breach by you of these ToU or any other policies that we may issue for the App and/or Services usage from time to time.  </p>
						<p>We may assign these ToU, in whole or in part, to any person or entity at any time with or without your consent.</p>
						<p>You may not assign the ToU without our prior written consent, and any unauthorized assignment by you shall be null and void.</p>
						<p>If any part of these ToU is determined to be invalid or unenforceable, then that portion shall be severed, and the remainder of the ToU shall be given full force and effect.</p>
						<p>Failure or delay by the Company to enforce compliance with the ToU does not constitute a waiver of these ToU.</p>
						<p>By using our App and/or the Services you confirm that you have read and consent to these Terms of Use as well as Privacy Policy.</p>
						<p>We may revise these Terms of Use and Privacy Policy at any time, without notice, and the new versions will be available on the App.</p>
						<p>If at any point you do not agree to any portion of these Terms of Use, you should immediately stop using the App and/or Services. </p>
						<p>The express waiver by us of any provision, condition, or requirement of these ToU shall not constitute a waiver of any future obligation to comply with such provision, condition or requirement.</p>
						<p>All notices given by you or required under this ToU shall be written and addressed to <a className="greyUnderlined" href="mailto:support@reccall.app">support@reccall.app</a></p>
						<p>All Policies and other documents, which are placed on the App, are integral parts of this Terms of Use.</p>
						<p>These ToU describe certain legal rights. You may have other rights under the laws of your state or country. These ToU do not change your rights under the laws of your state or country.</p>
						<p>This ToU is regulated by the laws of Cyprus. Any disputes should be resolved by the appropriate courts of Cyprus.</p>
						<p>In case the laws of your state or country do not permit you to use the Services, hereof you acknowledge and agree that it is upon your responsibility.</p>
						<p>The original, legally binding version of this document is written in English.</p>
						<p>For more information, please contact us by email <a className="greyUnderlined" href="mailto:support@reccall.app">support@reccall.app</a></p>
					</li>
				</ol>
			</div>
		</section>
	);
};

export default Component;

