import photoGuide_1 from 'assets/img/guide_1.png'
import photoGuide_2 from 'assets/img/guide_2.png'
import photoGuide_3 from 'assets/img/guide_3.png'
import photoGuide_4 from 'assets/img/guide_4.png'
import photoGuide_5 from 'assets/img/guide_5.png'
import videoCover from 'assets/img/video_cover.png'
import video from 'assets/img/landing_video_470x470.mp4'

export const contentTypes = {
	image: 'image',
	video: 'video'
}

export const GUIDE_STEPS = [{
	id: 0,
	contentType: 'image',
	contentSource: photoGuide_1
},{
	id: 1,
	contentType: 'image',
	contentSource: photoGuide_2
},{
	id: 2,
	contentType: 'image',
	contentSource: photoGuide_3
},{
	id: 3,
	contentType: 'image',
	contentSource: photoGuide_4
},{
	id: 4,
	contentType: 'image',
	contentSource: photoGuide_5
},{
	id: 5,
	contentType: 'video',
	contentSource: video,
	cover: videoCover
}]