import { KEYS } from "./keys.const";

export const SPANISH_TRANSLATIONS = {
	[KEYS.landing]: {
		bestAppBlock: {
			title: {
				text: 'Grabador de llamadas definitivo Aplicación para Dispositivos Móviles',
				decorate: true,
				prefix: '',
				body: 'Grabador de llamadas',
				suffix: ' definitivo Aplicación para Dispositivos Móviles'
			},
			subTitle: {
				text: 'Grabe, guarde y comparta conversaciones en su dispositivo iOS.',
				highlightedWord1: 'Grabe',
				highlightedWord2: 'guarde',
				highlightedWord3: 'y comparta'
			},
		},
		whyLoveItBlock: {
			title: {
				text: 'Por qué le encantará Call Recorder',
				decorate: true,
				prefix: 'Por qué ',
				body: 'le encantará',
				suffix: ' Call Recorder'
			},
			reasons: [
				{
					title: {
						text: 'Grabar las llamadas',
					},
					subTitle: 'Call Recorder le brinda un poder de grabación de llamadas ilimitado. Grabe todas las llamadas importantes sobre la marcha y revíselas más tarde, como audio o texto.'
				},{
					title: {
						text: 'Gran calidad de grabación',
					},
					subTitle: 'Grabe las llamadas entrantes y salientes con la mejor calidad posible. Elija entre múltiples opciones de grabación la que mejor se adapte a sus necesidades.'
				},{
					title: {
						text: 'Transcriba sus llamadas',
						decorate: true,
						prefix: '',
						body: 'Transcriba ',
						suffix: 'sus llamadas'
					},
					subTitle: 'Convierta las grabaciones de audio en notas escritas. Call Recorder transcribe todo por Usted automáticamente.',
				},{
					title: {
						text: 'Almacene grabaciones sin límites',
					},
					subTitle: 'Guarde su grabación en Google Drive o envíela a su correo electrónico.'
				},{
					title: {
						text: 'Privacidad total',
					},
					subTitle: 'Nos tomamos muy en serio su privacidad, por lo que todos los datos están encriptados y nunca los compartimos con nadie.'
				},{
					title: {
						text: 'Empiece a usar',
					},
				}
			]
		},
		getStartedBlock: {
			title: {
				text: '¿Listo para comenzar?',
				decorate: true,
				prefix: '¿Listo ',
				body: 'para comenzar',
				suffix: ' ?'
			},
			subTitle: 'Escuche, grabe y transcriba cualquier llamada.'
		},
		easyToRecordBlock: {
			title: {
				text: 'Es tan fácil grabar llamadas',
				decorate: true,
				prefix: 'Es tan',
				body: ' fácil grabar ',
				suffix: 'llamadas'
			},
			steps: [
				'Agregue su número de teléfono',
				'Marque el número de servicio de Call Recorder',
				'Introduzca el número que quiere grabar',
				'Fusionar las llamadas',
				'Grabar, transcribir y compartir \nlas grabaciones de llamadas'
			]
		},
		bestChoiceBlock: {
			title: {
				text: 'La mejor opción para una grabadora de llamadas',
				decorate: true,
				prefix: '',
				body: 'La mejor opción',
				suffix: ' para una grabadora de llamadas'
			},
			reasons: [{
				title: {
					prefix: '2.5\n',
					body: 'millones',
					suffix: '+'
				},
				subtitle: 'de llamadas registradas',
			},{
				title: {
					prefix: '',
					body: '250,000\n',
					suffix: '+ horas'
				},
				subtitle: ' de grabación completadas',
			},{
				title: {
					prefix: 'Más de 20,000+\n',
					body: 'transcripciones',
					suffix: ''
				},
				subtitle: 'procesadas',
			}]
		},
		whyCRBlock: {
			title: {
				text: '¿Por qué Grabador de llamadas\nde RecCall?',
				decorate: true,
				prefix: '¿Por qué Grabador de llamadas\n ',
				body: 'de RecCall',
				suffix: ' ?'
			},
			reasons: [[{
				text: 'Buena calidad de voz',
			},{
				text: 'Guardar y compartir grabaciones',
			},{
				text: 'Transcripción automática de audio',
			}],[{
				text: 'Grabaciones ilimitadas'
			},{
				text: 'Completamente privado',
			},{
				text: 'Cobertura mundial'
			}
			]]
		},
		reviewsBlock: {
			title: {
				text: 'Reseñas de nuestros clientes',
				decorate: true,
				prefix: 'Reseñas de nuestros ',
				body: 'clientes',
				suffix: ''
			},
			generalRating: '4.8',
			over30_1: 'más de 30,000',
			over30_2: 'reseñas',
			reviews: [{
				text: 'Call Recorder me ha salvado en múltiples ocasiones. Como asistente legal, constantemente hago malabarismos con llamadas telefónicas y correos electrónicos. Call Recorder realmente me ayuda a darle sentido a todo y me permite tener las cosas a mano para futuras referencias.',
				name: 'Karen J.'
			},{
				text: 'No puedo agradecerte lo suficiente. La compañía de mi tarjeta de crédito me estaba acosando por teléfono por una deuda pendiente que no debía. Pude capturar el acoso a través de audio y crear una transcripción. La prueba me ayudó en mi reclamo contra la compañía de tarjetas de crédito y finalmente condujo a una resolución pacífica.',
				name: 'Marvin Z.'
			},{
				text: 'Fácil de instalar. Fácil de usar. Y el audio es muy, muy claro. Me encanta la transcripción automática. 10 de 10. Le daría un 11 si pudiera.',
				name: 'Stephanie A.'
			}]
		}
	},
	[KEYS.common]: {
		tryNow: 'Pruebelo ahora',
		videoGuide: 'Videoguía',
		confirm: 'Confirmar',
		continue: 'Continuar',
		continueToPayment: 'Continuar a Pago',
		android: 'Android',
		ios: 'IOS',
		chooseCountry: 'Elija país',
		next: 'Próximo',
		previous: 'Anterior',
		navigation: 'Navegación',
		policies: 'Políticas',
		contactUs: 'Contáctenos',
		links : {
			faq: 'Preguntas más frecuentes',
			signIn: 'Iniciar sesión',
			signUp: 'Inscribirse',
			reviews: 'Reseñas',
			aboutUs: 'Sobre nosotros',
			terms: 'Condiciones de uso',
			bestChoice: 'Mejor elección',
			privacy: 'Política de privacidad',
			unsubscribe: 'Darse de baja',
			howItWorks: 'Cómo funciona',
			keyBenefits: 'Beneficios clave',
			howToRecord: 'Cómo grabar las llamadas',
			searchRecordings: 'Buscar grabaciones',
			support: 'support@reccall.app',
		}
	},
	[KEYS.choosePhone] : {
		title: {
			text: '¿Qué dispositivo quiere grabar?',
			prefix: '¿Qué dispositivo ',
			body: ' quiere grabar',
			suffix: ' ?'
		},
		steps: [{
			text: 'Paso 1',
		},{
			text: 'Paso 2',
		}],
		phones: [{
			text: 'Android',
		}, {
			text: 'IOS',
		}]
	},
	[KEYS.choosePlan] : {
		title: {
			text: 'Elija su plan',
			prefix: 'Elija  ',
			body: ' su ',
			suffix: 'plan'
		},
		mostPopular: 'MÁS POPULAR',
		planItems: [{
			title: '1 mes'
		},{
			title: 'PRUEBA DE 1 DÍA'
		},{
			title: '12 meses'
		}
		],
		pricePrefix: '$',
		priceSuffix: '',
		perMonth: '/mes*',
		priceHint: '*Precio mensual según suscripción de 1 o 12 meses. El precio está rebajado.',
		subscriptionsHint: '** The subscription will be automatically renewed after the end of the trial period ',
		platformHint: '***Currently works only on IOS',
	},
	[KEYS.verifyPhone] : {
		title: {
			text: 'Ingrese su\n número telefónico',
			prefix: 'Ingrese su\n',
			body: ' número telefónico',
			suffix: ''
		},
		inputLabel: 'Número de teléfono',
		invalidFormat: '* Número incorrecto de caracteres',
		chooseCountryTitle: 'Elija país'
	},
	[KEYS.verifyCode] : {
		title: {
			text: 'Ingrese un código de 6 dígitos',
			prefix: 'Ingrese un ',
			body: 'código de 6 dígitos',
			suffix: ''
		},
		getRequestPlaceholder: 'Su solicitud se está procesando, por favor espere...',
		checkRequestPlaceholder: 'Comprobando tu código...',
		resendSMS: 'Reenviar SMS después de',
		wrongCode: '*Codigo erroneo',
		sendAgain: 'ENVIAR CÓDIGO DE NUEVO',
		underlineDecoratedText: {
			text: 'Or change phone number!',
			prefix: 'Or change ',
			body: 'phone number',
			suffix: '!'
		}
	},
	[KEYS.successfulRegistration] : {
		title: {
			text: 'Registrado con éxito',
			prefix: '',
			body: '',
			suffix: ''
		},
		subTitle: {
			text: 'Hemos registrado correctamente\n su cuenta',
			prefix: '',
			body: '',
			suffix: ''
		},
		policyAgreement: 'Acepto la política de privacidad'
	},
	[KEYS.onboarding] : {
		steps: [{
			title: 'Paso 1',
			subTitle: 'Agregue su número de teléfono'
		},{
			title: 'Paso 2',
			subTitle: 'Marque el número de servicio de Call Recorder'
		},{
			title: 'Paso 3',
			subTitle: 'Introduzca el número que quiere grabar'
		},{
			title: 'Paso 4',
			subTitle: 'Fusionar las llamadas'
		},{
			title: 'Paso 5',
			subTitle: 'Grabe, transcriba y comparta las grabaciones de llamadas'
		},{
			title: 'Paso 6',
			subTitle: 'Mire el tutorial'
		}],
		downloadApp: {
			text: 'Descargue una aplicación\n de App Store a través del enlace',
			prefix: 'Descargue una aplicación\n de App Store a través ',
			body: 'del enlace',
			suffix: ''
		}
	},
	[KEYS.videoOnboarding] : {
		tabs: [
			'Extrovertido', 'Entrante'
		],
		breadcrumbs: [
			'Panel', 'Cómo grabar las llamadas'
		],
		records: [[{
			title: 'Paso 1',
			subTitle: {
				prefix: 'Presione el ',
				body: 'botón rojo grande',
				suffix: ' en la pantalla "Grabar llamadas"'
			}
		},{
			title: 'Paso 2',
			subTitle: {
				prefix: 'Verá una ventana emergente con un número de teléfono.',
				body: 'Haga clic en este número',
				suffix: ' para marcarlo (es una línea automatizada, no estará conectado con nadie).'
			}
		},{
			title: 'Paso 3',
			subTitle: {
				prefix: 'Espere a que el número responda y"',
				body: 'Combinar llamada',
				suffix: '".'
			}
		},{
			title: 'Paso 4',
			subTitle: {
				prefix: 'Espere a que el número que marcó responda y haga clic en el "',
				body: 'Combinar llamada',
				suffix: '" botón. Grabación de llamadas iniciada'
			}
		},{
			title: 'Verifique la aplicación para su grabación',
			subTitle: {
				prefix: 'Usar comando de voz. Diga "GO" para comenzar a',
				body: 'grabar llamadas',
				suffix: ''
			}
		}],[{
			title: 'Paso 1',
			subTitle: {
				prefix: 'Responde la llamada',
				body: '',
				suffix: ''
			}
		},{
			title: 'Paso 2',
			subTitle: {
				prefix: 'Presione el botón rojo grande en la pantalla "Grabar llamadas"',
				body: '',
				suffix: ''
			}
		},{
			title: 'Paso 3',
			subTitle: {
				prefix: 'Verá una ventana emergente con un número de teléfono. Haz clic en este número para marcarlo (es una línea automatizada, no estarás conectado con nadie).',
				body: '',
				suffix: ''
			}
		},{
			title: 'Paso 4',
			subTitle: {
				prefix: 'Marque el número con el que desea grabar su llamada',
				body: '',
				suffix: ''
			}
		},{
			title: 'Paso 5',
			subTitle: {
				prefix: 'Espere a que el número responda y "',
				body: 'Combinar llamada',
				suffix: '". La grabación de la llamada comenzará automáticamente.'
			}
		},{
			title: 'Verifique la aplicación para su grabación',
			subTitle: {
				prefix: 'Verifique la aplicación para su grabación',
				body: '',
				suffix: ''
			}
		}]],
		mobileGuideButton: 'Mire el tutorial',
		downloadSubTitle: 'Descargue una aplicación de App Store a través del enlace',
		hint: 'Solo puede hacer grabaciones de llamadas\n a través de la aplicación móvil'
	},
	[KEYS.dashboard] : {
		records: [{
			title: 'Grabación de llamada 1'
		},{
			title: 'Grabación de llamada 2'
		},{
			title: 'Grabación de llamada 3'
		},{
			title: 'Grabación de llamada 4'
		},{
			title: 'Grabación de llamada 5'
		},{
			title: 'Grabación de llamada 6'
		},{
			title: 'Grabación de llamada 7'
		},{
			title: 'Grabación de llamada 8'
		},{
			title: 'Grabación de llamada 9'
		},{
			title: 'Grabación de llamada 10'
		},{
			title: 'Grabación de llamada 11'
		},{
			title: 'Grabación de llamada 12'
		}],
		downloadApp: {
			text: 'Descargue una aplicación de\n App Store a través del enlace',
			prefix: 'Descargue una aplicación de\n App Store a través ',
			body: 'del enlace',
			suffix: ''
		},
		goToStore: {
			text: 'Ir a la aplicación para comenzar\n grabar llamadas',
			prefix: 'Ir a la ',
			body: 'aplicación',
			suffix: ' para comenzar\n grabar llamadas'
		},
		mobileTitle: 'Grabaciones de voz'
	},
	[KEYS.footer] : {
		CRForAll: 'Grabador de llamadas para todos',
		disclaimerTitle: 'Descargo de responsabilidad',
		disclaimerText: 'El software Call Recorder está DISEÑADO SÓLO PARA USO LEGAL. Es una violación de la ley aplicable y de las leyes de su jurisdicción local instalar el Software con licencia en un dispositivo que no es de su propiedad. La ley generalmente requiere que notifique a los propietarios de los dispositivos en los que tiene la intención de instalar el Software con licencia. La violación de este requisito podría resultar en severas sanciones monetarias y penales impuestas al infractor. Debe consultar a su propio asesor legal con respecto a la legalidad del uso del Software con licencia dentro de su jurisdicción antes de instalarlo y usarlo. Usted es el único responsable de instalar el Software con licencia en dicho dispositivo y sabe que Call Recorder no se hace responsable.'
	}
}