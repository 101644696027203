import { useTranslation } from "react-i18next";
import {FOOTER_CONFIGS, HEADER_CONFIGS, KEYS} from "constants";

import {
  Text,
  AppLogo,
  FooterBase,
  FooterLink,
  ForEveryone,
  FooterImage,
  FooterWrapper,
  FooterNavLink,
  DisclaimerText,
  DisclaimerTitle,
  FooterLinksList,
  DisclaimerWrapper,
  FooterLinksWrapper,
  FooterContentWrapper,
} from "./styles";

const Footer = ({ config = FOOTER_CONFIGS.default }) => {
  const { t } = useTranslation();
  return(
    <FooterBase>
      <FooterWrapper>
        <FooterContentWrapper>
          <FooterLinksWrapper>
            {FOOTER_CONFIGS[config].forAll && (
              <ForEveryone>
              <AppLogo/>
              <Text>
                {t(`${KEYS.footer}.CRForAll`)}
              </Text>
            </ForEveryone>
            )}

            {FOOTER_CONFIGS[config].links.map((block) => (
              <FooterLinksList key={block.id}>
                <FooterLink to={block.url} type={block.type}>
                  {t(`${KEYS.common}.${ block.key }`)}
                </FooterLink>

                {block.links.map((link) => (
                  link.nav ? (
                    <FooterNavLink key={link.text} href={link.url} type={link.type}>
                      {t(`${KEYS.common}.${ link.key }`)}
                    </FooterNavLink>
                  ) : (
                    <FooterLink key={link.text} to={ link.absolute ? { pathname: link.url } : link.url } type={link.type}>
                      {t(`${KEYS.common}.${ link.key }`)}
                    </FooterLink>
                  )
                ))}
              </FooterLinksList>
            ))}
          </FooterLinksWrapper>
          {
            FOOTER_CONFIGS[config].disclaimer && (
              <DisclaimerWrapper>
                <DisclaimerTitle>
                  {t(`${KEYS.footer}.disclaimerTitle`)}
                </DisclaimerTitle>
                <DisclaimerText>
                  {t(`${KEYS.footer}.disclaimerText`)}
                </DisclaimerText>
              </DisclaimerWrapper>
            )
          }
        </FooterContentWrapper>

        {FOOTER_CONFIGS[config].image && (
          <FooterImage/>
        )}
      </FooterWrapper>
    </FooterBase>
  )
}

export default Footer