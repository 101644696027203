import en_flag from 'assets/img/icons/flags/english.png'
import fr_flag from 'assets/img/icons/flags/french.png'
import pt_flag from 'assets/img/icons/flags/portugal.png'
import ko_flag from 'assets/img/icons/flags/korea.png'
import es_flag from 'assets/img/icons/flags/spain.png'
import tr_flag from 'assets/img/icons/flags/turkey.png'

export const SUPPORTED_LANGUAGES = [
	'en', 'fr', 'pt', 'ko', 'es', 'tr'
]

export const DEFAULT_LANG = SUPPORTED_LANGUAGES[0];

export const LANGUAGES = [
	{
		id: 0,
		title: 'English',
		code: SUPPORTED_LANGUAGES[0],
		icon: en_flag
	},
	{
		id: 1,
		title: 'French',
		code: SUPPORTED_LANGUAGES[1],
		icon: fr_flag
	},
	{
		id: 2,
		title: 'Portuguese',
		code: SUPPORTED_LANGUAGES[2],
		icon: pt_flag
	},
	{
		id: 3,
		title: 'Korean',
		code: SUPPORTED_LANGUAGES[3],
		icon: ko_flag
	},
	{
		id: 4,
		title: 'Spanish',
		code: SUPPORTED_LANGUAGES[4],
		icon: es_flag
	},
	{
		id: 5,
		title: 'Turkish',
		code: SUPPORTED_LANGUAGES[5],
		icon: tr_flag
	}
]