import { KEYS } from "./keys.const";

export const ENGLISH_TRANSLATIONS = {
	[KEYS.landing]: {
		bestAppBlock: {
			title: {
				text: 'Ultimate Call Recorder App for Mobile Devices',
				decorate: true,
				prefix: 'Ultimate ',
				body: 'Call Recorder',
				suffix: ' App for Mobile Devices'
			},
			subTitle: {
				text: 'Record, save, and share \nconversations on your iOS device.',
				highlightedWord1: 'Record',
				highlightedWord2: 'save',
				highlightedWord3: 'and share'
			},
		},
		whyLoveItBlock: {
			title: {
				text: 'Why You’ll Love Call Recorder',
				decorate: true,
				prefix: 'Why ',
				body: 'You’ll Love',
				suffix: ' Call Recorder'
			},
			reasons: [
				{
					title: {
						text: 'Record the calls',
					},
					subTitle: 'Call Recorder gives you unlimited call recording power. Record all important calls on the go and review them later - as audio or text.'
				},{
					title: {
						text: 'Great recording quality',
					},
					subTitle: 'Record incoming and outgoing calls in the best possible quality. Choose from multiple recording options the one that works best for you.'
				},{
					title: {
						text: 'Transcribe your calls',
						decorate: true,
						prefix: '',
						body: 'Transcribe ',
						suffix: 'your calls'
					},
					subTitle: 'Turn the audio recordings into written notes. Call Recorder transcribes everything for you automatically.',
				},{
					title: {
						text: 'Store recordings without limits',
					},
					subTitle: 'Save your recording to Google Drive or send them to your email.'
				},{
					title: {
						text: 'Full privacy',
					},
					subTitle: 'We take your privacy seriously, so all the data is encrypted, and we never share it with anyone.'
				},{
					title: {
						text: 'Start using',
					},
				}
			]
		},
		getStartedBlock: {
			title: {
				text: 'Ready to Get Started?',
				decorate: true,
				prefix: 'Ready to ',
				body: 'Get Started',
				suffix: '?'
			},
			subTitle: 'Listen, record, and transcribe any call.'
		},
		easyToRecordBlock: {
			title: {
				text: 'It`s So Easy to Record Calls',
				decorate: true,
				prefix: 'It`s So',
				body: ' Easy to Record ',
				suffix: 'Calls'
			},
			steps: [
				'Add your phone number',
				'Dial the Call Recorder service number',
				'Enter the number you want to record',
				'Merge the calls',
				'Record, transcribe and share \n' + 'the call recordings'
			]
		},
		bestChoiceBlock: {
			title: {
				text: 'Best Choice for a Call Recorder',
				decorate: true,
				prefix: '',
				body: 'Best choice',
				suffix: ' for a Call Recorder'
			},
			reasons: [{
				title: {
					prefix: '2.5\n',
					body: 'million',
					suffix: '+'
				},
				subtitle: 'calls recorded',
			},{
				title: {
					prefix: '',
					body: '250,000\n',
					suffix: '+ hours'
				},
				subtitle: 'of recording completed',
			},{
				title: {
					prefix: '20,000+\n',
					body: 'transcriptions',
					suffix: ''
				},
				subtitle: 'processed',
			}]
		},
		whyCRBlock: {
			title: {
				text: 'Why Call Recorder from RecCall?',
				decorate: true,
				prefix: 'Why Call Recorder\n',
				body: 'from RecCall',
				suffix: '?'
			},
			reasons: [[{
				text: 'Good voice quality',
				},{
					text: 'Save & share recordings',
				},{
					text: 'Automatic audio transcription',
			}],[{
					text: 'Unlimited recordings'
				},{
					text: 'Completely private',
				},{
					text: 'Worldwide coverage'
				}
			]]
		},
		reviewsBlock: {
			title: {
				text: 'Reviews from Our Customers',
				decorate: true,
				prefix: 'Reviews from Our ',
				body: 'Customers',
				suffix: ''
			},
			generalRating: '4.8',
			over30_1: 'over 30,000',
			over30_2: 'reviews',
			reviews: [{
				text: 'Call Recorder has saved me on multiple occasions. As a paralegal, I’m constantly juggling phone calls and emails. Call Recorder really helps me make sense of everything and allows me to keep things handy for future reference.',
				name: 'Karen J.'
			},{
				text: 'I can’t thank you enough. I was being harassed over the phone by my credit card company for an outstanding debt that I didn’t owe. I was able to capture the harassment via audio and create a transcription. The proof helped me in my claim against the credit card company and ultimately led to a peaceful resolution.',
				name: 'Marvin Z.'
			},{
				text: 'Easy to install. Easy to use. And the audio is really, really clear. Love the automatic transcription. 10 out of 10. I’d give it an 11 if I could.',
				name: 'Stephanie A.'
			}]
		}
	},
	[KEYS.common]: {
		tryNow: 'Try it now',
		videoGuide: 'Video guide',
		confirm: 'Confirm',
		continue: 'Continue',
		continueToPayment: 'Continue to Payment',
		android: 'Android',
		ios: 'IOS',
		chooseCountry: 'Choose country',
		next: 'Next',
		previous: 'Previous',
		navigation: 'Navigation',
		policies: 'Policies',
		contactUs: 'Contact us',
		links : {
			faq: 'FAQ',
			signIn: 'Sign in',
			signUp: 'Sign Up',
			reviews: 'Reviews',
			aboutUs: 'About us',
			terms: 'Terms of use',
			bestChoice: 'Best choice',
			privacy: 'Privacy policy',
			unsubscribe: 'Unsubscribe',
			howItWorks: 'How it works',
			keyBenefits: 'Key benefits',
			howToRecord: 'How to record calls',
			searchRecordings: 'Search recordings',
			support: 'support@reccall.app',
		}
	},
	[KEYS.choosePhone] : {
		title: {
			text: 'What device do you want to record?',
			prefix: 'What device do you ',
			body: ' want to record',
			suffix: '?'
		},
		steps: [{
			text: 'STEP 1',
		},{
			text: 'STEP 2',
		}],
		phones: [{
			text: 'Android',
		}, {
			text: 'IOS',
		}]
	},
	[KEYS.choosePlan] : {
		title: {
			text: 'Choose your plan',
			prefix: 'Choose  ',
			body: ' your ',
			suffix: 'plan'
		},
		mostPopular: 'MOST POPULAR',
		planItems: [{
				title: '1 Month'
			},{
				title: '1 DAY TRIAL'
			},{
				title: '12 Months'
			}
		],
		pricePrefix: '$',
		priceSuffix: '',
		perMonth: '/mo*',
		priceHint: '*Monthly price as per 1 or 12-month subscription. The price is discounted.',
		subscriptionsHint: '** The subscription will be automatically renewed after the end of the trial period ',
		platformHint: '***Currently works only on IOS',
	},
	[KEYS.verifyPhone] : {
		title: {
			text: 'Enter your\n phone number',
			prefix: 'Enter your\n',
			body: ' phone number',
			suffix: ''
		},
		inputLabel: 'Phone number',
		invalidFormat: '* Wrong number of characters',
		chooseCountryTitle: 'Choose country'
	},
	[KEYS.verifyCode] : {
		title: {
			text: 'Input a 6-digit code',
			prefix: 'Input a ',
			body: '6-digit code',
			suffix: ''
		},
		getRequestPlaceholder: 'Your request is processing, please wait...',
		checkRequestPlaceholder: 'Checking your code...',
		resendSMS: 'Resend SMS after',
		wrongCode: '* Wrong code',
		sendAgain: 'SEND CODE AGAIN',
		underlineDecoratedText: {
			text: 'Or change phone number!',
			prefix: 'Or change ',
			body: 'phone number',
			suffix: '!'
		}
	},
	[KEYS.successfulRegistration] : {
		title: {
			text: 'Successfully registered',
			prefix: '',
			body: '',
			suffix: ''
		},
		subTitle: {
			text: 'We`ve successfully registered\n  your account',
			prefix: '',
			body: '',
			suffix: ''
		},
		policyAgreement: 'I agree to the Privacy Policy'
	},
	[KEYS.onboarding] : {
		steps: [{
			title: 'STEP 1',
			subTitle: 'Add your phone number'
		},{
			title: 'STEP 2',
			subTitle: 'Dial the Call Recorder service number'
		},{
			title: 'STEP 3',
			subTitle: 'Enter the number you want to record'
		},{
			title: 'STEP 4',
			subTitle: 'Merge the calls'
		},{
			title: 'STEP 5',
			subTitle: 'Record, transcribe and share the call recordings'
		},{
			title: 'STEP 6',
			subTitle: 'Watch the tutorial'
		}],
		downloadApp: {
			text: 'Download an app\n from App Store via the link',
			prefix: 'Download an app\n from App Store via the',
			body: ' link',
			suffix: ''
		}
	},
	[KEYS.videoOnboarding] : {
		tabs: [
			'Outgoing', 'Incoming'
		],
		breadcrumbs: [
			'Dashboard', 'How to record calls'
		],
		records: [[{
			title: 'Step 1',
			subTitle: {
				prefix: 'Press the ',
				body: 'big red button',
				suffix: ' on "Record calls" screen'
			}
		},{
			title: 'Step 2',
			subTitle: {
				prefix: 'You will see a pop-up with a phone number.',
				body: 'Click on this number',
				suffix: ' to dial it (it`s an automated line, you won`t be connected to anyone).'
			}
		},{
			title: 'Step 3',
			subTitle: {
				prefix: 'Wait for the number to answer and "',
				body: 'Merge call',
				suffix: '".'
			}
		},{
			title: 'Step 4',
			subTitle: {
				prefix: 'Wait for the number you dialed to answer and click the "',
				body: 'Merge call',
				suffix: '" button. Call recording started'
			}
		},{
			title: 'Use voice command',
			subTitle: {
				prefix: 'Say "GO" to ',
				body: 'start recording calls',
				suffix: ''
			}
		}],[{
			title: 'Step 1',
			subTitle: {
				prefix: 'Answer the call',
				body: '',
				suffix: ''
			}
		},{
			title: 'Step 2',
			subTitle: {
				prefix: 'Press the big red button on the "Record calls" screen',
				body: '',
				suffix: ''
			}
		},{
			title: 'Step 3',
			subTitle: {
				prefix: 'You will see a pop-up with a phone number.',
				body: 'Click on this number',
				suffix: ' to dial it (it\'s an automated line, you won\'t be connected to anyone).'
			}
		},{
			title: 'Step 4',
			subTitle: {
				prefix: 'Dial the number that you want to record your call with',
				body: 'Tap on this number and call',
				suffix: ''
			}
		},{
			title: 'Step 5',
			subTitle: {
				prefix: 'Wait for the number to answer and "',
				body: 'Merge call',
				suffix: '". The call recording will automatically start.'
			}
		},{
			title: 'Check the app for your recording',
			subTitle: {
				prefix: 'Use voice command. Say "GO" to',
				body: 'start recording calls',
				suffix: ''
			}
		}]],
		mobileGuideButton: 'Watch guide',
		downloadSubTitle: 'Download an app from App Store',
		hint: 'You can make call recordings only\n through the mobile application'
	},
	[KEYS.dashboard] : {
		records: [{
			title: 'Call Recording 1'
		},{
			title: 'Call Recording 2'
		},{
			title: 'Call Recording 3'
		},{
			title: 'Call Recording 4'
		},{
			title: 'Call Recording 5'
		},{
			title: 'Call Recording 6'
		},{
			title: 'Call Recording 7'
		},{
			title: 'Call Recording 8'
		},{
			title: 'Call Recording 9'
		},{
			title: 'Call Recording 10'
		},{
			title: 'Call Recording 11'
		},{
			title: 'Call Recording 12'
		}],
		downloadApp: {
			text: 'Download an app from\n App Store via the link',
			prefix: 'Download an app from\n App Store via',
			body: ' the link',
			suffix: ''
		},
		goToStore: {
			text: 'Go to the application to start\n recording calls',
			prefix: 'Go to the ',
			body: 'application',
			suffix: ' to start\n recording calls'
		},
		mobileTitle: 'Voice Recordings'
	},
	[KEYS.footer] : {
		CRForAll: 'Call Recorder for everyone',
		disclaimerTitle: 'Disclaimer',
		disclaimerText: 'Call Recorder software is INTENDED FOR LEGAL USE ONLY. It is a violation of the applicable law and your local jurisdiction laws to install the Licensed Software onto a device you do not own. The law generally requires you to notify owners of the devices on which you intend to install the Licensed Software. The violation of this requirement could result in severe monetary and criminal penalties imposed on the violator. You should consult your own legal advisor with respect to the legality of using the Licensed Software within your jurisdiction prior to installing and using it. You are solely responsible for installing the Licensed Software onto such device, and you are aware that Call Recorder cannot be held responsible.'
	}
}