import {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Stepper,
  RedTitle,
  BlockTitle,
  ButtonTypes,
} from "components";
import { KEYS } from "constants";

import { STEPS, PLAN_OPTIONS } from "./choosePlan.const";

import {
  Hint,
  PlanItem,
  PlansList,
  CrossedText,
  RadioButton,
  OriginPrice,
  MobilePrice,
  PlansWrapper,
  PlanItemPrice,
  PlanItemTitle,
  SafetyIconsWeb,
  PlanItemPricing,
  PlanPriceWrapper,
  RadioButtonLabel,
  PlanItemPriceSub,
  MostPopularBlock,
  SafetyIconsMobile,
  ChoosePlanWrapper,
  RadioButtonWrapper,
  PlanItemTitleWrapper,
  MostPopularMobileBlock,
} from "./styles";


const buttonSettings = '' +
  'padding: 12px 45px;' +
  'gap: 10px;' +
  'width: 265px !important;' +
  'height: 50px !important;' +
  'margin-top: 36px;' +
  'margin-bottom: 22px;' +
  'cursor: pointer;' +
  '@media (max-width: 1169px) {' +
  '  width: 288px !important;' +
  '  height: 47px !important;' +
  '  margin-top: 14px;' +
  '  margin-bottom: 16px;' +
  '}'


const Plans = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedOptionId, setSelectedOptionId] = useState(1);

  const stepsList = STEPS.map((s, i) => ({ ...s, text: t(`${KEYS.choosePhone}.steps.${i}.text`) }))

  const handleSelectChange = (id) => {
    setSelectedOptionId(id);
  };

  const handleSubmit = () => {
    window.fastspring.builder.push({
      reset: true,
      products: [
        {
          quantity: 1,
          path: PLAN_OPTIONS[selectedOptionId].fsId
        }
      ]
    });

    window.fastspring.builder.checkout();
  }

  useEffect(() => {
    window.dataPopupClosed = async function (data) {

      const orderId = data?.id

      if(!orderId) {
        return
      }

      localStorage.setItem('orderId', orderId);
      navigate('/verify-number');
    }
  }, [])

  return (
    <ChoosePlanWrapper>
      <BlockTitle>
        {t(`${KEYS.choosePlan}.title.prefix`)}
        <RedTitle>
          {t(`${KEYS.choosePlan}.title.body`)}
        </RedTitle>
        {t(`${KEYS.choosePlan}.title.suffix`)}
      </BlockTitle>
      <Stepper steps={stepsList} activeStepId={STEPS[1].id}/>

      <PlansWrapper>
        <SafetyIconsWeb/>
        <MostPopularBlock>
          {t(`${KEYS.choosePlan}.mostPopular`)}
        </MostPopularBlock>
        <PlansList>
          {PLAN_OPTIONS.map((plan, i) => (
            <PlanItem onClick={() => handleSelectChange(plan.id)} isSelected={selectedOptionId === plan.id}>
              <RadioButtonWrapper>
                <RadioButton
                  type="radio"
                  name="radio"
                  checked={selectedOptionId === plan.id}
                />
                <RadioButtonLabel />
              </RadioButtonWrapper>

              <PlanItemPricing>

                <PlanItemTitleWrapper>
                  <PlanItemTitle isActive={selectedOptionId === plan.id}>
                    {t(`${KEYS.choosePlan}.planItems.${i}.title`)}
                  </PlanItemTitle>
                  {plan.mostPopular && (<MostPopularMobileBlock>
                    {t(`${KEYS.choosePlan}.mostPopular`)}
                  </MostPopularMobileBlock>)}
                </PlanItemTitleWrapper>

                <PlanPriceWrapper>
                  <PlanItemPrice>
                    {t(`${KEYS.choosePlan}.pricePrefix`)}
                    {plan.currentPrice}
                    {t(`${KEYS.choosePlan}.priceSuffix`)}
                    {plan.monthly && (
                      <PlanItemPriceSub>
                        &nbsp;{t(`${KEYS.choosePlan}.perMonth`)}
                      </PlanItemPriceSub>
                    )}
                  </PlanItemPrice>
                  {plan.originPrice && (<MobilePrice>
                    <CrossedText>
                      {t(`${KEYS.choosePlan}.pricePrefix`)}
                      {plan.originPrice}
                      {t(`${KEYS.choosePlan}.priceSuffix`)}
                    </CrossedText> {t(`${KEYS.choosePlan}.perMonth`)}
                  </MobilePrice>)}


                </PlanPriceWrapper>
                <OriginPrice>
                  {plan.originPrice && (
                    <>
                      <CrossedText>
                        {t(`${KEYS.choosePlan}.pricePrefix`)}
                        {plan.originPrice}
                        {t(`${KEYS.choosePlan}.priceSuffix`)}
                      </CrossedText> {t(`${KEYS.choosePlan}.perMonth`)}
                    </>
                  )}
                </OriginPrice>

              </PlanItemPricing>
            </PlanItem>
          ))}
        </PlansList>
      </PlansWrapper>

      <Button type={ButtonTypes.red} style_={buttonSettings} to={'/choose-plan'} onClick={handleSubmit}>
        {t(`${KEYS.common}.continueToPayment`)}
      </Button>
      <SafetyIconsMobile/>
      <Hint>
        {t(`${KEYS.choosePlan}.priceHint`)}
      </Hint>
      <Hint>
        {t(`${KEYS.choosePlan}.subscriptionsHint`)}
      </Hint>
      <Hint>
        {t(`${KEYS.choosePlan}.platformHint`)}
      </Hint>
    </ChoosePlanWrapper>
  );
}

export default Plans;