const ButtonTypes = {
  red: 'red',
  dark: 'dark',
  darkHeader: 'darkHeader',
  darkGreyHeader: 'darkGreyHeader',
}

export const UI_CONFIG_TYPES = {
  default: 'default',
  minimal: 'minimal',
  checkout: 'checkout',
  extended: 'extended',
  dashboard: 'dashboard',
  onboarding: 'onboarding',
  videoOnboarding: 'videoOnboarding',
}

export const UI_CONFIGS = {
  [UI_CONFIG_TYPES.default]: {
    header: UI_CONFIG_TYPES.default,
    footer: UI_CONFIG_TYPES.default
  },
  [UI_CONFIG_TYPES.checkout]: {
    header: UI_CONFIG_TYPES.checkout,
    footer: UI_CONFIG_TYPES.extended
  },
  [UI_CONFIG_TYPES.onboarding]: {
    header: UI_CONFIG_TYPES.onboarding,
    footer: UI_CONFIG_TYPES.extended
  },
  [UI_CONFIG_TYPES.dashboard]: {
    header: UI_CONFIG_TYPES.dashboard,
    footer: UI_CONFIG_TYPES.extended
  },
  [UI_CONFIG_TYPES.videoOnboarding]: {
    header: UI_CONFIG_TYPES.videoOnboarding,
    footer: UI_CONFIG_TYPES.extended
  },
[UI_CONFIG_TYPES.minimal]: {
    header: UI_CONFIG_TYPES.minimal,
    footer: UI_CONFIG_TYPES.extended
  }
}

export const LINKS_TYPES = {
  red: 'red',
  anchor_: 'anchor_',
  anchorSpecial: 'anchorSpecial',
  common: 'common',
  white: 'white',
  navTitle: 'navTitle',
  underlined: 'underlined',
}

const aboutUs = { url: '/#about-us', key: 'links.aboutUs', type: LINKS_TYPES.anchor_, nav: true };
const benefits = { url: '/#key-benefits', key: 'links.keyBenefits', type: LINKS_TYPES.anchor_, nav: true };
const howItWorks = { url: '/#how-it-works', key: 'links.howItWorks', type: LINKS_TYPES.anchor_, nav: true };
const bestChoice = { url: '/#best-choice', key: 'links.bestChoice', type: LINKS_TYPES.anchor_, nav: true };
const reviews = { url: '/#reviews', key: 'links.reviews', type: LINKS_TYPES.anchor_, nav: true };

const ANCHOR_LINKS = [
  aboutUs,
  benefits,
  howItWorks,
  bestChoice,
  reviews
]

const LEGAL_LINKS = [
  {url: '/terms', key: 'links.terms', type: LINKS_TYPES.anchor_, nav: true},
  {url: '/privacy', key: 'links.privacy', type: LINKS_TYPES.anchor_, nav: true}
]

const FAQLink = {url: '/faq', key: 'links.faq', type: LINKS_TYPES.common}
const UnsubscribeLink = {url: '/unsubscribe', key: 'links.unsubscribe', type: LINKS_TYPES.common}
const SignInLink = {url: '/verify-number', key: 'links.signIn', type: LINKS_TYPES.red}
const Support = {url: 'mailto:support@reccall.app', key: 'links.support', type: LINKS_TYPES.underlined, nav: true}

export const HEADER_CONFIGS = {
  [UI_CONFIG_TYPES.default]: {
    navLinks: ANCHOR_LINKS,
    redirectLinks: [FAQLink, SignInLink],
    button: {
      key: 'tryNow',
      url: '/choose-phone',
      type: ButtonTypes.darkHeader
    }
  },
  [UI_CONFIG_TYPES.checkout]: {
    navLinks: [],
    redirectLinks: [SignInLink],
    button: null,
    redirectLinksMobile: true
  },
  [UI_CONFIG_TYPES.onboarding]: {
    navLinks: [],
    redirectLinks: [],
    button: null
  },
  [UI_CONFIG_TYPES.dashboard]: {
    navLinks: [],
    redirectLinks: [],
    button: {
      key: 'links.howToRecord',
      url: '/video-onboarding',
      type: ButtonTypes.darkGreyHeader
    }
  },
  [UI_CONFIG_TYPES.videoOnboarding]: {
    navLinks: [],
    redirectLinks: [],
    button: null
  },
  [UI_CONFIG_TYPES.minimal]: {
    navLinks: [],
    redirectLinks: [],
    button: null
  }
}

const FooterNavigationColumn = {
  key: 'navigation',
  nav: true,
  url: '/#headquarters',
  type: LINKS_TYPES.navTitle,
  links: ANCHOR_LINKS
}

const FooterNav = [
  {
    key: 'policies',
    nav: true,
    url: '/#policies',
    type: LINKS_TYPES.navTitle,
    links: LEGAL_LINKS
  },
  {
    key: 'contactUs',
    nav: true,
    url: '/#policies',
    type: LINKS_TYPES.navTitle,
    links: [Support]
  }
]

export const FOOTER_CONFIGS = {
  [UI_CONFIG_TYPES.default]: {
    links: [FooterNavigationColumn, ...FooterNav],
    disclaimer: true,
    forAll: false,
    image: true
  },
  [UI_CONFIG_TYPES.extended]: {
    links: [FooterNavigationColumn, ...FooterNav],
    disclaimer: true,
    forAll: true,
    image: false
  }
}