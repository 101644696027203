import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, ButtonTypes, Modal } from "components";
import { debounce } from "helpers";
import { KEYS} from "constants";

import { DefaultSelect } from '../CountriesSelector/CountryCodes'
import CountrySearchForm from "../CountriesSelector/CountrySearchForm";

import {
	RedText,
	BlockTitle,
	CountryIcon,
	WrongNumber,
	MenuItemText,
	ArrowDownIcon,
	buttonSettings,
	CountryDialCode,
	PhoneNumberField,
	PhoneNumberFieldLabel,
	PhoneNumberFieldWrapper
} from "../styles";

const VerifyNumber = ({ handleSubmit }) => {
	const { t } = useTranslation();
	const [selectedCountry, setSelectedCountry] = useState(DefaultSelect);
	const [phoneNumber, setPhoneNumber] = useState('');
	const [isPhoneNumberValid, setIsPhoneNumberValid] = useState();
	const [isErrorShown, setIsErrorShown] = useState(false);
	const [countriesModalShown, setCountriesModalShown] = useState(false);

	const checkValidity = (number) => {
		const isValid = /^(?=.{7,14}$)/.test(number);

		if(isValid && isValid !== isPhoneNumberValid) {
			setIsPhoneNumberValid(true)
			setIsErrorShown(false);
		}
		if (!isValid && isValid !== isPhoneNumberValid) {
			setIsPhoneNumberValid(false)
			setIsErrorShown(true);
		}

		if(!number) {
			setIsErrorShown(false)
		}
	}

	const debouncedCheck = useCallback(debounce(checkValidity, 1000), [isPhoneNumberValid])

	const handlePhoneNumberChange = (e) => {
		setPhoneNumber(e.target.value)
		debouncedCheck(e.target.value);
	}

	const handleClick = () => {
		handleSubmit(`${selectedCountry.dial_code}${phoneNumber}`)
	}

	const handleModalOpen = () => {
		setCountriesModalShown(true)
	}

	const handleClose = () => {
		setCountriesModalShown(false)
	}

	return (
		<>
			<BlockTitle>
				{t(`${KEYS.verifyPhone}.title.prefix`)}
				<RedText>
					{t(`${KEYS.verifyPhone}.title.body`)}
				</RedText>
				{t(`${KEYS.verifyPhone}.title.suffix`)}
			</BlockTitle>
			<PhoneNumberFieldLabel>
				{t(`${KEYS.verifyPhone}.inputLabel`)}:
			</PhoneNumberFieldLabel>

			<PhoneNumberFieldWrapper showError={isErrorShown}>
					<CountryIcon src={selectedCountry.flagIcon} onClick={handleModalOpen}/>

					<CountryDialCode onClick={handleModalOpen}>
						<MenuItemText>{selectedCountry.dial_code}</MenuItemText>
						<ArrowDownIcon/>
					</CountryDialCode>

					<PhoneNumberField
						placeholder="00 000 00 00"
						value={phoneNumber}
						onChange={handlePhoneNumberChange}
						type='number'
						inputmode='numeric'
					/>
			</PhoneNumberFieldWrapper>

			{isErrorShown && (
				<WrongNumber>
					{t(`${KEYS.verifyPhone}.invalidFormat`)}
				</WrongNumber>
			)}
			{isPhoneNumberValid && (
				<Button type={ButtonTypes.red} style_={buttonSettings} to='/verify-number' onClick={handleClick}>
					{t(`${KEYS.common}.continue`)}
				</Button>
			)}

			{countriesModalShown && (
				<Modal>
					<CountrySearchForm closeModal={handleClose} selectCountry={setSelectedCountry}/>
				</Modal>
			)}

		</>
	);
}

export default VerifyNumber;
