export const ButtonConfigs = {
  red: `
    color: #FFFFFF;
    background: #EB5560;
  `,
  dark: `
    color: #EB5560;
    background: rgba(255, 255, 255, 0.1);
    
    @media (max-width: 1169px) {
      min-width: 165px;
      height: 47px;
    }
  `,
  darkHeader: `
    color: #EB5560;
    background: rgba(255, 255, 255, 0.1);
    margin-left: 16px;
    
    @media (max-width: 1169px) {
      min-width: 130px !important;
      height: 38px !important;
      font-size: 14px !important;
      margin-right: 8px !important;
      padding: 8px 10px !important;
    } 
  `,
  darkGreyHeader: `
    color: rgba(255, 255, 255, 0.8);
    background: rgba(255, 255, 255, 0.1);
    margin-left: 16px;
    
    @media (max-width: 1169px) {
      min-width: 130px !important;
      height: 38px !important;
      font-size: 14px !important;
      margin-right: 8px !important;
      padding: 8px 0 !important;
    } 
  `
}

export const ButtonTypes = {
  red: 'red',
  dark: 'dark',
  darkHeader: 'darkHeader',
  darkGreyHeader: 'darkGreyHeader',
}