import { useTranslation } from "react-i18next";

import { KEYS} from "constants";
import {
  Button,
  RedTitle,
  BlockTitle,
  ButtonTypes,
} from "components";

import {
  WhyCR_Icon,
  WhyCRImage,
  WhyCRList,
  WhyCRWrapper,
  WhyCRListItem,
  WhyCRItemText,
  WhyCRListWrapper,
  WhyCR_ContentWrapper
} from './styles'

import { CR_FEATURES_LISTS } from './ui_constants.const'

const WhyCRBlock = () => {
  const { t } = useTranslation();

  return (
    <WhyCRWrapper id="key-benefits">
      <WhyCR_ContentWrapper>
        <BlockTitle>
          {t(`${KEYS.landing}.whyCRBlock.title.prefix`)}
          <RedTitle>
            {t(`${KEYS.landing}.whyCRBlock.title.body`)}
          </RedTitle>
          {t(`${KEYS.landing}.whyCRBlock.title.suffix`)}
        </BlockTitle>
        <WhyCRListWrapper>
          {CR_FEATURES_LISTS.map((list, index) => (
            <WhyCRList key={list}>
              {list.map((el, i) => (
                <WhyCRListItem key={el.text}>
                  <WhyCR_Icon src={el.icon}/>
                  <WhyCRItemText>
                    {t(`${KEYS.landing}.whyCRBlock.reasons.${index}.${i}.text`)}
                  </WhyCRItemText>
                </WhyCRListItem>
              ))}
            </WhyCRList>
          ))}
        </WhyCRListWrapper>
        <Button displayType={'web'} type={ButtonTypes.red}>
          {t(`${KEYS.common}.tryNow`)}
        </Button>
      </WhyCR_ContentWrapper>
      <WhyCRImage/>
      <Button displayType={'mobile'} type={ButtonTypes.red}>
        {t(`${KEYS.common}.tryNow`)}
      </Button>
    </WhyCRWrapper>
  );
}

export default WhyCRBlock;
