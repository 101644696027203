import styled from 'styled-components';
import whiteArrow from 'assets/img/icons/white_arrow.png'
import QRCodeImage from 'assets/img/QR-code.png'
import AppStoreLogo from 'assets/img/AppStoreWhiteLogo.png'
import common_bg from 'assets/img/common_bg.png'
import common_mob_bg from 'assets/img/common_mobile_bg.png'

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #000000 url(${ common_bg }) center repeat;
  background-size: 100% 100%;
	width: 100%;
  padding-top: 0;
  padding-bottom: 24px;
	
  @media (max-width: 1169px) {
		padding-top: 14px;
		padding-bottom: 42px;
    background: #000000 url(${ common_mob_bg }) no-repeat;
    background-size: 100% 100%;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	flex-wrap: wrap;
	max-height: 540px;
	width: 1170px;
	
  @media (max-width: 1169px) {
		align-items: center;
		max-height: fit-content;
	}
`

export const RedLink = styled.a`
	text-decoration: underline;
	color: #EB5560;
`

export const Text = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  color: rgba(255, 255, 255, 0.6);
	max-width: 250px;

  @media (max-width: 1169px) {
    max-width: 288px;
		white-space: break-spaces;
		text-align: center;
  }
`

export const Image = styled.img`
	height: 540px;
	width: 379px;
	order: 1;
	
  @media (max-width: 1169px) {
    order: 2;
		height: 303px;
    width: 214px;
  }
`

export const Video = styled.video`
  width: 359px;
  height: 546px;
  order: 1;
	cursor: pointer;
	background: #000;
  border-radius: 20px;

  @media (max-width: 1169px) {
    order: 2;
    height: 437px;
    width: 288px;
  }
`

export const Title = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0;
  text-align: center;
  color: #fff;
	
	@media (max-width: 1169px) {
		max-width: 90vw;
    font-size: 28px;
    font-weight: 800;
    line-height: 34px;
  }
`

export const QRCode = styled.img.attrs(p => ({ ...p, src: QRCodeImage }))`
	display: block;
	width: 164px;
	height: 167px;
  border-radius: 12px;
	
  @media (max-width: 1169px) {
		display: none;
  }
`

export const Buttons = styled.div`
	display: flex;
	flex-direction: row;
	gap: 30px;

  @media (max-width: 1169px) {
    gap: 20px;
  }
`

export const SubTitle = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0;
  text-align: center;
	color: #fff;
	height: auto;

  @media (max-width: 1169px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
		height: 30px;
	  max-width: 90vw;
  }
`

export const TextBlock = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: flex-start;
  order: 2;
  margin-top: 63px;
  margin-bottom: 12px;
	
  @media (max-width: 1169px) {
    order: 1;
		align-items: center;
    margin-top: 0;  
		margin-bottom: 23px;
  }
`

export const Navigation = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 96px;
  order: 3;
	margin-right: 170px;
	margin-top: 0;
	
  @media (max-width: 1169px) {
    gap: 18px;
    align-items: center;
  	margin-right: 0;
    margin-top: 23px;
  }
`

export const NextButton = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
  position: relative;
  width: 170px;
  height: 50px;
  background: #EB5560;
  border-radius: 6px;
  padding: 0 2px 0 0;
  box-sizing: border-box;
	cursor: pointer;

  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0;
  text-align: center;
  color: #fff;
	
  @media (max-width: 1169px) {
    width: 134px;
    height: 44px;
    text-align: left;
    padding: 0 42px 0 0;
	}
`

export const PrevButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 170px;
  height: 50px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
	padding: 0 0 0 15px;
  box-sizing: border-box;
  cursor: pointer;
	
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0;
  text-align: center;
  color: #fff;
	

  @media (max-width: 1169px) {
    width: 134px;
    height: 44px;
    padding: 0 0 0 42px;
		text-align: right;
  }
`

export const StoreButton = styled.img.attrs(p => ({ ...p, src: AppStoreLogo }))`
	width: 125px;
	height: 41px;
	cursor: pointer;

  @media (max-width: 1169px) {
    width: 136px;
    height: 45px;
  }
`

export const StoreNavigation = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 15px;

  @media (max-width: 1169px) {
    gap: 12px;
    align-items: center;
  }
`

export const StoreNavigationWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 30px;
	
  @media (max-width: 1169px) {
    flex-direction: column;
  }
`

export const WhiteArrowIcon = styled.img.attrs(p => ({ ...p, src: whiteArrow}))`
	width: 26px;
	height: 16px;
	position: absolute;
	
	${({ rotated }) => rotated ? `
		transform: rotate(180deg);
		left: 12px;
		` : `
		right: 12px;
		
		`};
`