import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from 'swiper/react';
import {EffectFade, Navigation, Autoplay } from 'swiper'

import { RedTitle, BlockTitle } from "components";
import { KEYS} from "constants";

import {
  Quotes,
  Rating,
  StarIcon,
  ReviewText,
  ReviewItem,
  NextReason,
  ReviewsList,
  StarsWrapper,
  ReviewerName,
  ReviewsCount,
  HalfStarIcon,
  GeneralRating,
  WebNavWrapper,
  ReviewersIcon,
  PreviousReason,
  ReviewsWrapper,
  MobileNavWrapper,
  GeneralReviewInfo,
  MobileReviewsList
} from './styles'

import { REVIEWS } from "./ui_constants.const";

const ReviewsBlock = () => {
  const { t } = useTranslation();

  return (
    <ReviewsWrapper id="reviews">
      <BlockTitle>
        {t(`${KEYS.landing}.reviewsBlock.title.prefix`)}
        <RedTitle>
          {t(`${KEYS.landing}.reviewsBlock.title.body`)}
        </RedTitle>
        {t(`${KEYS.landing}.reviewsBlock.title.suffix`)}
      </BlockTitle>
      <ReviewsList>
        <Swiper
          slidesPerView={2}
          spaceBetween={16}
          effect="creative"
          loop={true}
          modules={[EffectFade, Navigation, Autoplay]}
          initialSlide={0}
          navigation={{
            nextEl: '.next_web_slide',
            prevEl: '.prev_web_slide'
          }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
        >
          <SwiperSlide>
            <GeneralReviewInfo>
              <GeneralRating>
                {t(`${KEYS.landing}.reviewsBlock.generalRating`)}
              </GeneralRating>
              <StarsWrapper>
                <StarIcon/>
                <StarIcon/>
                <StarIcon/>
                <StarIcon/>
                <HalfStarIcon/>
              </StarsWrapper>
              <ReviewsCount>
                {t(`${KEYS.landing}.reviewsBlock.over30_1`)}
                <ReviewersIcon/>
                {t(`${KEYS.landing}.reviewsBlock.over30_2`)}
              </ReviewsCount>
            </GeneralReviewInfo>
          </SwiperSlide>
          {
            REVIEWS.map((review, index) => (
              <SwiperSlide key={index}>
                <ReviewItem>
                  <Quotes/>
                  <Rating>
                    <StarIcon isReview/>
                    {review.startsCount}
                  </Rating>
                  <ReviewText>
                    {t(`${KEYS.landing}.reviewsBlock.reviews.${index}.text`)}
                  </ReviewText>
                  <ReviewerName>
                    {t(`${KEYS.landing}.reviewsBlock.reviews.${index}.name`)}
                  </ReviewerName>
                </ReviewItem>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </ReviewsList>
      <WebNavWrapper>
        <div className={'prev_web_slide'}>
          <PreviousReason />
        </div>
        <div className={'next_web_slide'}>
          <NextReason />
        </div>
      </WebNavWrapper>
      <MobileReviewsList>
        <Swiper
          slidesPerView={1}
          spaceBetween={15}
          effect="creative"
          loop={true}
          modules={[EffectFade, Navigation, Autoplay]}
          initialSlide={0}
          navigation={{
            nextEl: '.next_review',
            prevEl: '.prev_review'
          }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
        >
          <SwiperSlide>
            <GeneralReviewInfo>
              <GeneralRating>
                {t(`${KEYS.landing}.reviewsBlock.generalRating`)}
              </GeneralRating>
              <StarsWrapper>
                <StarIcon/>
                <StarIcon/>
                <StarIcon/>
                <StarIcon/>
                <HalfStarIcon/>
              </StarsWrapper>
              <ReviewsCount>
                {t(`${KEYS.landing}.reviewsBlock.over30_1`)}
                <ReviewersIcon/>
                {t(`${KEYS.landing}.reviewsBlock.over30_2`)}
              </ReviewsCount>
            </GeneralReviewInfo>
          </SwiperSlide>
          {
            REVIEWS.map((review, index) => (
              <SwiperSlide key={index}>
                <ReviewItem>
                  <Quotes/>
                  <Rating>
                    <StarIcon isReview/>
                    {review.startsCount}
                  </Rating>
                  <ReviewText>
                    {t(`${KEYS.landing}.reviewsBlock.reviews.${index}.text`)}
                  </ReviewText>
                  <ReviewerName>
                    {t(`${KEYS.landing}.reviewsBlock.reviews.${index}.name`)}
                  </ReviewerName>
                </ReviewItem>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </MobileReviewsList>
      <MobileNavWrapper>
        <div className={'prev_review'}>
          <PreviousReason/>
        </div>
        <div className={'next_review'}>
          <NextReason/>
        </div>
      </MobileNavWrapper>

    </ReviewsWrapper>
  );
}

export default ReviewsBlock;
