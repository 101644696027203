export const STEPS = [{
	id: 1,
	linkTo: ''
},{
	id: 2,
	linkTo: '/choose-plan'
}]

export const PLAN_OPTIONS = [{
	id: 0,
	fsId: 'reccall-1-month',
	currentPrice: 19.99,
	originPrice: 29.99,
	monthly: true
},{
	id: 1,
	fsId: 'reccall-1-month-1d-trial',
	currentPrice: 0.99,
	originPrice: null,
	monthly: false,
	mostPopular: true
},{
	id: 2,
	fsId: 'reccall-12-months',
	currentPrice: 8.3,
	originPrice: 12.5,
	monthly: true
}]