import {useState} from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Stepper,
  Checkbox,
  RedTitle,
  BlockTitle,
  ButtonTypes,
} from "components";
import { KEYS} from "constants";
import { STEPS, PHONES_LIST, buttonSettings } from "./phonesList.const";

import {
  Icon,
  PhonesList,
  PageContent,
  PhonesWrapper,
  PhonesListItem,
} from "./styles";

const ChoosePhone = () => {
  const { t } = useTranslation();
  const [selectedPhoneId, setSelectedPhoneId] = useState([]);

  const stepsList = STEPS.map((s, i) => ({ ...s, text: t(`${KEYS.choosePhone}.steps.${i}.text`) }))

  const handlePhoneSelect = ( e, id ) => {
    e.stopPropagation();
    e.preventDefault();

    if(selectedPhoneId.includes(id)) {
      setSelectedPhoneId(state => state.filter(el => el !== id))
    } else {
      setSelectedPhoneId(state => ([...state, id]))
    }
  }

  return (
    <PhonesWrapper>
      <BlockTitle style={{ textAlign: 'center'}}>
        {t(`${KEYS.choosePhone}.title.prefix`)}
        <RedTitle>
          {t(`${KEYS.choosePhone}.title.body`)}
        </RedTitle>
        {t(`${KEYS.choosePhone}.title.suffix`)}
      </BlockTitle>

      <PageContent>
        <Stepper steps={stepsList} activeStepId={STEPS[0].id}/>

          <PhonesList>
            {PHONES_LIST.map((phone, i) => (
              <PhonesListItem key={phone.id} onClick={(e) => handlePhoneSelect(e, phone.id)}>
                <Checkbox
                  id={phone.id}
                  name={t(`${KEYS.choosePhone}.phones.${i}.text`)}
                  label={t(`${KEYS.choosePhone}.phones.${i}.text`)}
                  checked={selectedPhoneId.includes(phone.id)}
                  value={selectedPhoneId.includes(phone.id)}
                  onClick={(e) => handlePhoneSelect(e,phone.id)}
                />
                <Icon src={phone.icon}/>
              </PhonesListItem>
            ))}
          </PhonesList>

        <Button type={ButtonTypes.red} style_={buttonSettings} to={'/choose-plan'}>
          {t(`${KEYS.common}.confirm`)}
        </Button>

      </PageContent>
    </PhonesWrapper>
  );
}

export default ChoosePhone;