import Lottie from 'react-lottie';
import { useTranslation } from "react-i18next";

import {
  Button,
  RedTitle,
  BlockTitle,
  ButtonTypes,
} from "components";
import { KEYS} from "constants";
import animation from "assets/sound_wave_animation.json";

import {
  BlockSubtitle,
  BestCRAppImage,
  AnimationWrapper,
  BestCRAppWrapper,
  BestCRAppContent,
  AnimatedBlockWrapper,
} from './styles'
import Highlighter from "react-highlight-words";


const BestAppBlock = () => {
  const { t } = useTranslation();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    renderer: 'svg'
  }
  return (
    <BestCRAppWrapper>
        <BestCRAppContent>
          <BlockTitle larger style={{ maxWidth: '500px' }}>
            {t(`${KEYS.landing}.bestAppBlock.title.prefix`)}
            <RedTitle larger>
              {t(`${KEYS.landing}.bestAppBlock.title.body`)}<br/>
            </RedTitle>
            {t(`${KEYS.landing}.bestAppBlock.title.suffix`)}
          </BlockTitle>
          <BlockSubtitle>
            <Highlighter
              searchWords={[
                t(`${KEYS.landing}.bestAppBlock.subTitle.highlightedWord1`),
                t(`${KEYS.landing}.bestAppBlock.subTitle.highlightedWord2`),
                t(`${KEYS.landing}.bestAppBlock.subTitle.highlightedWord3`)
               ]}
              autoEscape={true}
              textToHighlight={t(`${KEYS.landing}.bestAppBlock.subTitle.text`)}
            />
          </BlockSubtitle>
          <Button displayType={'web'} type={ButtonTypes.red}>
            {t(`${KEYS.common}.tryNow`)}
          </Button>
        </BestCRAppContent>
        <AnimatedBlockWrapper>
          <BestCRAppImage/>
          <AnimationWrapper>
            <Lottie
              style={{ width: '100%' }}
              options={defaultOptions}
              loop={true}
              autoplay={true}
            />
          </AnimationWrapper>
        </AnimatedBlockWrapper>
        <Button displayType={'mobile'} type={ButtonTypes.red}>
          {t(`${KEYS.common}.tryNow`)}
        </Button>
    </BestCRAppWrapper>
  );
}

export default BestAppBlock;
