import { PageWrapper, SeparatorLine } from 'components'
import BestAppBlock from "./BestAppBlock";
import WhyYoullLoveIt from "./WhyYoullLoveIt";
import GetStarted from "./GetStarted";
import EasyToRecord from "./EasyToRecord";
import BestChoiceBlock from "./BestChoice";
import WhyCR from "./WhyCR";
import Reviews from "./Reviews";

const Landing = () => {
  return (
    <PageWrapper>
      <BestAppBlock/>
      <WhyYoullLoveIt/>
      <GetStarted/>
      <EasyToRecord/>
      <BestChoiceBlock/>
      <WhyCR/>
      <Reviews/>
      <SeparatorLine/>
    </PageWrapper>
  );
}

export default Landing;
