import {Link} from "react-router-dom";
import styled from 'styled-components';
import footer_image from 'assets/img/img_footer.png'

import {LINKS_TYPES} from "constants";
import appLogo from "assets/img/logo.png";

const LINKS = {
  [LINKS_TYPES.navTitle]: `
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
    font-weight: 600;
  `,
  [LINKS_TYPES.anchor_]: `
    color: rgba(255, 255, 255, 0.6);
    font-size: 16px;
    font-weight: 400;
        
    @media (max-width: 1169px) {
      font-size: 13px;
    }
  `,
  [LINKS_TYPES.underlined]: `
    color: rgba(255, 255, 255, 0.6);
    font-size: 16px;
    font-weight: 400;
    text-decoration underline !important;
        
    @media (max-width: 1169px) {
      font-size: 13px;
    }
  `,
}

export const FooterBase = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  height: auto;
  background: #000;
  padding-top: 55px;
  
  @media (max-width: 1169px) {
    padding: 24px 0;
  }
`

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1169px;
`

export const FooterContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  
  @media (max-width: 1169px) {
    gap: 16px;
    max-width: 288px;
  }
`

export const FooterLinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 132px;
  
  @media (max-width: 1169px) {
    flex-direction: column;
    gap: 16px;
  }
`

export const DisclaimerWrapper = styled.div` 
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 75px;
  margin-bottom: 10px;
`

export const DisclaimerTitle = styled.div`
  font-family: SF Pro, sans-serif;
  font-size: 14px;
  font-weight: 590;
  line-height: 21px;
  letter-spacing: 0.33764711022377014px;
  text-align: left;
  color: rgba(255, 255, 255, 0.6);
`

export const DisclaimerText = styled.span`
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: rgba(255, 255, 255, 0.25);
`

export const FooterLinksList = styled.div` 
  display: flex;
  flex-direction: column;
  gap: 13px;
  width: 170px;
  
  @media (max-width: 1169px) {
    width: 288px;
    gap: 12px;
  }
`

export const FooterLink = styled(Link)`
  font-family: Poppins, sans-serif;
  line-height: 150%;
  letter-spacing: 0;
  text-align: left;
  text-decoration: none;
  
  ${({ type }) => LINKS[type]}
`

export const FooterImage = styled.img.attrs(props => ({
  src: footer_image,
}))`
  height: 388px;
  display: block;
  margin-top: -55px;
  
  @media (max-width: 1169px) {
    display: none;  
  }
`

export const AppLogo = styled.img.attrs(props => ({
  src: appLogo,
}))`
  width: 35px;
  height: 35px;
`

export const ForEveryone = styled.div` 
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 120px;
  
  @media (max-width: 1169px) {
    display: none;
  }
`

export const Text = styled.span`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: rgba(255, 255, 255, 0.6);
`

export const FooterNavLink = styled.a`
  font-family: Poppins, sans-serif;
  line-height: 150%;
  letter-spacing: 0;
  text-align: left;
  text-decoration: none;

  ${({ type }) => LINKS[type]}
`
