import styled from 'styled-components';
import pageBackground from "assets/img/common_bg.png";
import Secure_web from "assets/img/Secure_web.png";
import Secure_mobile from "assets/img/Secure_mobile.png";


export const ChoosePlanWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 62px;
  background: url(${ pageBackground });
  background-size: 100% 100%;
  width: 100%;
`

export const SafetyIconsWeb = styled.img.attrs(p => ({ src: Secure_web }))`
	display: block;
	width: 169px;
	height: fit-content;
  margin-right: 29px;
	
	@media (max-width: 1169px) {
		display: none;
	}
`

export const Hint = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 0;
  margin-bottom: 10px;
  
  @media (max-width: 1169px) {
    margin-top: 12px;
    line-height: 18px;
    letter-spacing: -0.01em;
  }
`

export const SafetyIconsMobile = styled.img.attrs(p => ({ src: Secure_mobile }))`
	display: none;
	
  @media (max-width: 1169px) {
    display: block;
		width: 320px;
		height: fit-content;
  }
`

export const RadioButtonLabel = styled.label`
  position: absolute;
  cursor: pointer;
  left: 4px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.35);

  @media (max-width: 1169px) {
    width: 14px;
    height: 14px;
  }
`;

export const PlanItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 503px;
  height: 77px;
  padding: 0 14px;
  color: #fff;
  cursor: pointer;
  
  background: ${({ isSelected }) => isSelected ? 'rgba(255, 255, 255, 0.2)' : 'rgba(255, 255, 255, 0.05)'};

  @media (max-width: 1169px) {
    width: 288px;
    height: 61px;
  }
`

export const RadioButton = styled.input`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px 0 0;

  @media (max-width: 1169px) {
    width: 18px;
    height: 18px;
  }
  
  &:hover ~ ${RadioButtonLabel} {
    background: transparent;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 10px;
      height: 10px;

      @media (max-width: 1169px) {
        width: 7.5px;
        height: 7.5px;
        margin: 3.5px 0 0 3.25px;
      }
      background: #eeeeee;
      margin: 5px 0 0 5px;
    }
  }
  ${(props) => props.checked &&
    `&:checked + ${RadioButtonLabel} {
      background: #fff;
      &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        
        @media (max-width: 1169px) {
          width: 7.5px;
          height: 7.5px;
          margin: 3.5px 0 0 3.25px;
        }
        
        box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.1);
        background: linear-gradient(180deg, #FF8278 0%, #FD5548 100%);
        margin: 5px 0 0 5px;
      }
    }
  `}
`;

export const PlansWrapper = styled.div`
	display: flex;
	flex-direction: row;
  align-items: center;
  margin-left: -240px;

  @media (max-width: 1169px) {
    margin: 0;
    
  }
`

export const PlansList = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  
  @media(max-width: 1169px) {
    border-radius: 0;
  }
`

export const PlanItemTitle = styled.div`

  font-family: Poppins, sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  text-align: left;
  font-weight: 700;
  
  ${({ isActive }) => isActive ? `
    font-weight: 700;
    line-height: 26px;
  ` : `
    font-weight: 400;
    line-height: 24px;
  `};
`

export const PlanItemPrice = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  
  @media (max-width: 1169px) {
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    text-align: right;
    justify-content: flex-end;
  }
`

export const PlanItemPriceSub = styled.div`
  font-family: Poppins, sans-serif;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: rgba(255, 255, 255, 0.8);


  ${({ isCrossed }) => isCrossed ? `
    font-size: 14px;
    line-height: 21px;
  ` : `
    font-size: 13px;
    line-height: 20px;
  `}
`

export const MostPopularBlock = styled.div`
  width: 46px;
  height: 77px;
  background: linear-gradient(180deg, #FF8278 0%, #FD5548 100%);
  border-radius: 10px 0 0 10px;
  
  display: flex;
  flex-direction: column;
  justify-content: center;

  font-family: Poppins, sans-serif;
  font-size: 8px;
  font-weight: 700;
  line-height: 11px;
  letter-spacing: 1px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;

  @media (max-width: 1169px) {
    display: none;
  }
`

export const PlanItemPricing = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 425px;
`

export const RadioButtonWrapper = styled.div`
  display: flex;
  width: 28px;
  height: 28px;
  position: relative;
  margin-right: 27px;
`

export const PlanPriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  flex-wrap: nowrap;
  width: 100px;

  @media(max-width: 1169px) {
    flex-direction: column;
    justify-content: center;
    text-align: right;
  }
`

export const OriginPrice = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  width: 100px;
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.6);

  @media(max-width: 1169px) {
    display: none; 
  }
`

export const MobilePrice = styled.div`
  display: none;
  
  @media(max-width: 1169px) {
    flex-direction: row;
    display: flex;
    justify-content: flex-end;
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: -0.01em;
    text-align: right;
    color: rgba(255, 255, 255, 0.6);

  }
  
`

export const PlanItemTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  width: 100px;
  justify-content: center;
`


export const MostPopularMobileBlock = styled.div`
  display: none;

  @media(max-width: 1169px) {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    background: linear-gradient(180deg, #FF8278 0%, #FD5548 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
`

export const CrossedText = styled.div`
  text-decoration: line-through;
`