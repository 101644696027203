import styled from 'styled-components';

import sound_wave_bg from "assets/img/sound_wave_bg.png";
import best_app_bg_img from "assets/img/best_app_bg.png";
import best_app_bg_img_mob from "assets/img/best_app_bg_img_mob.png";
import whyLove_bg from "assets/img/whyLove_bg.webp";
import whyLove_bg_mob from "assets/img/whyLove_bg_mob.png";
import get_started_bg from "assets/img/get_started_bg.png";
import btnNext from "assets/img/btn_next.png";
import video_cover from "assets/img/video_cover.png";
import gray_arrow_right from "assets/img/gray_arrow_right.png";
import HowToBg from "assets/img/How_To_bg.png";
import HowToBgMobile from "assets/img/How_To_bg_mobile.png";
import Why_CR from "assets/img/img_Why_CR.png";
import Reviewers from "assets/img/persons_group_icon.png";
import QuotesIcon from "assets/img/quotes.png";
import Review_BG from "assets/img/review_bg.png";
import Reviews_BG from "assets/img/reviews_bg.png";
import RedStarIcon from "assets/img/red_star.png";
import HalfRedStarIcon from "assets/img/red_half_star.png";

import video from "assets/img/landing_video_570x470.mp4";



export const BestCRAppWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100vw;
  padding: 31px 0 21px;
  background: #181818 url(${ best_app_bg_img });
  background-size: 100% 100%;
  justify-content: center;
  gap: 50px;
  align-items: unset;
  
  @media (max-width: 1169px) {
    flex-direction: column;
    align-items: center;
    justify-content: unset;
    padding: 22px 0 13px;
    gap: 10px;
  }
`

export const BestCRAppContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  padding: 160px 60px 210px 0;
  
  @media (max-width: 1169px){
    padding: 0;
    width: 302px;
  }
`

export const BestCRAppImage = styled.img.attrs(props => ({
  src: sound_wave_bg,
}))`
  width: auto;
  height: 609px;
  
  position: absolute;
  
  @media (max-width: 1169px) {
    left: -20px;
    height: 326px;  
  }
`

export const AnimatedBlockWrapper = styled.div`
  display: block;
  width: 690px;
  height: 650px;
  margin-right: -220px;
  position: relative;
  
  @media (max-width: 1169px) {
    width: 288px;
    height: 330px;
    margin-right: 0;
    margin-bottom: 10px;
  }
`

export const AnimationWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 40%;
  width: 100%;
  margin-left: -32px;

  @media (max-width: 1169px) {
    bottom: 35%;
    margin-left: 0;
  }
`



export const BlockSubtitle = styled.span`
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  color: #F3F3F3;
  margin-bottom: 34px;
  white-space: nowrap;
  
  @media (max-width: 1169px) {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    margin-bottom: 20px;
    white-space: break-spaces;
  }
`

export const WhyLoveItWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  padding: 20px 0 95px;
  gap: 60px;
  background-size: 1440px auto !important;
  background-position-x: center !important;
  background: rgb(4, 4, 4) url(${whyLove_bg}) no-repeat;

  @media (max-width: 1169px) {
    padding: 20px 0 30px;
    gap: 0;
    width: 100vw;
    background-size: 320px auto !important;
    background: rgb(4, 4, 4) url(${whyLove_bg_mob}) no-repeat;
    background-position-x: right !important;
  }
`

export const ReasonsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 1170px;
  align-items: center;
  
  @media (max-width: 1169px){
    display: none;
  }
`

export const MobileReasonsListWrapper = styled.div`
  display: none;

  @media (max-width: 1169px){
    margin-top: 17px;
    width: 896px;
    display: flex;
    flex-direction: row;
  }
`

export const ReasonsList = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1170px;
  flex-wrap: wrap;
  gap: 30px;
  
  @media (max-width: 1169px){
    width: fit-content;
    flex-wrap: nowrap;
  }
`

export const ReasonItem = styled.div`
  border-top: rgb(68, 62, 63) 2px solid;
  display: flex;
  flex-direction: column;
  width: 370px;
  min-width: 288px;
  margin-bottom: 30px;
  
  @media (max-width: 1169px){
    width: 288px;
    margin-bottom: 0;
  }
`

export const ReasonItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 76px;
  padding-top: 10px;

  @media (max-width: 1169px){
    min-height: 46px;
  }
`

export const ReasonItemTitle = styled.span`
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0;
  text-align: left;
  color: rgba(255, 255, 255, 0.8);
  padding-right: 35px;
  
  @media (max-width: 1169px){
    font-size: 18px;
    line-height: 27px;
  }
`

export const DecoratedReasonItemTitle = styled.span`
  ${ ReasonItemTitle };

  font-style: italic;
  color: #EB5560;
`

export const ReasonItemText = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  letter-spacing: 0.6px;
  text-align: left;
  color: rgba(255, 255, 255, 0.6);
  
  @media (max-width: 1169px){
    letter-spacing: 0;
    font-size: 14px;
    line-height: 21px;
  }
`

export const ReasonItemIcon = styled.img`
  width: 44px;
  height: 44px;
  
  @media (max-width: 1169px) {
    width: 36px;
    height: 36px;
  }
`

export const NavWrapper = styled.div`
  display: none;
  
  @media (max-width: 1169px) {
    display: flex;
    flex-direction: row;
    width: 320px;
    justify-content: flex-end;
    gap: 21px;
    align-self: center;  
    margin-top: 12px;
    margin-right: 16px;
  }
`

export const NextReason = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 50%;
  background: url(${ btnNext });
  cursor: pointer;
  height: 69px;
  width: 69px;
  background-size: 69px 69px;
  
  @media(max-width: 1169px) {
    width: 48px;
    height: 48px;
    background-size: 48px 48px;
  }
`

export const PreviousReason = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 50%;
  background: url(${ btnNext });
  transform: rotate(180deg);
  cursor: pointer;
  height: 69px;
  width: 69px;
  background-size: 69px 69px;

  @media(max-width: 1169px) {
    width: 48px;
    height: 48px;
    background-size: 48px 48px;
  }
`

export const GetStartedWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 250px;
  width: 100vw;
  min-height: 500px;
  background-size: auto 90%  !important;
  background-position-x: center !important;
  background: rgb(4, 4, 4) url(${get_started_bg});
  padding: 20px 0 21px;

  @media (max-width: 1169px) {
    padding: 30px 0 50px;
    flex-direction: column;
    align-items: center;
    gap: 0;
    max-width: 100vw;
  }
`

export const GetStartedTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 232px 0;
  
  @media (max-width: 1169px){
    padding: 0;
  }
`

export const GetStartedText = styled.span`
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  color: rgba(255, 255, 255, 0.6);
  display: block;

  @media (max-width: 1169px){
    display: none;
  }
`

export const GetStartedVideo = styled.video.attrs(props => ({
  src: video,
  poster: video_cover
}))`
  width: 370px;
  cursor: pointer;
  height: 562px;
  background: #000;
  border-radius: 25px;
  
  @media (max-width: 1169px) {
    width: 288px;
    height: 438px;
    border-radius: 20px;
  }
`

export const HowToWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 80px 0 88px;
  background: #000 url(${ HowToBg }) no-repeat center;
  background-size: 100% 100%;

  @media (max-width: 1169px) {
    max-width: 100vw;
    padding: 30px 0 35px;
    background: #000 url(${ HowToBgMobile }) no-repeat center;
    background-size: 100% 100%;
  }
`

export const WebStepsLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 220px;

  @media (max-width: 1169px) {
    display: none;
  }
`

export const MobileLayout = styled.div`
  display: none;
  
  @media (max-width: 1169px) {
    display: flex;
    flex-direction: row;
    padding-left: 16px;
    box-sizing: border-box;
    width: 320px;
  }
`

export const BCMobileLayout = styled.div`
  display: none;
  
  @media (max-width: 1169px) {
    display: flex;
    flex-direction: row;
    padding-left: 16px;
    box-sizing: border-box;
    width: 100vw;
  }
`

export const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 137px 0 80px;
  
  @media (max-width: 1169px) {
    flex-direction: row;
    margin: 16px 0 35px;
  }
`

export const Step = styled.div`
  display: flex;
  flex-direction: row;
  gap: 29px;
  height: 89px;
  width: 468px;
  flex-wrap: nowrap;
  line-height: 150%;
  align-items: center;
  
  @media (max-width: 1169px) {
    flex-direction: column;
    width: 288px;
    height: fit-content;
  }
`

export const StepIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border-radius: 50%;
  width: 69px;
  height: 69px;
  box-sizing: border-box;
  
  ${({ isActive }) => isActive ? `
    padding: 0;
    border: rgb(68, 62, 63) 1px solid;
  ` : `
    border: rgb(68, 62, 63) 1px transparent;
    padding: 0 15.59px;
  `};

  @media (max-width: 1169px){
    width: 44px;
    height: 44px;
    padding: 0;
  }
`

export const StepTextWrapper = styled.span`
  font-family: Poppins, sans-serif;
  letter-spacing: 0;
  text-align: left;
  max-width: 360px;
  white-space: break-spaces;
  
  ${({ isStepActive }) => isStepActive ? `
    font-size: 20px;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.8);
  ` : `
    font-size: 16px;
    font-weight: 400;  
    color: rgba(255, 255, 255, 0.6);
  `};

  @media (max-width: 1169px){
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    white-space: normal;
  }
`

export const MobileStepFooter = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  min-width: 288px;
  padding: 16px 10px;
  box-sizing: border-box;
  
`

export const ActiveStepArrow = styled.img.attrs(props => ({
  src: btnNext
}))`
  height: 69px;
  width: 69px;
  
  @media(max-width: 1169px){
    height: 44px;
    width: 44px;
  }
`

export const PassiveStepArrow = styled.img.attrs(props => ({
  src: gray_arrow_right
}))`
  width: 24px;
  height: 14px;
`

export const StepImage = styled.img`
  width: 472px;
  height: auto;
  margin: 70px 0 8px;

  @media(max-width: 1169px){
    height: 283px;
    width: auto;
    margin: 0 0 8px;
  }
`

export const BestChoiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 1170px;
  align-items: center;

  @media (max-width: 1169px) {
    width: 100vw;
  }
`

export const BestChoiceList = styled.div`
  display: flex;
  flex-direction: row;
  width: 1170px;
  justify-content: center;
  gap: 29px;
  padding: 51px 0;
  margin-top: 20px;
  
  @media (max-width: 1169px) {
    gap: 0;
    width: 100vw;
    padding: 0;
    margin-top: 6px;
    margin-bottom: 44px;
  }
`

export const BCWebLayout = styled.div`
  display: flex;
  flex-direction: row;
  
  @media (max-width: 1169px) {
    display: none;
  }
`

export const BestChoiceItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 370px;
  min-width: 224px;
  
  @media (max-width: 1169px) {
    width: 224px;
  }
`

export const BestChoiceItemHeader = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  height: 58px;
  margin-bottom: 14px;
`

export const BestChoiceIcon = styled.img`
  width: 44px;
  height: 44px;

  @media (max-width: 1169px) {
    width: 38px;
    height: 38px;
  }
`

export const BestChoiceTitle = styled.span`
  font-family: Poppins, sans-serif;
  font-size: 40px;
  font-weight: 800;
  line-height: 48px;
  letter-spacing: 0;
  text-align: left;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 4px;
  white-space: break-spaces;

  @media (max-width: 1169px) {
    font-size: 30px;
    line-height: 36px;
  }
`

export const BestChoiceRedSubstring = styled.span`
  color: #EB5560;
`

export const BestChoiceSubTitle = styled.span`
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0;
  text-align: left;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);

  @media (max-width: 1169px) {
    font-size: 15px;
    line-height: 23px;
  }
`

export const WhyCRWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #181818 url(${best_app_bg_img});
  background-size: 100% 100%;
  width: 100%;
  gap: 102px;

  @media (max-width: 1169px) {
    flex-direction: column;
    align-items: center;
    gap: 0;
    background: #020202 url(${best_app_bg_img_mob}) center;
    background-size: 100% 100%;
  }
`

export const WhyCR_ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 145px 0;
  
  align-items: flex-start;

  @media (max-width: 1169px) {
    align-items: center;
    padding: 30px 0 0;
  }
`

export const WhyCRImage = styled.img.attrs(props => ({
  src: Why_CR
}))`
  width: auto;
  height: 655px;
  margin-top: 0;
  margin-left: 0;
  
  @media (max-width: 1169px) {
    margin-left: 40px;
    margin-top: -35px;
    height: 516px;
  }
`

export const WhyCRList = styled.div`
  display: flex;
  flex-direction: column;
  width: 242px;
  gap: 12px;

  @media (max-width: 1169px) {
    gap: 0;
  }
`

export const WhyCRListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin: 36px 0 30px;
  gap: 58px;
  
  @media (max-width: 1169px) {
    width: 288px;
    flex-direction: column;
    margin: -5px 0 0;
    gap: 0;
  }
`

export const WhyCRListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 18px;
  height: 48px;

  @media (max-width: 1169px) {
    height: 58px;
    gap: 12px;
  }
`

export const WhyCR_Icon = styled.img`
  width: 48px;
  height: 48px;
  
  @media (max-width: 1169px) {
    width: 42px;
    height: 42px;
  }
`

export const WhyCRItemText = styled.div`
  width: 188px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0;
  text-align: left;
  color: #fff;
  
  @media (max-width: 1169px) {
    font-size: 15px;
    line-height: 23px;
  }
`

export const ReviewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1440px;
  background: url(${ Reviews_BG }) no-repeat;
  background-size: auto 100% !important;
  
  @media (max-width: 1169px) {
    width: 100%;
    padding-top: 20px;
  }
`

export const ReviewsList = styled.div`
  display: flex;
  flex-direction: row;
  width: 1170px;
  margin-top: 50px;
  
  @media (max-width: 1169px) {
    display: none;
  }
`

export const MobileReviewsList = styled.div`
  display: none;
  
  @media (max-width: 1169px) {
    display: flex;
    width: 288px;
  }
`

export const GeneralReviewInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 570px;
  align-items: center;
  padding: 108px 0;
  
  @media (max-width: 1169px) {
    width: 288px;
  }
`

export const GeneralRating = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 100px;
  font-weight: 800;
  line-height: 120px;
  letter-spacing: 0;
  text-align: left;
  color: #fff;

  @media (max-width: 1169px) {
    font-size: 60px;
    line-height: 72px;
  }
`

export const StarsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;

  @media (max-width: 1169px) {

  }
`

export const ReviewsCount = styled.span`
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 31px;

  @media (max-width: 1169px) {

  }
`

export const ReviewersIcon = styled.img.attrs(props => ({
  src: Reviewers
}))`
  width: 35px;
  margin: 0 13px;

  @media (max-width: 1169px) {

  }
`

export const ReviewItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 570px;
  max-width: 570px;
  min-width: 570px;
  background-size: 45%;
  background: url(${Review_BG}) right top no-repeat;
  
  @media (max-width: 1169px) {
    width: 288px;
    max-width: 288px;
    min-width: 288px;
    background-size: 50% !important;
  }
`

export const Quotes = styled.img.attrs(p => ({
  src: QuotesIcon
}))`
  width: 97px;

  @media (max-width: 1169px) {
    width: 56px;
  }
`

export const Rating = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  font-family: Helvetica, sans-serif;
  font-size: 26px;
  font-weight: 300;
  line-height: 39px;
  letter-spacing: 0;
  text-align: left;
  color: #fff;
  margin: 27px 0 24px;
  
  @media (max-width: 1169px) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const ReviewText = styled.span`
  font-family: Poppins, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0;
  text-align: left;
  width: 570px;
  color: #fff;

  @media (max-width: 1169px) {
    width: 288px;
    font-size: 14px;
    line-height: 21px;  
  }
`

export const ReviewerName = styled.span`
  ${ ReviewText };
  margin-top: 24px;

  @media (max-width: 1169px) {
    margin-top: 16px;
  }
  color: rgba(255, 255, 255, 0.6);
`

export const StarIcon = styled.img.attrs(p => ({
  src: RedStarIcon
}))`
  width: ${({ isReview }) => isReview ? '39px' : '29px'};
  height: ${({ isReview }) => isReview ? '39px' : '29px'};

  @media (max-width: 1169px) {
    width: ${({ isReview }) => isReview ? '24px' : '25px'};
    height: ${({ isReview }) => isReview ? '24px' : '25px'};
  }
`

export const HalfStarIcon = styled.img.attrs(p => ({
  src: HalfRedStarIcon
}))`
  width: 29px;
  height: 29px;

  @media (max-width: 1169px) {
    width: 25px;
    height: 25px;
  }
`

export const MobileNavWrapper = styled.div`
  display: none;
  
  @media (max-width: 1169px) {
    display: flex;
    flex-direction: row;  
    gap: 21px;
  }
`

export const WebNavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  gap: 51px;
  margin-right: 135px;
  
  @media (max-width: 1169px) {
    display: none;
    gap: 21px;
  }
`