import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ONELINK, KEYS } from "constants";

import { GUIDE_STEPS, contentTypes } from "./onboardingSteps.const";

import {
  Text,
  Image,
  Title,
  Video,
  QRCode,
  Buttons,
  RedLink,
  Wrapper,
  SubTitle,
  TextBlock,
  Navigation,
  NextButton,
  PrevButton,
  PageWrapper,
  StoreButton,
  WhiteArrowIcon,
  StoreNavigation,
  StoreNavigationWrapper
} from "./styles";


const Onboarding = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [activeStep, setActiveStep] = useState(GUIDE_STEPS[0]);

  const handleVideo = () => {
    setIsPlaying(!isPlaying);
    if (isPlaying === true) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
      if( window.innerWidth < 1170 ) {
        videoRef.current.requestFullscreen();
      }
    }
  };
  const handleStepChange = (number) => {
    if(activeStep.id + 1 === GUIDE_STEPS.length && number > 0){
      navigate('/dashboard')
    } else {
      setActiveStep(GUIDE_STEPS.find(el => el.id === activeStep.id + number))
    }
  }

  const handleVideoEnd = () => {
    setIsPlaying(false)
  }

  const webId = localStorage.getItem('webIdentifier')
  const oneLink = webId ? `${ONELINK}?source=web&identifier=${webId}` : ONELINK

  return (
    <PageWrapper>
      <Wrapper>

      <TextBlock>
        <Title>
          {t(`${KEYS.onboarding}.steps.${activeStep.id}.title`)}
        </Title>
        <SubTitle>
          {t(`${KEYS.onboarding}.steps.${activeStep.id}.subTitle`)}
        </SubTitle>
      </TextBlock>

        {activeStep.contentType === contentTypes.image ? (
          <Image src={activeStep.contentSource} />
        ) : (
          <Video poster={activeStep.cover} src={activeStep.contentSource} onClick={handleVideo} ref={videoRef} onEnded={handleVideoEnd}/>
        )}

      <Navigation>

        <Buttons>
          {activeStep.id > 0 && (
            <PrevButton onClick={() => handleStepChange(-1)}>
              <WhiteArrowIcon rotated/>
              {t(`${KEYS.common}.previous`)}
            </PrevButton>
          )}

          <NextButton onClick={() => handleStepChange(1)}>
            {t(`${KEYS.common}.next`)}
            <WhiteArrowIcon/>
          </NextButton>
        </Buttons>

          <StoreNavigationWrapper>
            <QRCode />
            <StoreNavigation>
              <Text>
                {t(`${KEYS.onboarding}.downloadApp.prefix`)}
                <RedLink href={oneLink}>
                  {t(`${KEYS.onboarding}.downloadApp.body`)}
                </RedLink>
                {t(`${KEYS.dashboard}.downloadApp.suffix`)}
              </Text>
              <RedLink href={oneLink}>
                <StoreButton/>
              </RedLink>
            </StoreNavigation>
          </StoreNavigationWrapper>

        </Navigation>
      </Wrapper>
    </PageWrapper>
  );
}

export default Onboarding;
