import { KEYS } from "./keys.const";

export const TURKISH_TRANSLATIONS = {
	[KEYS.landing]: {
		bestAppBlock: {
			title: {
				text: 'Mobil Cihazlar için Nihai Arama Kaydedici Uygulaması',
				decorate: true,
				prefix: 'Mobil Cihazlar için Nihai ',
				body: 'Arama Kaydedici',
				suffix: ' Uygulaması'
			},
			subTitle: {
				text: 'İOS cihazınızda konuşmaları kaydedin ve paylaşın.',
				highlightedWord1: 'kaydedin',
				highlightedWord2: 've',
				highlightedWord3: 'paylaşın'
			},
		},
		whyLoveItBlock: {
			title: {
				text: 'Arama Kaydediciyi Neden Seveceksiniz?',
				decorate: true,
				prefix: 'Arama Kaydediciyi Neden',
				body: 'Seveceksiniz',
				suffix: ' '
			},
			reasons: [
				{
					title: {
						text: 'Aramaları kaydet',
					},
					subTitle: 'Arama Kaydedici size sınırsız arama kaydetme gücü sağlar. Canlı olarak tüm önemli aramalarınızı kaydedin ve daha sonra sesli veya yazılı olarak görüntüleyin.'
				},{
					title: {
						text: 'Mükemmel kayıt kalitesi',
					},
					subTitle: 'Gelen ve giden aramaları olabilecek en iyi kalitede kaydedin. Birden çok kayıt seçeneğinden sizin için en uygun olanı seçin.'
				},{
					title: {
						text: 'Aramalarınızı yazılıya çevirin',
						decorate: true,
						prefix: 'Aramalarınızı yazılıya ',
						body: 'çevirin',
						suffix: ''
					},
					subTitle: 'Ses kayıtlarını yazılı notlara dönüştürün. Arama Kaydedici sizin için her şeyi otomatik olarak yazıya çevirir.',
				},{
					title: {
						text: 'Kayıtları sınırsız olarak saklayın',
					},
					subTitle: 'Kaydınızı Google Drive\'a kaydedin veya e-postanıza gönderin.'
				},{
					title: {
						text: 'Tam gizlilik',
					},
					subTitle: 'Gizliliğinizi ciddiye alıyoruz, bu nedenle tüm veriler şifreleniyor ve asla kimseyle paylaşmıyoruz.'
				},{
					title: {
						text: 'Kullanmaya başla',
					},
				}
			]
		},
		getStartedBlock: {
			title: {
				text: 'Başlamaya hazır mısın?',
				decorate: true,
				prefix: '  ',
				body: 'Başlamaya',
				suffix: ' hazır mısın?'
			},
			subTitle: 'Herhangi bir aramayı dinleyin, kaydedin ve yazılıya çevirin.'
		},
		easyToRecordBlock: {
			title: {
				text: 'Aramaları Kaydetmek Çok Kolay',
				decorate: true,
				prefix: 'Aramaları Kaydetmek ',
				body: 'Çok Kolay',
				suffix: ''
			},
			steps: [
				'Telefon numaranızı ekleyin',
				'Aram Kaydedici servis numarasını çevirin',
				'Kaydetmek istediğiniz numarayı girin',
				'Aramaları birleştir',
				'Kaydedin, yazılıya çeviri ve paylaşın arama kayıtları'
			]
		},
		bestChoiceBlock: {
			title: {
				text: 'Arama Kaydedici İçin En İyi Seçim',
				decorate: true,
				prefix: 'Arama Kaydedici İçin ',
				body: 'En İyi Seçim',
				suffix: ''
			},
			reasons: [{
				title: {
					prefix: '2.5\n',
					body: 'milyon',
					suffix: '+'
				},
				subtitle: 'arama kaydedildi',
			},{
				title: {
					prefix: '',
					body: '250,000\n',
					suffix: '+saat\n'
				},
				subtitle: 'kayıt tamamlandı',
			},{
				title: {
					prefix: '20,000\'den fazla\n',
					body: 'yazılıya çevirme',
					suffix: ''
				},
				subtitle: 'işlendi',
			}]
		},
		whyCRBlock: {
			title: {
				text: 'Neden RecCall\'dan Arama Kaydedici?',
				decorate: true,
				prefix: 'Neden ',
				body: 'RecCall\'dan\n',
				suffix: 'Arama Kaydedici?'
			},
			reasons: [[{
				text: 'İyi ses kalitesi',
			},{
				text: 'Kayıtları kaydedin ve paylaşın',
			},{
				text: 'Otomatik ses transkripsiyonu',
			}],[{
				text: 'Sınırsız kayıt'
			},{
				text: 'Tamamen özel',
			},{
				text: 'Dünya çapında kapsama'
			}
			]]
		},
		reviewsBlock: {
			title: {
				text: 'Müşterilerimizden ',
				decorate: true,
				prefix: '',
				body: 'Müşterilerimizden',
				suffix: ' Gelen Yorumlar'
			},
			generalRating: '4.8',
			over30_1: '30.000\'den fazla ',
			over30_2: ' yorum',
			reviews: [{
				text: 'Arama Kaydedici beni birçok kez kurtardı. Bir avukat yardımcısı olarak, sürekli olarak telefon görüşmeleri ve e-postalarla uğraşıyorum. Arama Kaydedici gerçekten her şeyi anlamama yardımcı oluyor ve ileride başvurmak üzere her şeyi el altında tutmamı sağlıyor.',
				name: 'Karen J.'
			},{
				text: 'Sana yeterince teşekkür edemem. Borçlu olmadığım ödenmemiş bir borç için kredi kartı şirketim tarafından telefonla taciz ediliyordum. Tacizi ses yoluyla yakalayabildim ve bir yazılı çevirme oluşturabildim. Kanıt, kredi kartı şirketine karşı iddiamda bana yardımcı oldu ve sonunda barışçıl bir çözüme götürdü.',
				name: 'Marvin Z.'
			},{
				text: 'Kurulumu kolay. Kullanımı kolay. Ve ses gerçekten çok net. Otomatik yazılıya çevirmeyi seviyorum. 10 üzerinden 10. Yapabilseydim 11 verirdim.',
				name: 'Stephanie A.'
			}]
		}
	},
	[KEYS.common]: {
		tryNow: 'Şimdi dene',
		videoGuide: 'Video guide',
		confirm: 'Onaylamak',
		continue: 'Devam et',
		continueToPayment: 'Ödeme devam',
		android: 'Android',
		ios: 'IOS',
		chooseCountry: 'Ülke Seçiniz',
		next: 'ileri',
		previous: 'Önceki',
		navigation: 'Navigasyon',
		policies: 'Politikalar',
		contactUs: 'Bize Ulaşın',
		links : {
			faq: 'SSS',
			signIn: 'Oturum aç',
			signUp: 'Sign Up',
			reviews: 'Yorumlar',
			aboutUs: 'Hakkımızda',
			terms: 'Kullanım Şartları',
			bestChoice: 'En iyi seçenek',
			privacy: 'Gizlilik Politikası',
			unsubscribe: 'Aboneliği iptal et',
			howItWorks: 'Nasıl çalışır',
			keyBenefits: 'Önemli avantajlar',
			howToRecord: 'Aramalar nasıl kaydedilir',
			searchRecordings: 'Kayıt ara',
			support: 'support@reccall.app',
		}
	},
	[KEYS.choosePhone] : {
		title: {
			text: 'Hangi cihazı kaydetmek istiyorsunuz?',
			prefix: 'Hangi cihazı',
			body: 'kaydetmek istiyorsunuz',
			suffix: ' ?'
		},
		steps: [{
			text: 'Adım 1',
		},{
			text: 'Adım 2',
		}],
		phones: [{
			text: 'Android',
		}, {
			text: 'IOS',
		}]
	},
	[KEYS.choosePlan] : {
		title: {
			text: 'Planınızı seçin',
			prefix: '',
			body: 'Planınızı',
			suffix: ' seçin'
		},
		mostPopular: 'MOST POPULAR',
		planItems: [{
			title: '1 ay'
		},{
			title: '1 GÜNLÜK DENEME'
		},{
			title: '12 ay'
		}
		],
		pricePrefix: '',
		priceSuffix: ' $',
		perMonth: '/ay*',
		priceHint: '*1 veya 12 aylık abonelik için aylık fiyat. Fiyat indirimlidir.',
		subscriptionsHint: '** The subscription will be automatically renewed after the end of the trial period ',
		platformHint: '***Currently works only on IOS',
	},
	[KEYS.verifyPhone] : {
		title: {
			text: 'Telefon numaranızı\n girin',
			prefix: '',
			body: 'Telefon numaranızı',
			suffix: ' girin'
		},
		inputLabel: 'telefon numarası',
		invalidFormat: '* Yanlış karakter sayısı',
		chooseCountryTitle: 'Ülke Seçiniz'
	},
	[KEYS.verifyCode] : {
		title: {
			text: '6 haneli bir kod giriniz',
			prefix: '',
			body: '6 haneli bir kod',
			suffix: ' giriniz'
		},
		getRequestPlaceholder: 'Talebiniz işleniyor, lütfen bekleyin...',
		checkRequestPlaceholder: 'Checking your code...',
		resendSMS: 'Şundan sonra SMS\'i tekrar gönder',
		wrongCode: '*Yanlış kod',
		sendAgain: 'KODU TEKRAR GÖNDER',
		underlineDecoratedText: {
			text: 'Or change phone number!',
			prefix: 'Or change ',
			body: 'phone number',
			suffix: '!'
		}
	},
	[KEYS.successfulRegistration] : {
		title: {
			text: 'Başarıyla kaydedildi',
			prefix: '',
			body: '',
			suffix: ''
		},
		subTitle: {
			text: 'Hesabınızı başarıyla kaydettik',
			prefix: '',
			body: '',
			suffix: ''
		},
		policyAgreement: 'Gizlilik Politikasını kabul ediyorum'
	},
	[KEYS.onboarding] : {
		steps: [{
			title: 'Adım 1',
			subTitle: 'Telefon numaranızı ekleyin'
		},{
			title: 'Adım 2',
			subTitle: 'Aram Kaydedici servis numarasını çevirin'
		},{
			title: 'Adım 3',
			subTitle: 'Kaydetmek istediğiniz numarayı girin'
		},{
			title: 'Adım 4',
			subTitle: 'Aramaları birleştir'
		},{
			title: 'Adım 5',
			subTitle: 'Arama kayitlarini kaydedin, yazilia çevirin ve paylaşın'
		},{
			title: 'Adım 6',
			subTitle: 'Eğitimi izleyin'
		}],
		downloadApp: {
			text: 'Bağlantı yoluyla App Store\'dan bir uygulama indirin',
			prefix: ' ',
			body: 'Bağlantı',
			suffix: ' yoluyla App Store\'dan bir uygulama indirin'
		}

	},
	[KEYS.videoOnboarding] : {
		tabs: [
			'Giden', 'Gelen'
		],
		breadcrumbs: [
			'Kontrol Paneli', 'Aramalar nasıl kaydedilir'
		],
		records: [[{
			title: 'Adım 1',
			subTitle: {
				prefix: 'Ekranındaki "Aramaları kaydet" büyük kırmızı düğmeye basın',
				body: '',
				suffix: ''
			}
		},{
			title: 'Adım 2',
			subTitle: {
				prefix: 'Bir telefon numarası içeren bir açılır pencere göreceksiniz. ',
				body: 'Aramak için bu numaraya',
				suffix: ' tıklayın (bu otomatik bir hat, kimseye bağlı olmayacaksınız).'
			}
		},{
			title: 'Adım 3',
			subTitle: {
				prefix: 'Numaranın cevap vermesini bekleyin ve ',
				body: 'Aramayı birleştir',
				suffix: '". '
			}
		},{
			title: 'Adım 4',
			subTitle: {
				prefix: 'Numaranın cevap vermesini bekleyin ve ',
				body: 'Aramayı birleştir',
				suffix: '" Arama kaydı otomatik olarak başlayacaktır.'
			}
		},{
			title: 'Kaydınız için uygulamayı kontrol edin',
			subTitle: {
				prefix: '',
				body: '',
				suffix: ''
			}
		}],[{
			title: 'Adım 1',
			subTitle: {
				prefix: 'Aramayı cevapla',
				body: '',
				suffix: ''
			}
		},{
			title: 'Adım 2',
			subTitle: {
				prefix: '"Aramaları kaydet" ekranındaki büyük kırmızı düğmeye basın',
				body: '',
				suffix: ''
			}
		},{
			title: 'Adım 3',
			subTitle: {
				prefix: 'Telefon numarası içeren bir açılır pencere göreceksiniz. ',
				body: 'Aramak için bu numaraya tıklayın',
				suffix: ' (bu otomatik bir hat, kimseye bağlı olmayacaksınız).'
			}
		},{
			title: 'Adım 4',
			subTitle: {
				prefix: 'Aramanızı kaydetmek istediğiniz numarayı tuşlayın',
				body: '',
				suffix: ''
			}
		},{
			title: 'Adım 5',
			subTitle: {
				prefix: 'Numaranın cevap vermesini bekleyin ve "',
				body: 'Aramayı birleştir',
				suffix: '". Arama kaydı otomatik olarak başlayacaktır.'
			}
		},{
			title: 'Kaydınız için uygulamayı kontrol edin',
			subTitle: {
				prefix: '"Ses komutunu kullan. Say "GO" to',
				body: 'Aramaları kaydetmeye başlamak için "GO" deyin',
				suffix: ''
			}
		}]],
		mobileGuideButton: 'Eğitimi izleyin',
		downloadSubTitle: 'Download an app from App Store',
		hint: 'You can make call recordings only\n through the mobile application'
	},
	[KEYS.dashboard] : {
		records: [{
			title: 'Arama Kaydı 1'
		},{
			title: 'Arama Kaydı 2'
		},{
			title: 'Arama Kaydı 3'
		},{
			title: 'Arama Kaydı 4'
		},{
			title: 'Arama Kaydı 5'
		},{
			title: 'Arama Kaydı 6'
		},{
			title: 'Arama Kaydı 7'
		},{
			title: 'Arama Kaydı 8'
		},{
			title: 'Arama Kaydı 9'
		},{
			title: 'Arama Kaydı 10'
		},{
			title: 'Arama Kaydı 11'
		},{
			title: 'Arama Kaydı 12'
		}],
		downloadApp: {
			text: 'Bağlantı yoluyla App Store\'dan bir uygulama indirin',
			prefix: ' ',
			body: 'Bağlantı',
			suffix: ' yoluyla App Store\'dan bir uygulama indirin'
		},
		goToStore: {
			text: 'Go to the application to start\n recording calls',
			prefix: 'Go to the ',
			body: 'application',
			suffix: ' to start\n recording calls'
		},
		mobileTitle: 'Voice Recordings'
	},
	[KEYS.footer] : {
		CRForAll: 'Call Recorder for everyone',
		disclaimerTitle: 'Feragatname',
		disclaimerText: 'Arama Kaydedici yazılımı YALNIZCA YASAL KULLANIM İÇİN TASARLANMIŞTIR. Lisanslı Yazılımın size ait olmayan bir cihaza kurulması yasanın ve yerel yargı yasalarınızın ihlalidir. Kanun genel olarak, Lisanslı Yazılımı kurmayı düşündüğünüz cihazların sahiplerini bilgilendirmenizi gerektirir. Bu gerekliliğin ihlali, ihlal eden kişiye ciddi parasal ve cezai yaptırımlar uygulanmasına neden olabilir. Lisanslı Yazılımı yüklemeden ve kullanmadan önce kendi yargı alanınız dahilinde kullanmanın yasallığı konusunda kendi hukuk danışmanınıza danışmalısınız. Lisanslı Yazılımı bu tür bir cihaza kurmaktan yalnızca siz sorumlusunuz ve Arama Kaydedicinin sorumlu tutulamayacağının farkındasınız.'
	}
}