import styled from 'styled-components';

import pageBackground from 'assets/img/common_bg.png'

export const PhonesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 62px;
  background: url(${ pageBackground });
  background-size: 100% 100%;
	width: 100%;
`

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const PhonesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 570px;
	background: transparent;

  @media (max-width: 1169px) {
    width: 288px;
	}
`

export const PhonesListItem = styled.div`
  display: flex;
  flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0 25px;
  width: 100%;
  height: 57px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(2px);
  border-radius: 10px;
	box-sizing: border-box;
	cursor: pointer;

  @media (max-width: 1169px) {
    height: 53px;
  }
  
`

export const Icon = styled.img`
	width: 31px;
  height: 31px;
`