import styled from 'styled-components';
import { Link } from "react-router-dom";

import pageBackground from "assets/img/common_bg.png";
import confetti from "assets/img/confetti.png";
import arrow_down from "assets/img/arrow_down.png";
import edit_icon from "assets/img/edit_icon.png";
import closeIcon from "assets/img/icons/white_cross_icon.png";
import searchIcon from "assets/img/icons/magnifyingglass.png";

export const buttonSettings = '' +
	'width: 166px !important;' +
	'height: 50px !important;' +
	'align-self: center;' +
	'align-self: center;' +

	'@media (max-width: 1169px) {' +
	'  width: 162px !important;' +
	'  height: 47px !important;' +
	'}'

export const VerifyNumberWrapper = styled.div`
  display: flex;
  flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 147px 0 137px;
	
  background: url(${ pageBackground });
  background-size: 100% 100%;
	flex-grow: 1;
	
	@media (max-width: 1169px){
    padding: 43px 0 17px;
	}
`

export const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
  align-items: center;
  justify-content: center;
	width: 570px;
	min-width: 570px;
	min-height: 230px;
  padding: 20px 0;
  gap: 10px;
	background: #000;
  border-radius: 24px;
	
	${({ showConfetti }) => showConfetti && `background: #000 url(${ confetti });`};
	background-size: 450px auto;

  @media (max-width: 1169px) {
    min-width: 288px;
    width: 288px;
    background-size: 260px auto;
  }
`

export const BlockTitle = styled.h1`
  font-family: Poppins, sans-serif;
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
	color: #FFFFFF;
	margin: 0;
	white-space: nowrap;
	
	@media (max-width: 1169px) {
    white-space: break-spaces;
		width: 200px;
    font-size: 18px !important;
    line-height: 27px !important;
  }
`

export const RedText = styled.span`
	${ BlockTitle };

  font-size: 24px;
  line-height: 36px;

  @media (max-width: 1169px) {
    font-size: 18px;
    line-height: 27px;
  }
	
	color: #EB5560;
`

export const PhoneNumberFieldLabel = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0;
	width: 370px;
  text-align: left;
  color: rgba(255, 255, 255, 0.8);
	
	@media (max-width: 1169px) {
    width: 265px;
	}
`

export const PhoneNumberFieldWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 4px;
	padding: 0 10px;
	box-sizing: border-box;
  background: #1C1C1E;
	border-radius: 8px;
  width: 370px;
	height: 50px;

  border: ${({ showError }) => showError ? '1px solid #EABC04' : '1px solid transparent'};

  @media (max-width: 1169px) {
    width: 265px;
    height: 46px;
	}
`

export const PhoneNumber = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0;
  text-align: center;
	margin-top: -3px;
  color: rgba(255, 255, 255, 0.8);
	
	@media (max-width: 1169px){
    font-size: 15px;
    line-height: 23px;
  }
`

export const PhoneNumberField = styled.input`
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
  color: #fff;
  background: transparent;
	width: 300px;
  border: none;
	
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
	
	&:focus {
    outline: none;
	}
	
	&::placeholder {
    color: rgba(255, 255, 255, 0.35);
	}
	
	@media (max-width: 1169px) {
		width: 186px;
	}
`

export const CountryIcon = styled.img`
	width: 20px;
	height: 15px;
	cursor: pointer;
`

export const MenuItemText = styled.span`
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0;
  text-align: center;
	color: #fff;
	cursor: pointer;
`

export const CountryDialCode = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
	cursor: pointer;
  margin-left: -4px;
  padding-left: 4px
`

export const ArrowDownIcon = styled.img.attrs(props => ({ src: arrow_down }))`
	width: 10px;
`

export const WrongNumber = styled.span`
  font-family: SF Pro, sans-serif;
  font-size: 14px;
  font-weight: 510;
  line-height: 21px;
  letter-spacing: 0.33764711022377014px;
  text-align: left;
	width: 370px;
	color: #EABC04;
	margin-top: 6px;
	
	@media (max-width: 1169px){
    font-size: 12px;
    line-height: 17px;
    width: 265px;
	}
`

export const CodeFieldWrapper = styled.div`
	display: flex;
  position: relative;
  width: 304px;
	height: 50px;

  @media (max-width: 1169px){
    width: 265px;
    height: 46px;
  }
`

export const CodeInputField = styled.input`
	width: 100%;
	height: 50px;
	letter-spacing: 31px;
	color: transparent;
	background: transparent;
	border: none;
	position: absolute;
	z-index: 1;
	
	&::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;

  &:focus {
    outline: none;
  }

  @media (max-width: 1169px){
    width: 265px;
    height: 46px;
  }
`

export const CodeFieldCover = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: 8px;
	height: 50px;
	position: absolute;
	z-index: 3;

  @media (max-width: 1169px){
    height: 46px;
  }
`

export const CodeFieldCell = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
  font-family: Poppins, sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0;
  text-align: center;
	text-decoration: underline;
  text-decoration-color: rgba(255, 255, 255, 0.1);
  text-decoration-thickness: 1px;
	height: 50px;
	width: 44px;
  background: #1C1C1E;
  border-radius: 8px;
	color: #fff;
	cursor: text;
	
  ${({ isEmpty }) => isEmpty && `
  	color: rgba(255, 255, 255, 0.1)
  `};
	
	${({ isDisabled }) => isDisabled && `
  	color: rgba(255, 255, 255, 0.05); 
  	background: rgba(28, 28, 30, 0.5);
  `};

  border: 1px solid ${({ isFocused, showEmptyBorder, isDisabled }) => 
					(isFocused || showEmptyBorder) && !isDisabled ? `rgba(255, 255, 255, 0.35)` : 'transparent'
	};
	
	${({ showError }) => showError && `
		text-decoration: underline;
  	text-decoration-color: #EABC04;
	`};
	
	@media(max-width: 1169px){
    font-size: 24px;
    line-height: 36px;
    width: 40px;
    height: 46px;
  }
`

export const ErrorMessage = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0;
  text-align: center;
	color: #EABC04;
	
	@media(max-width: 1169px){
    font-size: 12px;
    line-height: 17px;
  }
`

export const ResendButton = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  text-transform: uppercase;
  text-align: center;
	color: #EB5560;
	cursor: pointer;
	
	@media(max-width: 1169px){
    font-size: 13px;
    line-height: 18px;
  }
`

export const ChangePhoneHint = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);

  @media(max-width: 1169px){
    font-size: 13px;
    line-height: 18.2px;
  }
`

export const UnderlineDecoration = styled(Link)`
	color: inherit;
	text-decoration: underline;
	font: inherit;
`

export const TimerText = styled.div`
	display: flex;
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0;
  text-align: center;
  color: #FFFFFF59;

  @media(max-width: 1169px) {
    font-size: 13px;
    line-height: 18.2px;
  }
`

export const TimerCountdown = styled.div`
	${TimerText};
  color: #FFFFFFCC;
`

export const EditIcon = styled.img.attrs(p => ({ src: edit_icon }))`
	margin-left: 10px;
	width: 24px;
	height: 24px;
	cursor: pointer;
`

export const PendingText = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
	
	@media(max-width: 1169px){
    font-size: 13px;
    line-height: 18.2px;
  }
`

export const SearchFormWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
  width: 570px;
  height: 438px;
	padding: 20px 16px;
	box-sizing: border-box;
	max-height: 405px;
  background: #1C1C1E;
  border-radius: 24px;
	margin-top: 185px;

  @media(max-width: 1169px){
		width: 100vw;
		max-width: 570px;
    max-height: 360px;
    margin-top: 60px;
	}
`

export const CountrySearchFormHeader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`

export const ModalCloseButton = styled.img.attrs(p => ({ src: closeIcon }))`
	width: 24px;
	height: 24px;
	cursor: pointer;
`

export const CountrySearchFormTitle = styled.h3`
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0;
  text-align: center;
	color: #fff;
	padding: 0;
	margin: 0;

	@media (max-width: 1169px) {
    font-size: 18px;
    line-height: 27px;
  }
`

export const SearchFieldWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	height: 52px;
  background: rgba(118, 118, 128, 0.24);
  border-radius: 8px;
	margin-top: 20px;
	padding: 0 18px;
  box-sizing: border-box;

  @media (max-width: 1169px) {
    height: 44px;
	}
`

export const SearchIcon = styled.img.attrs(p => ({ src: searchIcon }))`
	width: 16px;
	height: 16px;
	margin-right: 10px;
`

export const SearchField = styled.input`
	height: 40px;
	width: 100%;
	border: none;
  caret-color: #EB5560;
	background: transparent;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0;
  text-align: left;
  color: rgba(255, 255, 255, 0.8);
	
  @media (max-width: 1169px) {
    font-size: 14px;
  }
	
  &::placeholder {
    color: rgba(255, 255, 255, 0.1);
  }
	
  &:focus {
    outline: none;
  }
`

export const SearchResultWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
	margin-top: 20px;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #373738;
		border-radius: 2.5px;
  }

  ::-webkit-scrollbar-track {
    background: #242426;
    border-radius: 2.5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`

export const SearchResultItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
	align-items: center;
  width: 99%;
  height: 48px;
  min-height: 48px;
	max-height: 48px;
  margin-right: 34px;
  flex-grow: 1;
  cursor: pointer;
	border-bottom: none;

  color: rgba(255, 255, 255, 0.8);

  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0;
  text-align: left;

  @media (max-width: 1169px) {
    height: 45px;
    min-height: 45px;
    max-height: 45px;
		font-size: 14px;
    border-bottom: 1px solid rgba(118, 118, 128, 0.24);;
  }

  &:hover {
    background: #242426FF;
  }
`

export const SearchResultItemFlag = styled.img`
	width: 32px;
	height: 24px;
	margin-right: 11px;

  @media (max-width: 1169px) {
    width: 24px;
    height: 18px;
	}
`

export const SearchResultItemText = styled.div`
	display: flex;
	flex-grow: 1;
`

export const FoundText = styled.div`
	color: #EB5560;
`

export const SearchResultItemDialCode = styled.div`
	max-width: 60px;
`

export const RegistrationMessage = styled.div`
  font-family: SF Pro, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.33764711022377014px;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
	max-width: 500px;

  @media (max-width: 1169px) {
    max-width: 240px;
		font-size: 15px;
	}
`

export const CheckboxWrapper = styled.div`
	width: fit-content;
	margin-top: 70px;
	max-width: 100%;
`