import {useRef, useState} from "react";
import { useTranslation } from "react-i18next";

import { RedTitle, BlockTitle } from "components";
import { KEYS} from "constants";

import {
  GetStartedText,
  GetStartedVideo,
  GetStartedWrapper,
  GetStartedTextWrapper,
} from './styles'

const GetStartedBlock = () => {
  const { t } = useTranslation();
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleVideo = () => {
    setIsPlaying(!isPlaying);
    if (isPlaying === true) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
      if( window.innerWidth < 1170 ) {
        videoRef.current.requestFullscreen();
      }
    }
  };

  const handleVideoEnd = () => {
    setIsPlaying(false)
  }

  return (
    <GetStartedWrapper>
      <GetStartedTextWrapper>
        <BlockTitle>
          {t(`${KEYS.landing}.getStartedBlock.title.prefix`)}
          <RedTitle>
            {t(`${KEYS.landing}.getStartedBlock.title.body`)}
          </RedTitle>
          {t(`${KEYS.landing}.getStartedBlock.title.suffix`)}
        </BlockTitle>
        <GetStartedText>
          {t(`${KEYS.landing}.getStartedBlock.subTitle`)}
        </GetStartedText>
      </GetStartedTextWrapper>
      <GetStartedVideo onClick={handleVideo} ref={videoRef} onEnded={handleVideoEnd}/>
    </GetStartedWrapper>
  );
}

export default GetStartedBlock;
