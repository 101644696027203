import React from "react";
import styled, { keyframes } from "styled-components";

export const CHECKBOX_TYPES = {
  dark: 'dark',
  light: 'light'
}

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
`;

const Label = styled.label`
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin: 0.6em 1em;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0;
  text-align: left;
  color: rgba(255, 255, 255, 0.8);
  padding-left: 0;
  
  @media(max-width: 1169px) {
    padding-left: 45px;
    margin: 0 1em;
  }
  ${({ type_ }) => type_ === CHECKBOX_TYPES.dark && `
    color: rgba(255, 255, 255, 0.35);
    font-size: 15px;
  `};
`;

const rotate = keyframes`
 from {
    opacity: 0;
    transform: rotate(0deg);
  }
  to {
    opacity: 1;
    transform: rotate(45deg);
  }
`;

const Indicator = styled.div`
  width: 20px;
  height: 20px;
  background: #e6e6e6;
  position: absolute;
  top: 0;
  left: -1.6em;
  border: 1px solid #757575;
  border-radius: 0.2em;

  ${Input}:checked & {
    background: #d1d1d1;
  }

  ${Label}:hover & {
    background: #ccc;
  }

  &::after {
    content: "";
    position: absolute;
    display: none;
  }

  ${Input}:checked + &::after {
    display: block;
    top: 0;
    left: 0.35em;
    width: 35%;
    height: 70%;
    border: solid #263238;
    border-width: 0 0.2em 0.2em 0;
    animation-name: ${rotate};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    margin-bottom: 2px;

    ${({ type_ }) => type_ === CHECKBOX_TYPES.dark ? `
      border-color: #FFFFFF;` : ``
    }
  }
  
  ${({ type_ }) => type_ === CHECKBOX_TYPES.dark ? `
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-radius: 4px;` : ``
  };
  
  @media (max-width: 1169px) {
    left: 1em;
  };
  
}
`;

const Checkbox = ({
  id,
  name,
  label,
  value,
  type_,
  checked,
  onClick
}) => {
  return (
    <Label htmlFor={id} onClick={onClick} type_={type_}>
      {label}
      <Input
        readOnly
        id={id}
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
      />
      <Indicator type_={type_}/>
    </Label>
  );
}

export default Checkbox;