import {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectFade, Autoplay } from 'swiper'

import {
  Button,
  RedTitle,
  BlockTitle,
  ButtonTypes
} from "components";
import { KEYS} from "constants";

import {
  Step,
  StepImage,
  HowToWrapper,
  MobileLayout,
  StepsWrapper,
  WebStepsLayout,
  StepIconWrapper,
  ActiveStepArrow,
  StepTextWrapper,
  PassiveStepArrow,
  MobileStepFooter,
} from './styles'

import { STEPS_LIST } from "./ui_constants.const";

const EasyToRecordBlock = () => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(STEPS_LIST[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      const nextStep = activeStep.id + 1 === STEPS_LIST.length ? STEPS_LIST[0] : STEPS_LIST.find(el => el.id === activeStep.id + 1)

      setActiveStep(nextStep);

    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [activeStep])


  return (
    <HowToWrapper id="how-it-works" name="how-it-works">
      <BlockTitle>
        {t(`${KEYS.landing}.easyToRecordBlock.title.prefix`)}
        <RedTitle>
          {t(`${KEYS.landing}.easyToRecordBlock.title.body`)}
        </RedTitle>
        {t(`${KEYS.landing}.easyToRecordBlock.title.suffix`)}
      </BlockTitle>
      <WebStepsLayout>
        <StepsWrapper>
          {STEPS_LIST.map((step, i) => (
            <Step key={step.id} onMouseOver={() => setActiveStep(step)} onClick={() => setActiveStep(step)}>
              <StepIconWrapper isActive={activeStep.id === step.id}>
                {activeStep.id === step.id ? <ActiveStepArrow/> : <PassiveStepArrow/>}
              </StepIconWrapper>
              <StepTextWrapper isStepActive={activeStep.id === step.id}>
                {t(`${KEYS.landing}.easyToRecordBlock.steps.${i}`)}
              </StepTextWrapper>
            </Step>
          ))}
          <Button displayType={'web'} type={ButtonTypes.red} style_={'margin-top: 35px;'}>
            {t(`${KEYS.common}.tryNow`)}
          </Button>
        </StepsWrapper>
        <StepImage src={activeStep.image}/>
      </WebStepsLayout>
      <MobileLayout>
        <Swiper
          slidesPerView={1}
          loop={true}
          navigation={{
            nextEl: '.nextStep',
          }}
          effect="creative"
          modules={[Navigation, EffectFade, Autoplay]}
          initialSlide={0}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
        >
          {STEPS_LIST.map((step, i) => (
            <SwiperSlide key={step.id}>
              <Step>
                <StepImage src={step.image}/>
                <MobileStepFooter>
                  <StepTextWrapper isStepActive>
                    {t(`${KEYS.landing}.easyToRecordBlock.steps.${i}`)}
                  </StepTextWrapper >
                  <div className={'nextStep'}>
                    <StepIconWrapper>
                      <ActiveStepArrow/>
                    </StepIconWrapper>
                  </div>
                </MobileStepFooter>
              </Step>
            </SwiperSlide>
          ))}
        </Swiper>
      </MobileLayout>
      <Button displayType={'mobile'} type={ButtonTypes.red} style_={'margin-top: 10px;'}>
        {t(`${KEYS.common}.tryNow`)}
      </Button>
    </HowToWrapper>
  );
}

export default EasyToRecordBlock;
