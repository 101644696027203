import { Link } from "react-router-dom";
import styled from "styled-components";
import { ButtonConfigs } from "components";

export const LayoutWrapperBase = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  max-height: 100vh;
  width: 100vw;
  position: relative;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100vw;
  top: 124px;
  overflow-x: hidden;
  overflow-y: scroll;
  
  @media(max-width: 1169px){
    top: 67px;
  }
`

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  background: #000;
  width: 100vw;
`

export const SeparatorLine = styled.div`
  background: rgb(38, 38, 38);
  height: 1px;
  width: 1170px;
  margin-top: 22px;
  
  @media (max-width: 1169px) {
    width: 320px;
    margin-top: 12px;
  }
`

export const Button = styled(Link).attrs(({  to='/choose-phone', ...p }) => ({  to, ...p }))`
  ${({ type }) => ButtonConfigs[type]};
  
  ${({ style_}) => style_ };
  
  white-space: nowrap;
  text-decoration: none;
  padding: 12px 5px;
  width: fit-content;
  min-width: 170px;
  height: 50px;
  border-radius: 6px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 163%;
  letter-spacing: 0;
  text-align: center;
  box-sizing: border-box;
  animation: pulsing 2.5s infinite cubic-bezier(0.66, 0, 0, 1);
  transition: all 300ms ease-in-out;
  box-shadow: 0 0 0 0 rgb(235, 85, 96);
  @-webkit-keyframes pulsing { to {box-shadow: 0 0 0 15px rgba(235, 85, 96, 0);}}
  @-moz-keyframes pulsing {to { box-shadow: 0 0 0 15px rgba(235, 85, 96, 0);}}
  @-ms-keyframes pulsing {to {box-shadow: 0 0 0 15px rgba(235, 85, 96, 0);}}
  @keyframes pulsing { to {box-shadow: 0 0 0 15px rgba(235, 85, 96, 0);}}

  display: ${({ displayType }) => displayType ? (
          displayType === 'web' ? 'block' : 'none') : 'block'
  };

  @media (max-width: 1169px) {
    display: ${({ displayType }) => displayType ? (
            displayType === 'mobile' ? 'block' : 'none') : 'block'
    };
    min-width: 288px;
    height: 47px;
    font-size: 15px;
    line-height: 150%;
  }
`

export const BlockTitle = styled.h1`
  font-family: Poppins, sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0;
  text-align: left;
  color: #fff;
  margin: 0;
  padding: 0;
  max-width: 1440px;

  @media (max-width: 1169px) {
    max-width: 320px;
    font-size: ${({ larger }) => larger ? '25px' : '20px' };
    margin-bottom: 22px;
    text-align: center;
  }
`

export const RedTitle = styled.span`
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-style: italic;
  white-space: nowrap;
  color: #EB5560;
  
  margin-left: ${({ stepOutLeft }) => stepOutLeft && '10px'};
  margin-right: ${({ stepOutRight }) => stepOutRight && '10px'};
  margin-bottom: 0;
  
  @media (max-width: 1169px) {
    margin-left: ${({ stepOutLeft }) => stepOutLeft && '5px'};
    margin-right: ${({ stepOutRight }) => stepOutRight && '5px'};
    margin-bottom: 22px;
  }
`