import { KEYS } from "./keys.const";

export const KOREAN_TRANSLATIONS = {
	[KEYS.landing]: {
		bestAppBlock: {
			title: {
				text: '최종 통화 녹음기 모바일용 어플',
				decorate: true,
				prefix: '최종 통화 녹음기 모바일용 어플',
				body: '',
				suffix: ''
			},
			subTitle: {
				text: 'iOS\xA0기기로\xA0통화를\xA0녹음,\xA0저장\xA0및\xA0공유할\xA0수\xA0있습니다.',
				highlightedWord1: '',
				highlightedWord2: '',
				highlightedWord3: ''
			},
		},
		whyLoveItBlock: {
			title: {
				text: '통화 녹음기를 좋아하는 이유',
				decorate: true,
				prefix: '통화 녹음기를 좋아하는 이유',
				body: '',
				suffix: ''
			},
			reasons: [
				{
					title: {
						text: '통화 녹음',
					},
					subTitle: '통화 녹음기는 간단한 통화 녹음 기능을 제공합니다. 하지만 그들이 말하는 것을 듣는 게 아니라 손가락을 툭툭 치면 통화 녹음기도 기록할 수 있습니다. 통화 녹음기를 통해서 통화내역을 듣거나 읽어보세요'
				},{
					title: {
						text: '좋은 녹음 품질',
					},
					subTitle: '수신 및 발신 통화를 최상의 품질로 기록합니다. 당신에게 가장 적합한 여러 녹음 옵션 중에서 선택하세요'
				},{
					title: {
						text: '통화 내역 기록',
						decorate: true,
						prefix: '',
						body: '',
						suffix: ''
					},
					subTitle: '오디오 녹음을 필기 노트로 전환할 수 있습니다. 통화 기록기은 자동으로 모든 것을 저장됩니다.',
				},{
					title: {
						text: '제한 없이 녹음 저장',
					},
					subTitle: '구글드라이버 또는 이메일로 녹음 저장하세요'
				},{
					title: {
						text: '모든 정보 보호됩니다',
					},
					subTitle: '사용자의 개인 정보를 중요하게 생각하므로 사용자의 기록이 완전히 암호화되어 다른 사용자와 공유되지 않습니다.'
				},{
					title: {
						text: '사용 시작',
					},
				}
			]
		},
		getStartedBlock: {
			title: {
				text: '시작할 준비가 되셨나요?',
				decorate: true,
				prefix: '시작할 준비가 되셨나요?',
				body: '',
				suffix: ''
			},
			subTitle: '통화 내용 듣기, 녹음 및 기록'
		},
		easyToRecordBlock: {
			title: {
				text: '통화 녹음이 매우 쉽습니다.',
				decorate: true,
				prefix: '통화 녹음이 매우 쉽습니다.',
				body: '',
				suffix: ''
			},
			steps: [
				'본인의 전화번호를 추가해 주세요',
				'통화 녹음기 서비스 번호로 연락해주세요',
				'녹음할 번호로 전화를 걸어 주세요',
				'통화 서로 연결시켜주세요',
				'중요한 통화를 녹음, 저장 및 공유하세요.'
			]
		},
		bestChoiceBlock: {
			title: {
				text: '통화 녹음기로 최고의 선택',
				decorate: true,
				prefix: '통화 녹음기로 최고의 선택',
				body: '',
				suffix: ''
			},
			reasons: [{
				title: {
					prefix: '2.5\n',
					body: '백만',
					suffix: '+'
				},
				subtitle: '건 이상의 통화 기록하였습니다',
			},{
				title: {
					prefix: '',
					body: '2500\n',
					suffix: ''
				},
				subtitle: '시간 이상의 녹음 저장하였습니다',
			},{
				title: {
					prefix: '20,000+\n',
					body: '개 이상의 전사 처리되었습니다',
					suffix: ''
				},
				subtitle: '',
			}]
		},
		whyCRBlock: {
			title: {
				text: 'RecCall을 선택한 이유?',
				decorate: true,
				prefix: 'RecCall을 선택한 이유?',
				body: '',
				suffix: ''
			},
			reasons: [[{
				text: '좋은 음성 품질',
			},{
				text: '녹음 저장',
			},{
				text: '전사가 포함됨',
			}],[{
				text: '무제한 녹음'
			},{
				text: '완전 비공개',
			},{
				text: '전 세계 적용 가능'
			}
			]]
		},
		reviewsBlock: {
			title: {
				text: '사용자의 후기',
				decorate: true,
				prefix: '사용자의 후기',
				body: '',
				suffix: ''
			},
			generalRating: '4.8',
			over30_1: '3만 건 이상의 후기',
			over30_2: '',
			reviews: [{
				text: '통화 녹음기는 여러 번에 걸쳐 저를 구해주었습니다. 저는 변호사 보조원으로 일하면서 항상 전화와 이메일을 번갈아가며 처리하고 있습니다. 통화 녹음기는 모든 것을 보다 명확하게 이해하게 해주고, 나중에 필요한 정보들을 손쉽게 보관할 수 있도록 도와줍니다.',
				name: 'Karen J.'
			},{
				text: '제가 정말 감사드립니다. 제가 갚지 않은 미결제금 때문에 신용카드 회사에서 전화로 괴롭힘을 당했는데, 통화 녹음기를 사용하여 그 괴롭힘을 녹음하고 필요한 내용을 기록할 수 있었습니다. 그 증거는 신용카드 회사에 대한 제 소송에서 큰 도움을 주었으며, 결국 원만한 해결책을 찾을 수 있게 되었습니다.',
				name: 'Marvin Z.'
			},{
				text: '설치하기도 쉽고 사용하기도 쉽습니다. 그리고 오디오 품질도 정말, 정말 좋습니다. 자동으로 이루어지는 전사 기능이 매우 마음에 듭니다. 10점 만점에 10점을 줄 만큼 만족스럽습니다. 만약 가능하다면 11점을 줄 수 있을 정도로 좋습니다.',
				name: 'Stephanie A.'
			}]
		}
	},
	[KEYS.common]: {
		tryNow: '지금 체험해 보세요',
		videoGuide: 'Video guide',
		confirm: '확인',
		continue: '계속하기',
		continueToPayment: '결제 계속하기',
		android: 'Android',
		ios: 'IOS',
		chooseCountry: '지역 선택',
		next: '본인의 전화번호를 입력하세요',
		previous: '이전',
		navigation: '내비게이션',
		policies: '정책들',
		contactUs: '문의하기',
		links : {
			faq: 'FAQ',
			signIn: '로그인',
			signUp: 'Sign Up',
			reviews: '후기',
			aboutUs: '저희 대해',
			terms: '이용약관',
			bestChoice: '최고 선택',
			privacy: '개인 정보 보호 정책',
			unsubscribe: '구독 취소',
			howItWorks: '작동 방식',
			keyBenefits: '주요 특징',
			howToRecord: '통화 녹음하는 방법',
			searchRecordings: '녹음 기록 검색',
			support: 'support@reccall.app',
		}
	},
	[KEYS.choosePhone] : {
		title: {
			text: '녹음할 기기를 선택하세요',
			prefix: '녹음할 기기를 선택하세요',
			body: ' ',
			suffix: ''
		},
		steps: [{
			text: '1 단계',
		},{
			text: '2 단계',
		}],
		phones: [{
			text: 'Android',
		}, {
			text: 'IOS',
		}]
	},
	[KEYS.choosePlan] : {
		title: {
			text: '요금제를 선택하세요',
			prefix: '요금제를 선택하세요',
			body: '',
			suffix: ''
		},
		mostPopular: 'MOST POPULAR',
		planItems: [{
			title: '1개월'
		},{
			title: '1일 무료 체험'
		},{
			title: '12개월'
		}
		],
		pricePrefix: '$',
		priceSuffix: '',
		perMonth: '/한달의 요금',
		priceHint: '*Monthly price as per 1 or 12-month subscription. The price is discounted.',
		subscriptionsHint: '** The subscription will be automatically renewed after the end of the trial period ',
		platformHint: '***Currently works only on IOS',
	},
	[KEYS.verifyPhone] : {
		title: {
			text: '본인의 전화번호를 입력해 주세요',
			prefix: '본인의 전화번호를 입력해 주세요',
			body: '',
			suffix: ''
		},
		inputLabel: '전화번호',
		invalidFormat: '문자수를 잘못 입력했습니다',
		chooseCountryTitle: '지역 선택'
	},
	[KEYS.verifyCode] : {
		title: {
			text: '6자리 코드를 입력하세요',
			prefix: '',
			body: '6자리',
			suffix: ' 코드를 입력하세요'
		},
		getRequestPlaceholder: '요청 처리 중입니다. 잠시만 기다려 주세요...',
		checkRequestPlaceholder: 'Checking your code...',
		resendSMS: 'Resend SMS after',
		wrongCode: '잘못된 코드',
		sendAgain: '코드 다시 보내기',
		underlineDecoratedText: {
			text: 'Or change phone number!',
			prefix: 'Or change ',
			body: 'phone number',
			suffix: '!'
		}
	},
	[KEYS.successfulRegistration] : {
		title: {
			text: '등록 성공',
			prefix: '',
			body: '',
			suffix: ''
		},
		subTitle: {
			text: '계정을 성공적으로 등록되었습니다',
			prefix: '',
			body: '',
			suffix: ''
		},
		policyAgreement: '개인 정보 보호 정책에 동의합니다'
	},
	[KEYS.onboarding] : {
		steps: [{
			title: '1 단계',
			subTitle: '본인의 전화번호를 추가해 주세요'
		},{
			title: '2 단계',
			subTitle: '통화 녹음기 서비스 번호로 연락해주세요'
		},{
			title: '3 단계',
			subTitle: '녹음할 번호로 전화를 걸어 주세요'
		},{
			title: '4 단계',
			subTitle: '통화 서로 연결시켜주세요'
		},{
			title: '5 단계',
			subTitle: '중요한 통화를 녹음, 저장 및 공유하세요.\n'
		},{
			title: '6 단계',
			subTitle: '튜토리얼 보기'
		}],
		downloadApp: {
			text: '링크를 통해 앱 스토어에서 앱 다운로드',
			prefix: '',
			body: '링크를 통해 앱 스토어에서 앱 다운로드',
			suffix: ''
		}

	},
	[KEYS.videoOnboarding] : {
		tabs: [
			'발신 전화', '수신 전화'
		],
		breadcrumbs: [
			'대시보드', '통화 녹음하는 방법'
		],
		records: [[{
			title: '1 단계',
			subTitle: {
				prefix: '화면에서 큰 빨간 "통화 녹음" 버튼을 누르세요.',
				body: '',
				suffix: ''
			}
		},{
			title: '2 단계',
			subTitle: {
				prefix: '그다음에 회사 번호가 표시되는 네이티브 팝업이 나타납니다.',
				body: '이 번호를 탭하고 전화를 걸면 됩니다.',
				suffix: ''
			}
		},{
			title: '3 단계',
			subTitle: {
				prefix: '원하는 연락처를 추가하고 전화를 걸 수 있습니다.',
				body: '',
				suffix: ''
			}
		},{
			title: '4 단계',
			subTitle: {
				prefix: '걸어놓은 번호를 받으시면 "통화 연걸" 버튼을 클릭해주세요. 그러면 통화 녹음이 시작됩니다.',
				body: '',
				suffix: ''
			}
		},{
			title: '통화 종료된 후에',
			subTitle: {
				prefix: '녹음 파일은 앱에 업로드됩니다.',
				body: '',
				suffix: ''
			}
		}],[{
			title: '1 단계',
			subTitle: {
				prefix: '전화를 받으세요.',
				body: '',
				suffix: ''
			}
		},{
			title: '2 단계',
			subTitle: {
				prefix: '메인 화면으로 가서 "통화 녹음기" 앱을 엽니다.',
				body: '',
				suffix: ''
			}
		},{
			title: '3 단계',
			subTitle: {
				prefix: '화면에서 큰 빨간 "통화 녹음" 버튼을 누르세요.',
				body: '',
				suffix: ''
			}
		},{
			title: '4 단계',
			subTitle: {
				prefix: '그다음에 회사 번호가 표시되는 네이티브 팝업이 나타납니다. 이 번호를 탭하고 전화를 걸면 됩니다.',
				body: '',
				suffix: ''
			}
		},{
			title: '5 단계',
			subTitle: {
				prefix: '걸어놓은 번호를 받으시면 "통화 연걸" 버튼을 클릭해주세요. 그러면 통화 녹음이 시작됩니다.',
				body: '',
				suffix: ''
			}
		},{
			title: '통화 종료된 후에',
			subTitle: {
				prefix: '통화 종료된 후에',
				body: '녹음 파일은 앱에 업로드됩니다.',
				suffix: ''
			}
		}]],
		mobileGuideButton: 'Watch guide',
		downloadSubTitle: '앱 스토어에서 앱 다운로드',
		hint: 'You can make call recordings only\n through the mobile application'
	},
	[KEYS.dashboard] : {
		records: [{
			title: '통화 기록 1'
		},{
			title: '통화 기록 2'
		},{
			title: '통화 기록 3'
		},{
			title: '통화 기록 4'
		},{
			title: '통화 기록 5'
		},{
			title: '통화 기록 6'
		},{
			title: '통화 기록 7'
		},{
			title: '통화 기록 8'
		},{
			title: '통화 기록 9'
		},{
			title: '통화 기록 10'
		},{
			title: '통화 기록 11'
		},{
			title: '통화 기록 12'
		}],
		downloadApp: {
			text: '링크를 통해 앱 스토어에서 앱 다운로드',
			prefix: '',
			body: '링크를 통해 앱 스토어에서 앱 다운로드',
			suffix: ''
		},
		goToStore: {
			text: 'Go to the application to start\n recording calls',
			prefix: 'Go to the ',
			body: 'application',
			suffix: ' to start\n recording calls'
		},
		mobileTitle: 'Voice Recordings'
	},
	[KEYS.footer] : {
		CRForAll: 'Call Recorder for everyone',
		disclaimerTitle: 'Disclaimer',
		disclaimerText: '법적으로 허용되는 용도로만 사용할 수 있는 통화 녹음기 소프트웨어입니다. 허가된 소프트웨어를 소유하지 않은 장치에 설치하는 것은 해당 법과 귀하의 지역 관할권 법에 위반됩니다. 일반적으로 법은 허가된 소프트웨어를 설치할 의도가 있는 장치의 소유자에게 알리도록 요구합니다. 이 요구 사항의 위반은 위반자에게 중대한 금전적 및 형사적 처벌을 부과할 수 있습니다. 귀하는 설치 및 사용 전에 귀하의 관할권에서 허가된 소프트웨어의 사용이 합법적인지 여부에 대해 귀하의 법률 자문관과 상담해야 합니다. 이러한 장치에 허가된 소프트웨어를 설치하는 것은 전적으로 귀하의 책임이며, eyeZy는 책임을 지지 않음을 인지하고 있습니다.'
	}
}