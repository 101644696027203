export const RecordsMock = [{
	id: 0,
	length: 105000
},{
	id: 1,
	length: 147000
},{
	id: 2,
	length: 5106000
},{
	id: 3,
	length: 47000
},{
	id: 4,
	length: 4365000
},{
	id: 5,
	length: 4561000
},{
	id: 6,
	length: 372000
},{
	id: 7,
	length: 66000
},{
	id: 8,
	length: 372000
},{
	id: 9,
	length: 66000
},{
	id: 10,
	length: 372000
},{
	id: 11,
	length: 66000
}]