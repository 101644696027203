import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper'
import { useTranslation } from "react-i18next";

import { RedTitle, BlockTitle } from "components";
import { KEYS} from "constants";

import {
  BCWebLayout,
  BestChoiceItem,
  BestChoiceList,
  BestChoiceIcon,
  BCMobileLayout,
  BestChoiceTitle,
  BestChoiceWrapper,
  BestChoiceSubTitle,
  BestChoiceItemHeader,
  BestChoiceRedSubstring,
} from './styles'
import { BEST_CHOICES_REASONS } from "./ui_constants.const";

const BestChoiceBlock = () => {
  const { t } = useTranslation();

  return (
    <BestChoiceWrapper id="best-choice">
      <BlockTitle>
        {t(`${KEYS.landing}.bestChoiceBlock.title.prefix`)}
        <RedTitle>
          {t(`${KEYS.landing}.bestChoiceBlock.title.body`)}
        </RedTitle>
        {t(`${KEYS.landing}.bestChoiceBlock.title.suffix`)}
      </BlockTitle>

      <BCWebLayout>
        <BestChoiceList>
          {BEST_CHOICES_REASONS.map((reason, index) =>
            <BestChoiceItem key={reason.id}>
              <BestChoiceItemHeader>
                <BestChoiceIcon src={reason.icon}/>
              </BestChoiceItemHeader>
              <BestChoiceTitle>
                {t(`${KEYS.landing}.bestChoiceBlock.reasons.${index}.title.prefix`)}
                <BestChoiceRedSubstring>
                  {t(`${KEYS.landing}.bestChoiceBlock.reasons.${index}.title.body`)}
                </BestChoiceRedSubstring>
                {t(`${KEYS.landing}.bestChoiceBlock.reasons.${index}.title.suffix`)}
              </BestChoiceTitle>
              <BestChoiceSubTitle>
                {t(`${KEYS.landing}.bestChoiceBlock.reasons.${index}.subtitle`)}
              </BestChoiceSubTitle>
            </BestChoiceItem>
          )}
        </BestChoiceList>
      </BCWebLayout>

      <BestChoiceList>
        <BCMobileLayout>
          <Swiper
            slidesPerView={1.2}
            spaceBetween={0}
            centeredSlides={true}
            modules={[Autoplay]}
            initialSlide={0}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false
            }}
          >
            {BEST_CHOICES_REASONS.map((reason, index) => (
              <SwiperSlide style={{width: '244px'}} key={reason.id}>
                <BestChoiceItem>
                  <BestChoiceItemHeader>
                    <BestChoiceIcon src={reason.icon}/>
                  </BestChoiceItemHeader>
                  <BestChoiceTitle>
                    {t(`${KEYS.landing}.bestChoiceBlock.reasons.${index}.title.prefix`)}
                    <BestChoiceRedSubstring>
                      {t(`${KEYS.landing}.bestChoiceBlock.reasons.${index}.title.body`)}
                    </BestChoiceRedSubstring>
                    {t(`${KEYS.landing}.bestChoiceBlock.reasons.${index}.title.suffix`)}
                  </BestChoiceTitle>
                  <BestChoiceSubTitle>
                    {t(`${KEYS.landing}.bestChoiceBlock.reasons.${index}.subtitle`)}
                  </BestChoiceSubTitle>
                </BestChoiceItem>
              </SwiperSlide>
            ))}
          </Swiper>
        </BCMobileLayout>
      </BestChoiceList>
    </BestChoiceWrapper>
  );
}

export default BestChoiceBlock;
