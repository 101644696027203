import styled from "styled-components";

export const Step = styled.div`
  border: ${({ isActive }) => isActive ? '0.5px solid #fff' : 'none'};
  width: ${({ isActive }) => isActive ? '10px' : '6px'};
  height: ${({ isActive }) => isActive ? '10px' : '6px'};
	border-radius: 50%;
	background: ${({ isActive }) => isActive ? '#000' : '#fff'};
`

export const StepWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 10px;
	height: 10px;
`

export const StepsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
	align-items: center;
  margin: 8px auto;
	gap: 13px;

  @media (max-width: 1169px) {
    margin: 27px 0 16px;
  }
`