import {useState} from "react";
import {Link} from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button, ButtonTypes } from "components";
import { HEADER_CONFIGS, KEYS } from "constants";

import { LangsController } from './LangsController'

import {
  Icon,
  AppLogo,
  NavLink,
  MenuLabel,
  IdNavLink,
  HeaderBase,
  AppStoreLogo,
  MobileNavLinks,
  ContentWrapper,
  NavLinksWrapper,
  MobileContentWrapper,
  MobileNavLinksWrapper,
} from "./styles";

const Header = ({ config = HEADER_CONFIGS.default }) => {
  const { t } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);

  const handleClick = () => setIsOpened(!isOpened);

  return(
    <HeaderBase>
      <ContentWrapper>
        <NavLinksWrapper>
          <AppLogo/>
          {HEADER_CONFIGS[config].button && (
            <Button to={HEADER_CONFIGS[config].button.url} type={HEADER_CONFIGS[config].button.type}>
              {t(`${KEYS.common}.${ HEADER_CONFIGS[config].button.key }`)}
            </Button>
          )}
          {HEADER_CONFIGS[config].navLinks.map((link) =>
            <IdNavLink key={link.text} type={link.type} anchor_ href={link.url}>
              {t(`${KEYS.common}.${ link.key }`)}
            </IdNavLink>
          )}
        </NavLinksWrapper>
        <NavLinksWrapper>
          {HEADER_CONFIGS[config].redirectLinks.map((link) =>
            <NavLink key={link.text} type={link.type} to={link.url}>
              {t(`${KEYS.common}.${ link.key }`)}
            </NavLink>
          )}
          <LangsController/>
        </NavLinksWrapper>
      </ContentWrapper>

      <MobileContentWrapper>
        <AppLogo/>

        <MobileNavLinksWrapper>
          {HEADER_CONFIGS[config].button && (
            <Button to={HEADER_CONFIGS[config].button.url} type={HEADER_CONFIGS[config].button.type}>
              {t(`${KEYS.common}.${ HEADER_CONFIGS[config].button.key }`)}
            </Button>
          )}

          <LangsController/>

          {(HEADER_CONFIGS[config].navLinks.length || HEADER_CONFIGS[config].redirectLinks.length) &&  (
            <MenuLabel htmlFor="navi-toggle" onClick={handleClick}>
              <Icon opened={isOpened}></Icon>
            </MenuLabel>
          )}
        </MobileNavLinksWrapper>
        <MobileNavLinks opened={isOpened}>
          <div></div>
          <NavLinksWrapper>
            {HEADER_CONFIGS[config].navLinks.map((link) =>
              <NavLink key={link.text} type='white' anchor_ to={link.url} onClick={handleClick}>
                {t(`${KEYS.common}.${ link.key }`)}
              </NavLink>
            )}
            {HEADER_CONFIGS[config].redirectLinksMobile && HEADER_CONFIGS[config].redirectLinks.map((link) =>
              <NavLink key={link.text} type={link.type} anchor_ to={link.url} onClick={handleClick}>
                {t(`${KEYS.common}.${ link.key }`)}
              </NavLink>
            )}
          </NavLinksWrapper>
          <Link to="/">
            <AppStoreLogo/>
          </Link>
        </MobileNavLinks>
      </MobileContentWrapper>
    </HeaderBase>
  )
}

export default Header