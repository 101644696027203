import { KEYS } from "./keys.const";

export const FRENCH_TRANSLATIONS = {
	[KEYS.landing]: {
		bestAppBlock: {
			title: {
				text: 'Enregistreur d\'appels Ultimate App pour les appareils mobiles',
				decorate: true,
				prefix: '',
				body: 'Enregistreur d\'appels',
				suffix: ' Ultimate App pour les appareils mobiles'
			},
			subTitle: {
				text: 'Enregistrez, sauvegardez et partagez vos conversations avec votre appareil iOS.',
				highlightedWord1: 'Enregistrez',
				highlightedWord2: 'sauvegardez',
				highlightedWord3: 'et partagez'
			},
		},
		whyLoveItBlock: {
			title: {
				text: 'Pourquoi vous allez adorer Call Recorder',
				decorate: true,
				prefix: 'Pourquoi ',
				body: 'vous allez adorer',
				suffix: ' Call Recorder'
			},
			reasons: [
				{
					title: {
						text: 'Enregistrez un appel',
					},
					subTitle: 'Call Recorder vous permet d\'enregistrer vos appels en toute simplicité. Mais il ne s\'agit pas seulement d\'entendre ce qu\'ils disent. Claquez des doigts et Call Recorder le transcrit également. Écoutez. Ou lisez. C\'est votre appel avec Call Recorder'
				},{
					title: {
						text: 'Une qualité d\'enregistrement exceptionnelle',
					},
					subTitle: 'Enregistrez les appels entrants et sortants dans la meilleure qualité possible. Choisissez parmi plusieurs options d\'enregistrement celles qui vous conviennent le mieux.'
				},{
					title: {
						text: 'Transcrivez vos appels',
						decorate: true,
						prefix: '',
						body: 'Transcrivez ',
						suffix: 'vos appels'
					},
					subTitle: 'Transformez vos enregistrements audio en notes écrites. Call Recorder transcrit tout pour vous, automatiquement.',
				},{
					title: {
						text: 'Stockez vos enregistrements sans limite',
					},
					subTitle: 'Sauvegardez vos enregistrements sur Google Drive ou par e-mail.'
				},{
					title: {
						text: 'Confidentialité totale',
					},
					subTitle: 'Nous prenons votre vie privée au sérieux, c\'est pourquoi vos enregistrements sont entièrement cryptés et ne sont jamais partagés avec d\'autres personnes.'
				},{
					title: {
						text: 'Commencez à utiliser',
					},
				}
			]
		},
		getStartedBlock: {
			title: {
				text: 'Prêt à commencer ?',
				decorate: true,
				prefix: 'Prêt à ',
				body: 'commencer',
				suffix: ' ?'
			},
			subTitle: 'Écoutez, enregistrez et transcrivez tous les appels'
		},
		easyToRecordBlock: {
			title: {
				text: 'C\'est si facile d\'enregistrer des appels',
				decorate: true,
				prefix: 'C\'est si',
				body: ' facile d\'enregistrer ',
				suffix: 'des appels'
			},
			steps: [
				'Ajoutez votre numéro de téléphone',
				'Appelez le numéro de service Call Recorder',
				'Composez le numéro que vous voulez enregistrer',
				'Fusionnez l\'appel',
				'Enregistrez, transcrivez et partagez un appel important.'
			]
		},
		bestChoiceBlock: {
			title: {
				text: 'Le meilleur choix comme enregistreur d\'appels',
				decorate: true,
				prefix: '',
				body: 'Le meilleur choix',
				suffix: ' comme enregistreur d\'appels'
			},
			reasons: [{
				title: {
					prefix: '2.5\n',
					body: 'millions',
					suffix: '+'
				},
				subtitle: 'd\'appels enregistrés',
			},{
				title: {
					prefix: '',
					body: '250,000\n',
					suffix: '+ d\'heures'
				},
				subtitle: 'd\'enregistrement réalisées',
			},{
				title: {
					prefix: 'Plus de 20,000+\n',
					body: 'transcriptions',
					suffix: ''
				},
				subtitle: 'traitées',
			}]
		},
		whyCRBlock: {
			title: {
				text: 'Pourquoi Call Recorder de RecCall ?',
				decorate: true,
				prefix: 'Pourquoi Call Recorder\n',
				body: 'de RecCall',
				suffix: ' ?'
			},
			reasons: [[{
				text: 'Bonne qualité de voix',
			},{
				text: 'Sauvegarde des enregistrements',
			},{
				text: 'Transcription incluse',
			}],[{
				text: 'Enregistrements illimités'
			},{
				text: 'Entièrement privé',
			},{
				text: 'Couverture mondiale'
			}
			]]
		},
		reviewsBlock: {
			title: {
				text: 'Commentaires de nos clients',
				decorate: true,
				prefix: 'Commentaires de nos ',
				body: 'clients',
				suffix: ''
			},
			generalRating: '4.8',
			over30_1: 'plus de 30 000',
			over30_2: 'commentaires',
			reviews: [{
				text: 'Call Recorder m\'a sauvé à plusieurs reprises. En tant que juriste, je jongle constamment avec les appels téléphoniques et les courriels. Call Recorder m\'aide vraiment à donner un sens à tout cela et me permet de garder les choses à portée de main pour m\'y référer ultérieurement.',
				name: 'Karen J.'
			},{
				text: 'Je ne saurais trop vous remercier. J\'étais harcelé au téléphone par ma société de carte de crédit pour une dette impayée que je ne devais pas. J\'ai pu enregistrer le harcèlement par audio et créer une transcription. La preuve m\'a aidé dans ma réclamation contre la société de carte de crédit et a finalement conduit à une résolution paisible .',
				name: 'Marvin Z.'
			},{
				text: 'Facile à installer. Facile à utiliser. Et l\'audio est vraiment, vraiment clair. J\'adore le fait que la transcription soit si automatique. 10 sur 10. Je lui donnerais un 11 si je pouvais.',
				name: 'Stephanie A.'
			}]
		}
	},
	[KEYS.common]: {
		tryNow: 'Essayez-le maintenant',
		videoGuide: 'Video guide',
		confirm: 'Confirmez',
		continue: 'Continuer',
		continueToPayment: 'Poursuivre le paiement',
		android: 'Android',
		ios: 'IOS',
		chooseCountry: 'Choisissez le pays',
		next: 'Suivant',
		previous: 'Précédent',
		navigation: 'Navigation',
		policies: 'Politiques',
		contactUs: 'Nous contacter',
		links : {
			faq: 'FAQ',
			signIn: 'Se connecter',
			signUp: 'Sign Up',
			reviews: 'Critiques',
			aboutUs: 'A propos de nous',
			terms: 'Conditions d\'utilisation',
			bestChoice: 'Meilleur choix',
			privacy: 'Politique de confidentialité',
			unsubscribe: 'Se désabonner',
			howItWorks: 'Comment cela fonctionne',
			keyBenefits: 'Principaux avantages',
			howToRecord: 'Comment enregistrer des appels',
			searchRecordings: 'Rechercher un enregistrement',
			support: 'support@reccall.app',
		}
	},
	[KEYS.choosePhone] : {
		title: {
			text: 'Choisissez l\'appareil que vous voulez enregistrer',
			prefix: 'Choisissez l\'appareil que vous ',
			body: ' voulez enregistrer',
			suffix: ' ?'
		},
		steps: [{
			text: 'ÉTAPE 1',
		},{
			text: 'ÉTAPE 2',
		}],
		phones: [{
			text: 'Android',
		}, {
			text: 'IOS',
		}]
	},
	[KEYS.choosePlan] : {
		title: {
			text: 'Choisissez votre plan',
			prefix: 'Choisissez ',
			body: ' votre ',
			suffix: 'plan'
		},
		mostPopular: 'MOST POPULAR',
		planItems: [{
			title: '1 mois'
		},{
			title: '1 JOUR D\'ESSAI'
		},{
			title: '12 mois'
		}
		],
		pricePrefix: '',
		priceSuffix: ' $',
		perMonth: '/mois',
		priceHint: '*Prix mensuel selon l\'abonnement de 1 ou 12 mois. Le prix est reduit.',
		subscriptionsHint: '** The subscription will be automatically renewed after the end of the trial period ',
		platformHint: '***Currently works only on IOS',
	},
	[KEYS.verifyPhone] : {
		title: {
			text: 'Entrez votre\n numéro de téléphone',
			prefix: 'Entrez votre\n',
			body: ' numéro de téléphone',
			suffix: ''
		},
		inputLabel: 'numéro de téléphone',
		invalidFormat: '* Mauvais nombre de caractères',
		chooseCountryTitle: 'Choisissez le pays'
	},
	[KEYS.verifyCode] : {
		title: {
			text: 'Entrez un code à 6 chiffres',
			prefix: 'Entrez un code à ',
			body: '6 chiffres',
			suffix: ''
		},
		getRequestPlaceholder: 'Votre demande est en cours de traitement, veuillez patienter...',
		checkRequestPlaceholder: 'Checking your code...',
		resendSMS: 'Réinitialiser le SMS après',
		wrongCode: '*Code erroné',
		sendAgain: 'ENVOYEZ À NOUVEAU LE CODE',
		underlineDecoratedText: {
			text: 'Or change numéro de téléphone!',
			prefix: 'Or change ',
			body: 'numéro de téléphone',
			suffix: '!'
		}
	},
	[KEYS.successfulRegistration] : {
		title: {
			text: 'Enregistré avec succès',
			prefix: '',
			body: '',
			suffix: ''
		},
		subTitle: {
			text: 'Nous avons enregistré votre\n compte avec succès',
			prefix: '',
			body: '',
			suffix: ''
		},
		policyAgreement: 'J\'accepte la politique de confidentialité'
	},
	[KEYS.onboarding] : {
		steps: [{
			title: 'ÉTAPE 1',
			subTitle: 'Ajoutez votre numéro de téléphone'
		},{
			title: 'ÉTAPE 2',
			subTitle: 'Appelez le numéro de service Call Recorder'
		},{
			title: 'ÉTAPE 3',
			subTitle: 'Composez le numéro que vous voulez enregistrer'
		},{
			title: 'ÉTAPE 4',
			subTitle: 'Fusionnez l\'appel'
		},{
			title: 'ÉTAPE 5',
			subTitle: 'Enregistrer, transcrire et partager \n un appel important'
		},{
			title: 'ÉTAPE 6',
			subTitle: 'Regardez le tutoriel'
		}],
		downloadApp: {
			text: 'Téléchargez une application\n depuis l\'App Store via le lien',
			prefix: 'Téléchargez une application\n depuis l\'App Store via ',
			body: 'le lien',
			suffix: ''
		}

	},
	[KEYS.videoOnboarding] : {
		tabs: [
			'Sortir', 'Entrant'
		],
		breadcrumbs: [
			'Tableau de bord', 'Comment enregistrer des appels'
		],
		records: [[{
			title: 'Étape 1',
			subTitle: {
				prefix: 'Appuyez sur ',
				body: 'le gros bouton rouge',
				suffix: ' de l\'écran "Enregistrer les appels".'
			}
		},{
			title: 'Étape 2',
			subTitle: {
				prefix: 'Vous verrez alors une pop-up native avec le numéro de téléphone de l\'entreprise.',
				body: 'Tapez sur ce numéro et appelez',
				suffix: ''
			}
		},{
			title: 'Étape 3',
			subTitle: {
				prefix: 'Ensuite, vous pouvez ajouter le contact que vous voulez appeler.',
				body: '',
				suffix: ''
			}
		},{
			title: 'Étape 4',
			subTitle: {
				prefix: 'Attendez que le numéro que vous avez composé réponde et cliquez sur \nle bouton  "',
				body: 'Fusionner l\'appel',
				suffix: '" L\'enregistrement de l\'appel a commencé'
			}
		},{
			title: 'Une fois l\'appel terminé',
			subTitle: {
				prefix: 'Vérifiez l\'enregistrement dans l\'application ! L\'enregistrement sera',
				body: ' téléchargé sur l\'application',
				suffix: ''
			}
		}],[{
			title: 'Étape 1',
			subTitle: {
				prefix: 'Répondre à l\'appel',
				body: '',
				suffix: ''
			}
		},{
			title: 'Étape 2',
			subTitle: {
				prefix: '"Allez à l\'écran principal et ouvrez \n l\'application Call recorder"',
				body: '',
				suffix: ''
			}
		},{
			title: 'Étape 3',
			subTitle: {
				prefix: 'Appuyez sur le gros bouton rouge ',
				body: 'le gros bouton rouge',
				suffix: ' de l\'écran "Enregistrer les appels".'
			}
		},{
			title: 'Étape 4',
			subTitle: {
				prefix: 'Vous verrez alors une pop-up native avec le numéro de téléphone de l\'entreprise.',
				body: 'Tapez sur ce numéro et appelez',
				suffix: ''
			}
		},{
			title: 'Étape 5',
			subTitle: {
				prefix: 'Attendez que le numéro que vous avez composé réponde et cliquez sur le bouton  "',
				body: 'Fusionner l\'appel',
				suffix: '". L\'enregistrement de l\'appel a commencé.'
			}
		},{
			title: 'Une fois l\'appel terminé',
			subTitle: {
				prefix: 'Une fois l\'appel terminé, L\'enregistrement sera',
				body: 'téléchargé sur l\'application.',
				suffix: ''
			}
		}]],
		mobileGuideButton: 'Regardez le tutoriel',
		downloadSubTitle: 'Téléchargez une application depuis l\'App Store',
		hint: 'You can make call recordings only\n through the mobile application'
	},
	[KEYS.dashboard] : {
		records: [{
			title: 'Enregistrement d\'appel 1'
		},{
			title: 'Enregistrement d\'appel 2'
		},{
			title: 'Enregistrement d\'appel 3'
		},{
			title: 'Enregistrement d\'appel 4'
		},{
			title: 'Enregistrement d\'appel 5'
		},{
			title: 'Enregistrement d\'appel 6'
		},{
			title: 'Enregistrement d\'appel 7'
		},{
			title: 'Enregistrement d\'appel 8'
		},{
			title: 'Enregistrement d\'appel 9'
		},{
			title: 'Enregistrement d\'appel 10'
		},{
			title: 'Enregistrement d\'appel 11'
		},{
			title: 'Enregistrement d\'appel 12'
		}],
		downloadApp: {
			text: 'Téléchargez une application\n depuis l\'App Store via le lien',
			prefix: 'Téléchargez une application\n depuis l\'App Store via ',
			body: 'le lien',
			suffix: ''
		},
		goToStore: {
			text: 'Go to the application to start\n recording calls',
			prefix: 'Go to the ',
			body: 'application',
			suffix: ' to start\n recording calls'
		},
		mobileTitle: 'Voice Recordings'
	},
	[KEYS.footer] : {
		CRForAll: 'Call Recorder for everyone',
		disclaimerTitle: 'Avis de non-responsabilité',
		disclaimerText: 'Le LOGICIEL d\'enregistrement d\'appels est destiné à un usage légal uniquement. L\'installation du logiciel sous licence sur un appareil dont vous n\'êtes pas propriétaire constitue une violation de la loi applicable et des lois de votre juridiction locale. La loi vous oblige généralement à informer les propriétaires des appareils sur lesquels vous avez l\'intention d\'installer le logiciel sous licence. La violation de cette exigence peut entraîner de graves sanctions monétaires et pénales imposées au contrevenant. Avant d\'installer et d\'utiliser le Logiciel sous licence, vous devez consulter votre propre conseiller juridique pour connaître la légalité de son utilisation dans votre juridiction. Vous êtes seul responsable de l\'installation du logiciel sous licence sur un tel dispositif et vous êtes conscient qu\'eyeZy ne peut être tenu responsable.'
	}
}