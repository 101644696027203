import React, {useRef, useState, useEffect} from "react";
import { useTranslation } from "react-i18next";

import { Button, ButtonTypes } from "components";
import { getCode, checkCode, useFocus } from "helpers";
import { KEYS} from "constants";

import { FORM_STATUSES, CODE_PAGE_UI_CONFIGS } from "./uiConfiguratons.const";
import {
	RedText,
	EditIcon,
	TimerText,
	BlockTitle,
	PhoneNumber,
	PendingText,
	ResendButton,
	ErrorMessage,
	CodeFieldCell,
	buttonSettings,
	CodeInputField,
	CodeFieldCover,
	TimerCountdown,
	ChangePhoneHint,
	CodeFieldWrapper,
	UnderlineDecoration,
} from "../styles";

const ApprovalCode = ({ handleSubmit, phone, editPhoneNumber }) => {
	let timeout;
	const { t } = useTranslation();
	const [ref, setRef] = useFocus();
	const [code, setCode] = useState('');
	const [isFocused, setIsFocused] = useState(false);
	const [counter, setCounter] = useState(59);
	const [componentState, setComponentState] = useState(FORM_STATUSES.pending);

	// Міняє стан фокусу інпуту при кліку на поле або поза ним
	const handleFocusEvent = (bool) => {
		setIsFocused(bool)
	}

	// Міняє код на новий
	const handleCodeChange = (e) => {
		const newCode = e.target.value;
		if(newCode.length <= 6) {
			setCode(newCode);
		}
	}

	// Чистить таймер і код
	// Блокує форму
	// Посилає новий код
	const resendCode = () => {
		clearTimeout(timeout)
		setCode('')

		setComponentState(FORM_STATUSES.pending)

		sendSMS()
	}

	const sendSMS = async () => {
		const isRequestOk = await getCode(phone)

		if(isRequestOk) {
			setComponentState(FORM_STATUSES.empty);
			setCounter(59);
		} else {
			setCounter(0);
			setComponentState(FORM_STATUSES.expired);
		}
	}

	// Обробляє запит на логін
	// Перевіряє чи код правильний
	// Якщо правильний - переадресація, якщо ні - викидає помилку
	const handleFormSubmit = async () => {
		setComponentState(FORM_STATUSES.checkingCode)

		const { redirectPoint, isResponseOk } = await checkCode(phone, code);

		if(isResponseOk) {
			handleSubmit(redirectPoint);
		} else {
			setComponentState(FORM_STATUSES.wrongCode)
		}
	}

	// Міняє стан форми залежно від стану поля
	useEffect(() => {
		// Ресетить стан інпуту коли поле очищено
		if(!code.length && ![FORM_STATUSES.empty, FORM_STATUSES.pending].includes(componentState)) {
			setComponentState(FORM_STATUSES.empty);
		}

		// Міняє стан інпуту на "в процесі"
		if(code.length && code.length < 6) {
			setComponentState(FORM_STATUSES.inProgress);
		}

		// Перевіряє код коли ввели повністю
		if(code.length === 6) {
			setComponentState(FORM_STATUSES.rightCode);
		}
	}, [code])

	// Таймер
	useEffect(() => {
		if (counter >= 0){
			timeout = setTimeout(() => {
				if (counter === 0) {
					setComponentState(FORM_STATUSES.expired)
				} else {
					setCounter(counter => counter - 1)
				}
			}, 1000);
		}
		return () => {
			clearTimeout(timeout)
		}
	}, [counter]);

	useEffect(() => {
		sendSMS();
	}, [])

	return (
		<>
			<BlockTitle>
				{t(`${KEYS.verifyCode}.title.prefix`)}
				<RedText>
					{t(`${KEYS.verifyCode}.title.body`)}
				</RedText>
				{t(`${KEYS.verifyCode}.title.suffix`)}
			</BlockTitle>
			<PhoneNumber>
				{phone}
				<EditIcon onClick={editPhoneNumber}/>
			</PhoneNumber>
			<CodeFieldWrapper>
				<CodeInputField
					ref={ref}
					value={code}
					onChange={handleCodeChange}
					onFocus={() => handleFocusEvent(true)}
					onBlur={() => handleFocusEvent(false)}
					disabled={CODE_PAGE_UI_CONFIGS[componentState].inputField.disabled}
					type={'number'}
				/>
				<CodeFieldCover onClick={setRef}>
					{Array(6).fill().map((e, i) => (
						<CodeFieldCell
							key={i}
							isFocused={isFocused}
							isEmpty={CODE_PAGE_UI_CONFIGS[componentState].inputField.empty}
							isDisabled={CODE_PAGE_UI_CONFIGS[componentState].inputField.disabled}
							showError={CODE_PAGE_UI_CONFIGS[componentState].inputField.showError}
							showEmptyBorder={CODE_PAGE_UI_CONFIGS[componentState].inputField.empty && i===0}
						>
							{code ? (code[i] ? code[i] : '') : i + 1}
						</CodeFieldCell>
					))}
				</CodeFieldCover>
			</CodeFieldWrapper>

			{CODE_PAGE_UI_CONFIGS[componentState].pendingMessage && (
				<PendingText>
					{t(`${KEYS.verifyCode}.getRequestPlaceholder`)}
				</PendingText>
			)}

			{CODE_PAGE_UI_CONFIGS[componentState].checkingCode && (
				<PendingText>
					{t(`${KEYS.verifyCode}.checkRequestPlaceholder`)}
				</PendingText>
			)}

			{CODE_PAGE_UI_CONFIGS[componentState].countdown && (
				<TimerText>{t(`${KEYS.verifyCode}.resendSMS`)}:&nbsp;<TimerCountdown>00:{counter < 10 ? '0' : ''}{counter}</TimerCountdown></TimerText>
			)}

			{CODE_PAGE_UI_CONFIGS[componentState].wrongCodeMessage && (
				<ErrorMessage>
					{t(`${KEYS.verifyCode}.wrongCode`)}
				</ErrorMessage>
			)}

			{CODE_PAGE_UI_CONFIGS[componentState].resendButton && (
				<ResendButton onClick={resendCode}>
					{t(`${KEYS.verifyCode}.sendAgain`)}
				</ResendButton>
			)}

			{CODE_PAGE_UI_CONFIGS[componentState].changePhoneHint && (
				<ChangePhoneHint>
					{t(`${KEYS.verifyCode}.underlineDecoratedText.prefix`)}
					<UnderlineDecoration to={'/verify-number'} onClick={editPhoneNumber}>
						{t(`${KEYS.verifyCode}.underlineDecoratedText.body`)}
					</UnderlineDecoration>
					{t(`${KEYS.verifyCode}.underlineDecoratedText.suffix`)}
				</ChangePhoneHint>
			)}

			{CODE_PAGE_UI_CONFIGS[componentState].continueButton && (
				<Button type={ButtonTypes.red} style_={buttonSettings} onClick={handleFormSubmit} to={'/verify-number'}>
					{t(`${KEYS.common}.continue`)}
				</Button>
			)}
		</>
	);
}

export default ApprovalCode;



