export const FORM_STATUSES = {
	empty: 'empty',
	pending: 'pending',
	expired: 'expired',
	wrongCode: 'wrongCode',
	rightCode: 'rightCode',
	inProgress: 'inProgress',
	checkingCode: 'checkingCode'
}

export const CODE_PAGE_UI_CONFIGS = {
	[FORM_STATUSES.empty]: {
		inputField: {
			disabled: false,
			empty: true,
			showError: false
		},
		resendButton: true,
		countdown: true
	},

	[FORM_STATUSES.pending]: {
		inputField: {
			disabled: true,
			empty: false,
			showError: false
		},
		pendingMessage: true
	},

	[FORM_STATUSES.checkingCode]: {
		inputField: {
			disabled: true,
			empty: false,
			showError: false
		},
		checkingCode: true
	},

	[FORM_STATUSES.expired]: {
		inputField: {
			disabled: true,
			empty: false,
			showError: false
		},
		resendButton: true,
		changePhoneHint: true
	},

	[FORM_STATUSES.wrongCode]: {
		inputField: {
			disabled: false,
			empty: false,
			showError: true
		},
		resendButton: true,
		wrongCodeMessage: true,
		pendingMessage: false,
		countdown: false,
		hint: false
	},

	[FORM_STATUSES.rightCode]: {
		inputField: {
			disabled: false,
			empty: false,
			showError: false
		},
		continueButton: true
	},

	[FORM_STATUSES.inProgress]: {
		inputField: {
			disabled: false,
			empty: false,
			showError: false
		},
		resendButton: true,
		wrongCodeMessage: false,
		pendingMessage: false,
		countdown: true,
		hint: false
	}
}

