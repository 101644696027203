export const KEYS = {
	landing: 'landing',
	choosePhone: 'choosePhone',
	choosePlan: 'choosePlan',
	verifyPhone: 'verifyPhone',
	verifyCode: 'verifyCode',
	successfulRegistration: 'successfulRegistration',
	onboarding: 'onboarding',
	videoOnboarding: 'videoOnboarding',
	dashboard: 'dashboard',
	header: 'header',
	footer: 'footer',
	common: 'common'
}