import IncomingCall from 'assets/img/dark_incoming_call.png'
import GraySoundWaves from 'assets/img/dark_gray_sound_waves.png'
import Transcription from 'assets/img/dark_transcrition_outlined.png'
import StorageIcon from 'assets/img/dark_storage_icon.png'
import PrivacyIcon from 'assets/img/dark_lock_icon.png'
import CloudIcon from 'assets/img/dark_gray_cloud_icon.png'
import HowToImg_1 from 'assets/img/img_HowToUse_1.png'
import HowToImg_2 from 'assets/img/img_HowToUse_2.png'
import HowToImg_3 from 'assets/img/img_HowToUse_3.png'
import HowToImg_4 from 'assets/img/img_HowToUse_4.png'
import HowToImg_5 from 'assets/img/img_HowToUse_5.png'

import call_sound_icon from 'assets/img/call_sound_icon.png'
import clock_icon from 'assets/img/clock_icon.png'
import chat_icon from 'assets/img/light_transcription_outlined.png'

import Feature_1 from 'assets/img/mic_icon.png'
import Feature_2 from 'assets/img/light_cloud_icon.png'
import Feature_3 from 'assets/img/light_transcription.png'
import Feature_4 from 'assets/img/light_storage_icon.png'
import Feature_5 from 'assets/img/shield_icon.png'
import Feature_6 from 'assets/img/planet_icon.png'

import { ButtonTypes } from "components";

export const REASONS_LIST = [
  {
    text: true,
    icon: IncomingCall,
    button: false
  },
  {
    text: true,
    icon: GraySoundWaves,
    button: false
  },
  {
    text: true,
    icon: Transcription,
    button: false,
    decorate_title: true,
  },
  {
    text: true,
    icon: StorageIcon,
    button: false
  },
  {
    text: true,
    icon: PrivacyIcon,
    button: false
  },
  {
    icon: CloudIcon,
    button: true,
    buttonType: ButtonTypes.dark
  }
]

export const STEPS_LIST = [{
  id: 0,
  image: HowToImg_1
}, {
  id: 1,
  image: HowToImg_2
}, {
  id: 2,
  image: HowToImg_3
}, {
  id: 3,
  image: HowToImg_4
}, {
  id: 4,
  image: HowToImg_5
}]

export const BEST_CHOICES_REASONS = [{
  id: 0,
  icon: call_sound_icon
},{
  id: 1,
  icon: clock_icon
},{
  id: 2,
  icon: chat_icon
}]

export const CR_FEATURES_LISTS = [[{
  text: 'Good voice quality',
  icon: Feature_1
},{
  text: 'Save & share recordings',
  icon: Feature_2
},{
  text: 'Automatic audio transcription',
  icon: Feature_3
}],[
  {
    text: 'Unlimited records',
    icon: Feature_4
  },{
    text: 'Completely private',
    icon: Feature_5
  },{
    text: 'Worldwide coverage',
    icon: Feature_6
  }
]]

export const REVIEWS = [{
  startsCount: 5
},{
  startsCount: 5
},{
  startsCount: 5
}]