import styled from 'styled-components';
import breadcrumbsSeparator from 'assets/img/icons/right_grey_arrow.png'
import arrowIcon from 'assets/img/icons/white_arrow.png'
import video from 'assets/img/landing_video_470x470.mp4'
import cover from 'assets/img/video_guide_poster.png'
import playIcon from 'assets/img/icons/play_icon.png'
import storeIcon from 'assets/img/AppStoreWhiteLogo.png'
import warningIcon from 'assets/img/icons/warningIcon.png'
import common_bg from 'assets/img/common_bg.png'
import common_mob_bg from 'assets/img/common_mobile_bg.png'
import playButton from "assets/img/videoPlayButton.png";
import closeIcon from "assets/img/icons/white_cross_icon.png";

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	justify-content: center;
	background: #000 url(${	common_bg });
	padding-bottom: 90px;

  @media (max-width: 1169px) {
    background: #000 url(${	common_mob_bg });
    padding-bottom: 0;
	}
`

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
	align-items: flex-start;
  padding-top: 0;
`

export const PageContentWrapper = styled.div`
	display: flex;
	flex-direction: row;
	width: 1170px;
	gap: 30px;
  background: #1C1C1E;
  border-radius: 0 12px 12px 12px;
	padding: 25px 0 25px 25px;
	box-sizing: border-box;

  @media (max-width: 1169px) {
    width: 288px;
    padding: 7.5px 16px 16px 16px;
  }
`

export const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`

export const SlidesWrapper = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: 34px;

	@media (max-width: 1169px) {
		
	}
`

export const Breadcrumbs = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 34px;
	
	@media (max-width: 1169px) {
		margin: 16px 0;
	}
`

export const Breadcrumb = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0;
  text-align: right;
  color: rgba(255, 255, 255, 0.6);
	text-decoration: none;

	${({ lastOne }) => lastOne && `
		color: #EB5560;
		font-weight: 600;
	`};
	
  @media (max-width: 1169px) {
    font-size: 12px;
	}
`

export const BreadcrumbsSeparator = styled.img.attrs(p => ({ ...p, src: breadcrumbsSeparator }))`
	width: 34px;
	height: 34px;
	
	@media (max-width: 1169px) {
    width: 21px;
    height: 21px;
	}
`

export const Tabs = styled.div`
	display: flex;
	flex-direction: row;
`

export const Tab = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;
	height: 51px;
  width: ${({ isActive }) => isActive ? `204px` : `123px`};
  padding: 0 28px;
	background: ${({ isActive }) => isActive ? `#1C1C1E` : `transparent`};
  border-radius: 12px 12px 0 0;
	gap: 0;
  cursor: pointer;
	
	@media (max-width: 1169px) {
		justify-content: flex-start;
    height: 40px;
		width: 144px;
    gap: 8px;
		padding: 0 0 0 34px;
	}
`

export const TabIcon = styled.img`
	width: 22px;
	height: 22px;
	
	@media (max-width: 1169px) {
    width: 13px;
    height: 13px;
	}
`

export const TabTitle = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0;
  text-align: right;
	color: #fff;
	white-space: nowrap;

  @media (max-width: 1169px) {
    font-size: 12px;
	}
`

export const RedDot = styled.div`
	width: 10px;
	height: 10px;
	background: #EB5560;
	border-radius: 50%;
	margin: 0;

	@media (max-width: 1169px) {
    width: 8px;
    height: 8px;
		margin: 10px;
	}
`

export const Arrow = styled.img.attrs(p => ({ src: arrowIcon }))`
	width: 17px;
	height: 11px;
	
	${({ rotated}) => rotated && `transform: rotate(180deg);`}
`

export const Video = styled.video.attrs(p => ({ ...p, src: video, poster: cover }))`
	width: 345px;
	height: 468px;
  border-radius: 18px;
  cursor: pointer;
	background: #000;
	
	@media (max-width: 1169px) {
    width: 288px;
    height: 389px;
	}
`

export const VideoPlayButton = styled.img.attrs(p => ({ ...p, src: playButton }))`
	width: 144px;
	height: 144px;
	position: absolute;
	border-radius: 50%;
	left: 101px;
	top: 162px;
  cursor: pointer;

  @media (max-width: 1169px) {
    height: 120px;
    width: 120px;
    top: 243px;
    left: 84px;
  }
`

export const RedText = styled.span`
	font-weight: 700;
	color: #EB5560;
`

export const Buttons = styled.div`
	display: flex;
	flex-direction: row;
	gap: 16px;
	margin-bottom: 110px;
	
	@media (max-width: 1169px) {
    margin-bottom: 15px;
		gap: 20px;
	}
`
export const PrevButton = styled.div`
  display: flex;
  flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
	cursor: pointer;
`

export const NextButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  cursor: pointer;
`

export const PlayIcon = styled.img.attrs(p => ({ ...p, src: playIcon }))`
	width: 16px;
	height: 16px;
	margin-right: 10px;
`

export const WebImage = styled.img`
	display: block;
	width: 366px;
	height: 428px;

	@media (max-width: 1169px) {
		display: none;
	}
`

export const TextBlock = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 11px;

	@media (max-width: 1169px) {
    gap: 7.5px;
	}
`

export const StoreIcon = styled.img.attrs(p => ({ ...p, src: storeIcon }))`
	width: 100px;
	height: 32px;
  cursor: pointer;

	@media (max-width: 1169px) {
    width: 90px;
    height: 28px;
	}
`

export const StepImage = styled.img`
	width: 134px;
	height: 158px;
`

export const StepTitle = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0;
  text-align: center;
  color: #fff;
	
  @media (max-width: 1169px) {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
  }
`

export const StepSubtitle = styled.span`
  font-family: Poppins, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.01em;
  text-align: left;
  color: rgba(255, 255, 255, 0.8);
	width: 335px;
	height: 41px;
	
	@media (max-width: 1169px) {
		width: 256px;
    height: 61px;
	}
`

export const StoreBlock = styled.div`
	display: flex;
	flex-direction: column;
	width: fit-content;
	height: 110px;
	gap: 8px;
	align-items: flex-start;

	@media (max-width: 1169px) {
    height: 62px;
		width: 100%;
		align-items: center;
	}
`

export const WarningIcon = styled.img.attrs(p => ({ ...p, src: warningIcon}))`
	width: 16px;
	height: 16px;
  margin-right: 10px;

  @media (max-width: 1169px) {
		margin-right: 12px;
	}
`

export const DownloadText = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  letter-spacing: 0;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
`

export const WebRecordHint = styled.div`
  display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: 0;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
	height: 40px;

  @media (max-width: 1169px) {
    display: none;
  }
`

export const WebVideoWrapper = styled.div`
  display: block;
	position: relative;
	
	@media (max-width: 1169px) {
    display: none;	
	}
`

export const MobileRecordHint = styled.div`
	display: none;

	@media (max-width: 1169px) {
		
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: -0.01em;
    text-align: left;

    display: flex;
		flex-direction: row;
		align-items: center;
		align-self: center;
		padding: 12px 0;
		width: 235px;
    color: rgba(255, 255, 255, 0.6);
	}
`

export const PlayButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 35px;
	width: 256px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  cursor: pointer;
  
  font-family: Poppins, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #FFFFFF;

`

export const ControlsAndContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex-grow: 1;
	padding-top: 65px;
	gap: 11px;

	@media (max-width: 1169px) {
		align-items: center;
    padding-top: 7.5px;
    gap: 7.5px;
	}
`

export const MobileContentWrapper = styled.div`
	display: none;

	@media (max-width: 1169px) {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 8px;
	}
`

export const MobileVideoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100vw;
	padding-top: 41px;
	height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
	z-index: 1000;
`

export const MobileVideoContentWrapper = styled.div`
	display: flex;
  flex-direction: column;
  align-items: center;
  width: 288px;
	position: relative;
`

export const CloseButton = styled.div`
	display: flex;
	flex-direction: row;
	align-self: flex-end;
	width: 26px;
	height: 26px;
	margin-bottom: 83px;
`

export const CloseIcon = styled.img.attrs(p => ({ ...p, src: closeIcon }))`
  width: 26px;
  height: 26px;
`

export const ExternalLink = styled.a`

`