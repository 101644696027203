import styled from "styled-components";

export const Step = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: center;
  text-transform: uppercase;
  width: 285px;
  height: 79px;

  color: ${({ isActive }) => isActive ? 'rgba(255, 255, 255, 0.8)' : 'rgba(255, 255, 255, 0.35)'};
  border-bottom: 1px solid;
  border-color: ${({ isActive }) => isActive ? 'rgba(255, 255, 255, 0.6)' : 'rgba(255, 255, 255, 0.2)'};
  
  @media (max-width: 1169px) {
    width: 144px;
    height: 53px;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
  }
`

export const StepsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 30px 0 20px;

  @media (max-width: 1169px) {
    margin: 27px 0 16px;
  }
`