import { DEFAULT_LANG, SUPPORTED_LANGUAGES } from "constants";

export const configureInterfaceLanguage = () => {
	const browser_lang = navigator.language.includes('-') ? navigator.language.split('-')[0] : navigator.language;
	const param_lang = window.location.pathname.split('/').filter(el => !!el)[0];
	const url_lang = SUPPORTED_LANGUAGES.includes(param_lang) ? param_lang : undefined;
	const lastChosenLanguage = localStorage.getItem('chosenLanguage')

	if(SUPPORTED_LANGUAGES.includes(lastChosenLanguage)) {

		return lastChosenLanguage
	}

	let lang;

	if(url_lang && url_lang !== 'undefined' && SUPPORTED_LANGUAGES.includes(url_lang)) {
		lang = url_lang
	} else if(browser_lang && browser_lang !== 'undefined' && SUPPORTED_LANGUAGES.includes(browser_lang)) {
		lang = browser_lang
	} else {
		lang = DEFAULT_LANG
	}

	return lang
}